import * as actionTypes from 'store/actions/actionTypes';

export const deployFlow = id => ({
  type: actionTypes.DEPLOY_FLOW_REQUESTED,
  payload: { id }
});

export const destroyFlow = id => ({
  type: actionTypes.DESTROY_FLOW_REQUESTED,
  payload: { id }
});

export const cloneFlow = (flowId, name, description) => ({
  type: actionTypes.CLONE_FLOW_REQUESTED,
  payload: { flowId, name, description }
});

export const deleteFlow = id => ({
  type: actionTypes.DELETE_FLOW_REQUESTED,
  payload: { id }
});

// is used only when configuration for particular node changed
export const redeployFlowNode = (flowId, nodeId) => ({
  type: actionTypes.REDEPLOY_FLOW_NODE_REQUESTED,
  payload: { flowId, nodeId }
});

import axios from 'axios';
import jwtDecode from 'jwt-decode';

const API_URL = window?.ENV?.API_URL;

const AUTH_URL = '/auth';

export const signIn = ({ email, password }) =>
  axios({
    baseURL: API_URL,
    method: 'POST',
    url: `${AUTH_URL}/signin`,
    data: {
      email,
      password
    },
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json'
    }
  });

export const authCheck = () =>
  axios({
    baseURL: API_URL,
    method: 'GET',
    url: `${AUTH_URL}/check`,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json'
    }
  });

export const signOut = () =>
  axios({
    baseURL: API_URL,
    method: 'DELETE',
    url: `${AUTH_URL}/signout`,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json'
    }
  });

export const register = ({ email, password }) =>
  axios({
    baseURL: API_URL,
    method: 'POST',
    url: `${AUTH_URL}/register`,
    data: {
      email,
      password
    },
    headers: {
      'Content-Type': 'application/json'
    }
  });

export const checkAuth = (data = {}) => {
  const { jwt } = data;
  const headers = jwt
    ? {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`
      }
    : {
        'Content-Type': 'application/json'
      };

  return axios({
    baseURL: API_URL,
    method: 'GET',
    url: `${AUTH_URL}/check`,
    withCredentials: true,
    headers
  });
};

export const fetchUserByToken = ({ jwt }) => {
  const { sub } = jwtDecode(jwt);

  return axios({
    baseURL: API_URL,
    method: 'GET',
    url: `${AUTH_URL}/users/${sub}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwt}`
    }
  });
};

export const resetPassword = email => {
  return axios({
    baseURL: API_URL,
    method: 'POST',
    url: `notify/password-reset`,
    data: email,
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

export const setPassword = ({ password, jwt }) => {
  return axios({
    baseURL: API_URL,
    method: 'PATCH',
    url: `${AUTH_URL}/password`,
    data: {
      new_password: password
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwt}`
    }
  });
};

import React from 'react';

import { get } from 'lodash';

import { InfoIcon } from 'components';

import {
  Container,
  Wrapper,
  ImageArea,
  GraphName,
  ToggleWrapper,
  ToggleSwitch,
  SwitchWrapper
} from './styled';

const ToggleBlock = ({
  name,
  values,
  label,
  column,
  description,
  icon: GraphIcon
}) => {
  const isToggled = get(values, name, false);

  return (
    <Container isToggled={isToggled} column={column}>
      <Wrapper>
        <ImageArea isToggled={isToggled}>
          <GraphIcon />
        </ImageArea>
        <ToggleWrapper>
          <GraphName>{label}</GraphName>
          <SwitchWrapper>
            <InfoIcon>{description}</InfoIcon>
            <ToggleSwitch name={name} />
          </SwitchWrapper>
        </ToggleWrapper>
      </Wrapper>
    </Container>
  );
};

export default ToggleBlock;

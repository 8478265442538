import { Component } from 'react';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';

import * as actions from 'store/actions/auth';

class Authorization extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pathName: ''
    };
  }

  // this causes additional rerender of whole application if routing changes
  // so 1 rerender because routing change + this one
  // componentDidMount() {
  //   this.props.history.listen((location, action) => {
  //     if (location.pathname !== this.state.pathName) {
  //       this.setState({
  //         pathName: location.pathname
  //       });
  //       this.props.authCheck();
  //     }
  //   });
  // }

  componentWillMount() {
    this.props.authCheck();
  }

  render() {
    return null;
  }
}

const mapDispatchToProps = dispatch => {
  return {
    authCheck: () => dispatch(actions.authCheck())
  };
};

export default connect(null, mapDispatchToProps)(withRouter(Authorization));

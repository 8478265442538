import axios from 'axios';
import UserAgentParser from 'ua-parser-js';

const FLAGR_API_URL = window.ENV.FLAGR_API_URL;

const prepareEntityID = entityId => (entityId ? entityId.toString() : entityId);

const ua = UserAgentParser(window.navigator.userAgent);

const isObject = thing => thing && typeof thing === 'object';

export const evaluateFlag = ({
  flagKey,
  user = {},
  deviceId,
  evaluationContext,
  hostname = window.location.hostname
}) =>
  axios.post(`${FLAGR_API_URL}/evaluation`, {
    flagKey,
    entityID: prepareEntityID(user?.customerID),
    entityContext: {
      userId: user?.id,
      email: user?.email,
      customerID: user?.customerID,
      fullName: user?.fullName,
      deviceId,
      hostname,
      browserName: ua.browser.name,
      osName: ua.os.name,
      ...(isObject(evaluationContext) ? evaluationContext : {})
    }
  });

export const batchEvaluateFlags = ({
  flagKeys,
  user = {},
  deviceId,
  hostname = window.location.hostname
}) =>
  axios.post(`${FLAGR_API_URL}/evaluation/batch`, {
    entities: [
      {
        entityID: prepareEntityID(user?.customerID),
        entityContext: {
          userId: user?.id,
          email: user?.email,
          customerID: user?.customerID,
          fullName: user?.fullName,
          deviceId,
          hostname,
          browserName: ua.browser.name,
          osName: ua.os.name
        }
      }
    ],
    flagKeys
  });

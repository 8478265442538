import { put, call } from 'redux-saga/effects';

import * as API from '../api';
import * as actionTypes from 'store/actions/actionTypes';

import { closeRightPanel } from '../actions/panels';

import { activateLoader, deactivateLoader } from './loaders';
import {
  showErrorNotification,
  showSuccessNotification
} from './notifications';

import { getUsers as getUsersAction } from 'store/actions/users';

function* getUsers() {
  try {
    const response = yield call(API.fetchUsers);

    yield put({
      type: actionTypes.GET_USERS_SUCCEEDED,
      payload: response.data.response
    });
  } catch (e) {
    yield put({
      type: actionTypes.GET_USERS_FAILED,
      payload: e.message
    });
    yield* showErrorNotification({
      content: 'Something went wrong in getting users'
    });
  }
}

function* deleteUser(action) {
  const { id } = action.payload;
  try {
    const response = yield call(API.deleteUser, { id });

    yield put({
      type: actionTypes.DELETE_USER_SUCCEEDED,
      payload: response.data.response
    });
    yield* showSuccessNotification({ content: 'User deleted.' });
  } catch (e) {
    yield put({
      type: actionTypes.DELETE_USER_FAILED,
      payload: e.message
    });
    yield* showErrorNotification({
      content: 'Error deleting user.'
    });
  } finally {
    yield put(getUsersAction());
  }
}

function* updateUser(action) {
  const { id, data } = action.payload;
  try {
    const response = yield call(API.updateUser, { id, data });

    yield put({
      type: actionTypes.UPDATE_USER_SUCCEEDED,
      payload: response.data.response
    });
    yield* showSuccessNotification({ content: 'Successfully updated user!' });
  } catch (e) {
    yield put({
      type: actionTypes.UPDATE_USER_FAILED,
      payload: e.message
    });
    yield* showErrorNotification({
      content: 'Something went wrong, error updating user.'
    });
  } finally {
    yield put(getUsersAction());
  }
}

function* inviteUsers(action) {
  const { payload } = action;

  const isString = typeof payload === 'string';
  const hasComma = isString && payload.includes(',');

  const emails = payload
    .split(hasComma ? ',' : '\n')
    .map(i => i.trim())
    .filter(Boolean)
    .filter(i => typeof i === 'string' && i.includes('@'));

  try {
    yield activateLoader({
      payload: { path: 'accountDetails.inviteUsers' }
    });

    yield call(API.inviteUsers, emails);

    yield put({ type: actionTypes.USER_INVITE_SUCCEEDED, payload: emails });

    yield* showSuccessNotification({
      content: 'Invites have been successfully sent to the requested emails.'
    });
  } catch (e) {
    yield* showErrorNotification({
      content: 'Error sending invitations. Users already invited.'
    });

    yield put({ type: actionTypes.USER_INVITE_FAILED, message: e.message });
  } finally {
    yield deactivateLoader({
      payload: { path: 'accountDetails.inviteUsers' }
    });
    yield* getUsers();
    yield put(closeRightPanel());
  }
}
export { getUsers, deleteUser, updateUser, inviteUsers };

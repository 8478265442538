export const AnalyzeNodeTypes = {
  VIDEO_WIDGET: 'Video Display Widget',
  HSL_WIDGET: 'HSL Display Widget',
  TWITTER_WIDGET: 'Twitter Display Widget',
  COMPARE_WIDGET: 'COMPARE',
  TIME_WIDGET: 'TIME',
  DATA_TABLE: 'DATA TABLE',
  TEXT_WIDGET: 'TEXT'
};

export const NODE_TYPE_COMPARE = 'COMPARE';

export default AnalyzeNodeTypes;

import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useField } from 'formik';

import useDropdown from 'hooks/useDropdown';
import { Status, Icon, Button, Search } from 'components';
import ClickOutside from 'hoc/ClickOutside/ClickOutside';
import styles from './Dropdown.module.scss';

export const BaseDropdown = ({
  values = [],
  onItemSelected = () => {},
  placeholder,
  leftIcon,
  rightIcon,
  isSearchable,
  disabled = false,
  open,
  setOpen,
  selected,
  showStatus = false,
  isTable
}) => {
  const [toggle, setToggle] = useState(null);
  const [search, setSearch] = useState(null);
  const [selectedItem, setSelectedItem] = useState(selected);

  useEffect(() => setSelectedItem(selected), [selected]);

  const toggleDropdown = useCallback(() => {
    setOpen(!open);
    setToggle(!open ? styles['toggle'] : null);
  }, [open, setOpen]);

  const handleSelect = value => e => {
    e.preventDefault();
    setSelectedItem(value);
    onItemSelected && onItemSelected(value);
    toggleDropdown();
  };

  const onSearchHandler = useCallback(event => {
    setSearch(event.target.value);
  }, []);

  const handleSearchClear = useCallback(() => {
    setSearch(null);
  }, []);

  const rowFilter = row => {
    const filter = search;

    if (filter) {
      for (let key in row) {
        if (!row[key]) continue;

        if (
          row[key]
            .toString()
            .toLowerCase()
            .includes(filter.toLowerCase())
        ) {
          return true;
        }
      }

      return false;
    } else return true;
  };

  const headerTitle = selectedItem ? selectedItem.value : placeholder;

  function handleClickOutside() {
    setOpen(false);
    setToggle(null);
  }

  return (
    <ClickOutside onClickOutside={handleClickOutside}>
      <div
        className={classnames(styles['dropdown-container'], {})}
        data-testid="dropdown-container"
      >
        <div
          onClick={toggleDropdown}
          data-testid="dropdown-header"
          className={classnames(
            isTable
              ? styles['dropdown-header-table']
              : styles['dropdown-header'],
            toggle,
            {
              'dropdown__not-selected': !selected
            }
          )}
        >
          {leftIcon ? (
            <Icon
              className={styles['dropdown-header-info']}
              name={['fas', leftIcon]}
            />
          ) : null}

          {showStatus && selected ? <Status status={selected.status} /> : null}
          <Button
            className={
              isTable
                ? styles['dropdown-header-table-button']
                : styles['dropdown-header-button']
            }
            data-testid="dropdown-header-button"
            type="button"
            aria-haspopup="true"
            aria-expanded={open}
            id="dropdownMenuButton"
            label={headerTitle}
            title={headerTitle}
            disabled={disabled}
          />
          {rightIcon ? (
            <Icon
              className={styles['dropdown-header-caret']}
              name={['fas', rightIcon]}
            />
          ) : null}
        </div>
        <div className={styles['dropdown-list-wrapper']}>
          {open && !disabled && (
            <ul className={styles['dropdown-list']}>
              {isSearchable ? (
                <li key="search" className={styles['dropdown-list-search']}>
                  <Search
                    type="search"
                    placeholder="Filter by name"
                    onChange={onSearchHandler}
                    value={search}
                    onClearClick={handleSearchClear}
                  />
                </li>
              ) : null}
              {values.filter(rowFilter).map(item => (
                <li
                  className={styles['dropdown-list-item']}
                  data-testid="dropdown-list-item"
                  key={item.key}
                  onClick={handleSelect(item)}
                  title={item.value}
                >
                  {item.status && <Status status={item.status} />}
                  {item.value}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </ClickOutside>
  );
};

const defaultEntity = {
  singular: 'item',
  plural: 'items'
};

export const MultiSelectionDropdown = ({
  values = [],
  shouldShow,
  selectedItems,
  onSelect,
  isSelected,
  setShouldShow,
  disabled = false,
  entity = defaultEntity,
  isTable
}) => {
  const numberOfItemsSelected = selectedItems.length;
  const [toggle, setToggle] = useState(null);

  const toggleDropdown = useCallback(() => {
    setShouldShow(!shouldShow);
    setToggle(!shouldShow ? styles['toggle'] : null);
  }, [shouldShow, setShouldShow]);

  const handleClickOutside = useCallback(() => {
    setShouldShow(false);
    setToggle(null);
  }, [setShouldShow]);

  const handleSelect = useCallback(
    item => e => {
      e.preventDefault();
      onSelect(item);
    },
    [onSelect]
  );

  const unit = numberOfItemsSelected === 1 ? entity.singular : entity.plural;
  const headerTitle =
    numberOfItemsSelected === 0
      ? `Filter ${unit}`
      : `${numberOfItemsSelected} ${unit} selected`;

  return (
    <ClickOutside onClickOutside={handleClickOutside}>
      <div className={styles['dropdown-container']}>
        <div
          onClick={toggleDropdown}
          className={classnames(
            isTable
              ? styles['dropdown-header-table']
              : styles['dropdown-header'],
            toggle,
            {
              'dropdown__not-selected': !selectedItems
            }
          )}
        >
          <Button
            className={
              isTable
                ? styles['dropdown-header-table-button']
                : styles['dropdown-header-button']
            }
            type="button"
            aria-haspopup="true"
            aria-expanded={shouldShow}
            id="dropdownMenuButton"
            label={headerTitle}
            title={headerTitle}
            disabled={disabled}
          />
          <Icon
            className={styles['dropdown-header-caret']}
            name={['fas', 'caret-down']}
          />
        </div>
        <div className={styles['dropdown-list-wrapper']}>
          {shouldShow && (
            <ul className={styles['dropdown-list']}>
              {values.map(item => (
                <li
                  className={
                    styles[
                      `dropdown-list-item ${
                        isSelected(item) ? 'dropdown-list-item-selected' : null
                      }`
                    ]
                  }
                  key={item.key}
                  onClick={handleSelect(item)}
                  title={item.value}
                >
                  {isSelected(item) && <Icon name={['far', 'check']} />}
                  {item.value}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </ClickOutside>
  );
};

export const FormDropdown = ({
  values,
  placeholder,
  rightIcon,
  selected,
  name
}) => {
  const [toggle, setToggle] = useState(null);
  const [field, _, helpers] = useField({ name, value: selected });

  const { shouldShow, setShouldShow } = useDropdown();

  const toggleDropdown = useCallback(() => {
    setShouldShow(!shouldShow);
    setToggle(!shouldShow ? styles['toggle'] : null);
  }, [shouldShow, setShouldShow]);

  const handleSelect = value => e => {
    e.preventDefault();
    helpers.setValue(value);
    toggleDropdown();
  };

  const headerTitle = field.value ? field.value : placeholder;

  function handleClickOutside() {
    setShouldShow(false);
    setToggle(null);
  }

  return (
    <ClickOutside onClickOutside={handleClickOutside}>
      <div className={styles['dropdown-container']}>
        <div
          onClick={toggleDropdown}
          className={classnames(styles['dropdown-header'], toggle, {
            'dropdown__not-selected': !selected
          })}
        >
          <Button
            className={styles['dropdown-header-form-button']}
            data-testid="dropdown-header-button"
            type="button"
            aria-haspopup="true"
            aria-expanded={shouldShow}
            id="dropdownMenuButton"
            label={headerTitle}
            title={headerTitle}
          />
          {rightIcon ? (
            <Icon
              className={styles['dropdown-header-caret']}
              name={['fas', rightIcon]}
            />
          ) : null}
        </div>
        <div className={styles['dropdown-list-wrapper']}>
          {shouldShow && (
            <div className={styles['dropdown-list']}>
              {values.map(item => (
                <input
                  className={styles['dropdown-list-item-input']}
                  data-testid="dropdown-list-item"
                  key={item}
                  onClick={handleSelect(item)}
                  title={item}
                  value={item}
                  spellCheck="false"
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </ClickOutside>
  );
};

const Dropdown = props => {
  const { shouldShow, setShouldShow } = useDropdown();
  return <BaseDropdown {...props} open={shouldShow} setOpen={setShouldShow} />;
};

BaseDropdown.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any, // eslint-disable-line
      text: PropTypes.string
    })
  ),
  placeholder: PropTypes.string,
  onItemSelected: PropTypes.func,
  variant: PropTypes.string,
  isSearchable: PropTypes.bool,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  isTable: PropTypes.bool
};

Dropdown.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any, // eslint-disable-line
      text: PropTypes.string
    })
  ),
  placeholder: PropTypes.string,
  onItemSelected: PropTypes.func,
  variant: PropTypes.string,
  isSearchable: PropTypes.bool
};

export default Dropdown;

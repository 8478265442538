import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { NoData, ConfigurationForm } from 'components';
import { getConfigurationData, getConfigurationParameters } from 'utilities';

import { flowParametersSelector, flowSelector } from 'store/selectors';
import { updateFlowNodes, fetchNodeDefinitions } from 'store/actions/flow';
import { closeRightPanel } from 'store/actions/panels';

const AddConfiguration = ({ nodeId }) => {
  const dispatch = useDispatch();

  const flow = useSelector(flowSelector);

  const flowId = useMemo(() => flow.ID, [flow]);

  const editing = !isEmpty(flow.parameters);

  const nodeTypes = useSelector(state => state.flows.nodeTypes || []);

  const nodes = useSelector(state =>
    state.flows.flow ? state.flows.flow.nodes : []
  );

  const isNotConfigured = useMemo(() => isEmpty(flow.parameters), [
    flow.parameters
  ]);

  const flowParameters = useSelector(flowParametersSelector);

  useEffect(() => {
    dispatch(fetchNodeDefinitions());
  }, [dispatch]);

  const handleSubmit = useCallback(
    async (values, nodesWithParameters) => {
      const parameters = getConfigurationParameters(
        nodesWithParameters,
        values
      );

      const data = getConfigurationData(flowId, parameters);

      // Updates the configuration for the node & closes the panel once done
      //
      // web/frontend/src/store/sagas/flows.js
      //
      dispatch(
        updateFlowNodes({
          data,
          nodeId,
          flowId
        })
      );
    },
    [dispatch, flowId, nodeId]
  );

  const handleCancel = useCallback(() => dispatch(closeRightPanel()), [
    dispatch
  ]);

  return (
    <div>
      {nodes && nodeTypes ? (
        <ConfigurationForm
          editing={editing}
          nodes={nodes}
          nodeTypes={nodeTypes}
          parameters={editing ? flowParameters : null}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          selectedNodeId={nodeId}
          empty={isNotConfigured}
        />
      ) : (
        <NoData />
      )}
    </div>
  );
};

AddConfiguration.propTypes = {
  nodeId: PropTypes.string
};

export default AddConfiguration;

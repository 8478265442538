import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from 'components';
import { default as modalTypes } from 'components/Modals';
import { hideModal } from 'store/actions/modal';

const ModalContainer = ({ closeModal }) => {
  const dispatch = useDispatch();
  const props = useSelector(state => state.modal.modalProps);
  const type = useSelector(state => state.modal.modalType);
  const SpecifiedModal = modalTypes[type] ?? null;

  const shouldRender = type && Object.keys(modalTypes).includes(type) && props;

  const closeModalHandler = useCallback(() => {
    closeModal ? closeModal() : dispatch(hideModal());
  }, [dispatch, closeModal]);

  return shouldRender ? (
    <div>
      <Modal
        title={props.title}
        isShowing
        hide={closeModalHandler}
        className={props.className}
      >
        <SpecifiedModal closeModal={closeModalHandler} {...props} />
      </Modal>
    </div>
  ) : null;
};

export default ModalContainer;

import * as actionTypes from 'store/actions/actionTypes';

export const showModal = data => {
  return {
    type: actionTypes.SHOW_MODAL,
    modalType: data.modalType,
    modalProps: data.modalProps
  };
};

export const hideModal = () => {
  return {
    type: actionTypes.HIDE_MODAL
  };
};

import React from 'react';

import { ReactComponent as LineGraph } from 'vector/icon-viz-line-graph.svg';
import { ReactComponent as BarGraph } from 'vector/icon-viz-bar-graph.svg';
import { ReactComponent as PieChart } from 'vector/icon-viz-pie-chart.svg';
import { ReactComponent as DonutChart } from 'vector/icon-viz-donut-chart.svg';
import { ReactComponent as StackedBarGraph } from 'vector/icon-viz-stacked-bar-graph.svg';

import { NodeParameters } from 'constants';

import NodeParameter from 'components/Configuration/ConfigurationCreator/ConfigurationForm/NodeParameter';

import ToggleBlock from './ToggleBlock';

import { Wrapper } from './styled';

const CompareParameterInputs = ({
  values,
  getFieldName,
  parameters,
  formProps,
  validate
}) => {
  const visualizationTypeParameter =
    parameters.find(p => p.variable === NodeParameters.VISUALIZATION_STYLE) ||
    {};

  const group = getFieldName(
    visualizationTypeParameter.type,
    visualizationTypeParameter.ID,
    visualizationTypeParameter.flowNodeID,
    visualizationTypeParameter.nodeTypeParameterID
  );

  const titleParamter =
    parameters.find(p => p.variable === NodeParameters.TITLE) || {};

  return (
    <>
      <Wrapper>
        <div>
          {INPUTS.LEFT.map((input, index) => (
            <ToggleBlock
              column={(index + 1) % 2}
              key={input.name}
              values={values}
              {...input}
              name={`${group}.${input.name}`}
            />
          ))}
        </div>
        <div>
          {INPUTS.RIGHT.map((input, index) => (
            <ToggleBlock
              column={(index + 1) % 2}
              key={input.name}
              values={values}
              {...input}
              name={`${group}.${input.name}`}
            />
          ))}
        </div>
      </Wrapper>
      <NodeParameter
        {...formProps}
        validate={validate}
        parameter={titleParamter}
      />
    </>
  );
};

export default CompareParameterInputs;

const INPUTS = {
  LEFT: [
    {
      name: 'graphs.lineGraph',
      label: 'Line Graph',
      icon: LineGraph,
      description:
        'Line graphs are used to display quantitative values over a continuous interval or time period. A Line graph is most frequently used to show trends and analyse how data has changed over time.'
    },
    {
      name: 'graphs.barGraph',
      label: 'Bar Graph',
      icon: BarGraph,
      description:
        'Bar graphs use (horizontal or vertical) bars to show discrete, numerical comparisons across items or categories. One axis of the chart shows the specific categories being compared and the other axis represents a discrete value scale.'
    },
    {
      name: 'graphs.pieChart',
      label: 'Pie Chart',
      icon: PieChart,
      description:
        'Pie charts help show proportions and percentages between items / categories. It is useful to visualise what the relationship of each part is to the total amount.'
    }
  ],
  RIGHT: [
    {
      name: 'graphs.donutChart',
      label: 'Donut Chart',
      icon: DonutChart,
      description:
        'Donut charts help show proportions and percentages between items / categories. It is useful to visualise what the relationship of each part is to the total amount.'
    },
    {
      name: 'graphs.stackedBarGraph',
      label: 'Stacked Bar Graph',
      icon: StackedBarGraph,
      description:
        'Stacked area graphs use multiple data series to visualise multiple variables changing over an interval.'
    }
  ]
};

export const Panels = {
  FLOW_PANEL: 'FLOW_PANEL',
  CONFIGURATION_CREATOR_PANEL: 'CONFIGURATION_CREATOR_PANEL',
  NOTIFICATIONS_PANEL: 'NOTIFICATIONS_PANEL',
  INTEGRATION_PANEL: 'INTEGRATION_PANEL',
  INVITE_USERS_PANEL: 'INVITE_USERS_PANEL',
  DATA_SOURCES_PANEL: 'DATA_SOURCES_PANEL'
};

export default Panels;

export const findArrayValueById = (array, id, value) => {
  if (!isArray(array)) return null;

  const item = array.find(item => item.ID === id);
  return item ? item[value] : null;
};

export const filterArrayByParameter = (array, value, parameter) => {
  if (!isArray(array)) return [];

  const items = array.filter(item => item[parameter] === value);

  return items || [];
};

export const findArrayObjectById = (array, id, fieldName = 'ID') => {
  if (!isArray(array)) return null;

  const item = array.find(item => item[fieldName] === id);
  return item;
};

export const isArray = function(a) {
  return Array.isArray(a);
};

export const isEmpty = obj => {
  return !obj || Object.keys(obj).length === 0;
};

export const chartColors = [
  '#002689',
  '#7FCBDD',
  '#6CD28C',
  '#F6DD5C',
  '#F49351',
  '#EA5F21',
  '#949BA3',
  '#CFD3D8',
  '#D5DEFB',
  '#CFF9D3'
];

export const getRandomColor = () => {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const generateColor = index => {
  let color = getRandomColor();

  if (index < chartColors.length) {
    color = chartColors[index];
  }

  return color;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const formatBytesNoUnit = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
};

export const getFormatedBytesUnit = bytes => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return sizes[i];
};

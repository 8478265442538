import React from 'react';

class HubspotForm extends React.Component {
  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/shell.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '6563474',
          formId: '4594feec-a6d5-4721-84f0-0c3ebb6ce2b1',
          target: '#hubspotForm'
        });
      }
    });
  }

  render() {
    return <div id="hubspotForm"></div>;
  }
}

export default HubspotForm;

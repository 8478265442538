import { call, put } from 'redux-saga/effects';
import * as API from '../api';

import * as actionTypes from 'store/actions/actionTypes';

function* getFlowKpis(action) {
  const { id } = action.payload;

  if (id) {
    try {
      const {
        data: { response }
      } = yield call(API.getFlowKpi, id);

      yield put({
        type: actionTypes.GET_FLOW_KPI_SUCCEEDED,
        payload: response
      });
    } catch (error) {
      yield put({
        type: actionTypes.GET_FLOW_KPI_FAILED,
        payload: error.message
      });
    }
  }
}

export { getFlowKpis };

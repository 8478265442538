import * as actionTypes from 'store/actions/actionTypes';

export const createFlow = data => ({
  type: actionTypes.CREATE_FLOW_REQUESTED,
  payload: { data }
});

export const updateFlow = data => ({
  type: actionTypes.UPDATE_FLOW_REQUESTED,
  payload: { data }
});

export const updateFlowNodes = data => ({
  type: actionTypes.UPDATE_FLOW_NODES_REQUESTED,
  payload: { ...data }
});

export const clearFlow = () => ({
  type: actionTypes.CLEAR_FLOW_DATA
});

export const fetchNodeDefinitions = () => ({
  type: actionTypes.FETCH_NODE_DEFINITIONS_REQUESTED
});

import React, { useState, useCallback } from 'react';
import { Icon } from 'components';
import NodeParameter from '../NodeParameter/NodeParameter';

import styles from './AdvancedSettings.module.scss';

const AdvancedSettings = ({
  advancedParameters,
  validate,
  integrationType,
  ...props
}) => {
  const [isAdvancedSettingsActive, setAdvancedSettingsActive] = useState(false);

  const toggleAdvancedSettings = useCallback(() => {
    setAdvancedSettingsActive(
      isAdvancedSettingsActive => !isAdvancedSettingsActive
    );
  }, [setAdvancedSettingsActive]);

  const advancedSettingsStyle = isAdvancedSettingsActive
    ? styles['advanced-settings']
    : styles['hidden-advanced-settings'];

  return (
    <div className={styles['advanced-settings-container']}>
      <div className={styles['advanced-settings-info']}>
        <Icon
          className={styles['exclamation-mark-icon']}
          name={['fas', 'exclamation-circle']}
        />
        <div className={styles['advanced-settings-info-text']}>
          {isAdvancedSettingsActive
            ? 'Advanced settings for this node are shown'
            : 'Advanced settings for this node are hidden'}
        </div>
        <div
          className={styles['advanced-settings-btn']}
          label="Show all settings"
          onClick={toggleAdvancedSettings}
        >
          {isAdvancedSettingsActive
            ? 'Hide advanced settings'
            : 'Show all settings'}
        </div>
      </div>
      <div className={advancedSettingsStyle}>
        <div className={styles['advanced-settings-text']}>
          <Icon
            className={styles['exclamation-mark-icon']}
            name={['fas', 'exclamation-circle']}
          />
          Change these settings only if you're absolutely sure what you're doing
        </div>
        <div className={styles['advanced-settings-parameter']}>
          {advancedParameters.map(parameter => (
            <NodeParameter
              {...props}
              validate={validate}
              parameter={parameter}
              key={parameter.ID}
              integrationType={integrationType}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdvancedSettings;

import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Icon = props => {
  const iconName = Array.isArray(props.name) ? props.name : ['far', props.name];

  return (
    <span className={props.className}>
      <FontAwesomeIcon
        onClick={props.onClick}
        icon={iconName}
        size={props.size}
        spin={props.spin}
        id={props.id}
      />
    </span>
  );
};

Icon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired,
  size: PropTypes.string
};

export default Icon;

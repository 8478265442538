import { useState, useCallback } from 'react';

export default function useDropdown(config = {}) {
  const { initialState = false, onChange } = config || {};
  const [shouldShow, setShouldShow] = useState(initialState);
  const toggleShouldShow = useCallback(() => {
    setShouldShow(!shouldShow);

    if (onChange) {
      onChange(!shouldShow);
    }
  }, [shouldShow, onChange]);

  return {
    shouldShow,
    setShouldShow,
    toggleShouldShow
  };
}

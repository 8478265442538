import styled from 'styled-components';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: var(--s-b);
  padding: var(--s-xxs);
`;

export { Wrapper };

import React, { useRef, useEffect } from 'react';
import './ClickOutside.scss';

const useClickOutside = (ref, onClickOutside) => {
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      onClickOutside();
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  });
};

export default function ClickOutside(props) {
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, props.onClickOutside);
  return (
    <div className="click-outside" ref={wrapperRef}>
      {props.children}
    </div>
  );
}

import { call, put, select } from 'redux-saga/effects';

import * as actionTypes from 'store/actions/actionTypes';
import { PERSISTENT_STATE_KEY } from 'constants';
import clone from 'lodash/clone';

import { getItem, setItem } from 'utilities/localStorage';

import { showErrorNotification } from './notifications';

function* loadPersistentState(action) {
  const persistentTypes = action.payload;

  if (Array.isArray(persistentTypes)) {
    try {
      const output = {};

      const data = yield call(getItem, PERSISTENT_STATE_KEY);

      const serializedData = yield call(JSON.parse, data);

      if (serializedData && typeof serializedData === 'object') {
        persistentTypes.forEach(key => {
          output[key] = serializedData[key];
        });
      }

      yield put({
        type: actionTypes.LOAD_PERSISTENT_STATE_SUCCEEDED,
        payload: output
      });
    } catch ({ message }) {
      yield* showErrorNotification({
        content: 'Error loading persistent state.'
      });
      yield put({
        type: actionTypes.LOAD_PERSISTENT_STATE_FAILED,
        payload: message
      });
    }
  }
}
function* addPersistentStateItem(action) {
  const { type, item, overwrite } = action.payload;

  if (type && item) {
    try {
      let result = [];

      const currentState = yield select(store => store.persistentState.data);

      if (currentState && typeof currentState === 'object') {
        if (overwrite) {
          result = item;
        } else if (Array.isArray(currentState[type])) {
          result = currentState[type].concat(item);
        }
      }

      const output = { ...currentState, [type]: result };

      yield call(setItem, PERSISTENT_STATE_KEY, JSON.stringify(output));

      yield put({
        type: actionTypes.ADD_PERSISTENT_STATE_ITEM_SUCCEEDED,
        payload: output
      });
    } catch ({ message }) {
      yield put({
        type: actionTypes.ADD_PERSISTENT_STATE_ITEM_FAILED,
        payload: message
      });
    }
  }
}

function* clearPersistentStateItem(action) {
  try {
    const data = yield call(getItem, PERSISTENT_STATE_KEY);

    const serializedData = yield call(JSON.parse, data);

    const processedData = clone(serializedData);

    processedData[action.payload] = [];

    yield call(setItem, PERSISTENT_STATE_KEY, JSON.stringify(processedData));

    yield put({
      type: actionTypes.CLEAR_PERSISTENT_STATE_ITEM_SUCCEEDED,
      payload: action.payload
    });
  } catch ({ message }) {
    yield put({
      type: actionTypes.CLEAR_PERSISTENT_STATE_ITEM_FAILED,
      payload: message
    });
  }
}

function* removePersistentStateItems(action) {
  const { stateKey, property, value } = action.payload;
  try {
    const data = yield call(getItem, PERSISTENT_STATE_KEY);

    const serializedData = yield call(JSON.parse, data);

    const processedData = clone(serializedData);

    const filteredData = processedData[stateKey].filter(
      item => item[property] !== value
    );

    processedData[stateKey] = filteredData;

    yield call(setItem, PERSISTENT_STATE_KEY, JSON.stringify(processedData));

    yield put({
      type: actionTypes.REMOVE_PERSISTENT_STATE_ITEMS_SUCCEEDED,
      payload: processedData
    });
  } catch ({ message }) {
    yield put({
      type: actionTypes.REMOVE_PERSISTENT_STATE_ITEMS_FAILED,
      payload: message
    });
  }
}

export {
  loadPersistentState,
  clearPersistentStateItem,
  addPersistentStateItem,
  removePersistentStateItems
};

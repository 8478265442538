import React from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';

const Tooltip = ({ children, ...rest }) => {
  const placement = rest.place ? rest.place : 'top';
  const type = rest.type ? rest.type : 'dark';
  const effect = rest.effect ? rest.effect : 'solid';

  return (
    <div data-for={rest.id} data-tip={children} {...rest}>
      <ReactTooltip
        id={rest.id}
        place={placement}
        effect={effect}
        type={type}
        html
        multiline
        className="tooltip"
      />
    </div>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node,
  place: PropTypes.string
};

export default Tooltip;

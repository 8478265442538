import React from 'react';

import styles from './InfoBlock.module.scss';

const InfoBlock = ({ children, ...rest }) => (
  <div {...rest} className={styles['info-block-container']}>
    {children}
  </div>
);

export default InfoBlock;

import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { Formik, Form } from 'formik';

import { Button, Field } from 'components';
import { INVITE_USERS_SCHEMA } from 'schema/schema';

import { inviteUsers } from 'store/actions/users';
import { inviteUsersLoaderSelector } from 'store/selectors';

const InviteUsersPanel = ({ closePanel }) => {
  const dispatch = useDispatch();

  const isInviteUsersLoading = useSelector(inviteUsersLoaderSelector, isEqual);

  const handleInviteUsers = useCallback(
    data => {
      dispatch(inviteUsers(data.emails));
    },
    [dispatch]
  );

  return (
    <div id="user-invite-form">
      <div className="right-panel-header">
        <h1>Invite users</h1>
        <Button className="icon" onClick={closePanel} icon={['fal', 'times']} />
      </div>
      <div>
        <Formik
          enableReinitialize
          onSubmit={handleInviteUsers}
          initialValues={{ emails: '' }}
          validationSchema={INVITE_USERS_SCHEMA}
        >
          <Form>
            <Field
              as="textarea"
              name="emails"
              placeholder="Add users to this account (email ids separated by newline or comma)"
            />
            <div className="options-bar">
              <Button
                id="send-invites-btn"
                type="submit"
                isLoading={isInviteUsersLoading}
                disabled={isInviteUsersLoading}
                label="Send invites"
                className="primary"
              />
            </div>
          </Form>
        </Formik>
        <p>
          Learn more about user management and how RAIN manages your personal
          data. RAIN is GDPR compliant.
        </p>
      </div>
    </div>
  );
};

export default InviteUsersPanel;

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Button } from 'components';

const ConfirmModal = ({ id, entity, closeModal, confirmModal }) => {
  const confirmModalHandler = useCallback(() => {
    confirmModal(id);
  }, [confirmModal, id]);

  const buttons = (
    <React.Fragment>
      <Button
        id="cancel-btn"
        type="button"
        onClick={closeModal}
        label="Cancel"
      />
      <Button
        id="confirm-btn"
        type="submit"
        className="primary"
        label="Confirm"
        onClick={confirmModalHandler}
      />
    </React.Fragment>
  );

  return (
    <div className={classnames('modal-wrapper')}>
      <div>
        <p>
          Are you sure you want to {entity.command || 'confirm'} this{' '}
          {entity.type}?
        </p>
        <p className="large-p">{entity.node}</p>
      </div>
      <div className="footer">{buttons}</div>
    </div>
  );
};

ConfirmModal.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  entity: PropTypes.shape({
    type: PropTypes.string,
    command: PropTypes.string,
    node: PropTypes.node
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
  confirmModal: PropTypes.func.isRequired
};

export default ConfirmModal;

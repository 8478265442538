import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { TimeSeriesLineChart, WidgetWrapper } from 'components';

import { extractItemsData, limitDatapointBufferSize } from './convert';

const TimeSeriesDataView = ({
  state,
  title,
  datapointsLowerLimit,
  timewindowLowerLimit
}) => {
  const dataSet = extractItemsData(state);
  const limitedDataSet = dataSet.map(lineseries =>
    limitDatapointBufferSize(
      lineseries,
      +datapointsLowerLimit,
      +timewindowLowerLimit
    )
  );

  const data = useMemo(() => {
    if (limitedDataSet && limitedDataSet.length > 0) {
      return limitedDataSet;
    }
    return [];
  }, [limitedDataSet]);

  return (
    <WidgetWrapper title={title} name="Time Series Data">
      <TimeSeriesLineChart data={data} />
    </WidgetWrapper>
  );
};

TimeSeriesDataView.propTypes = {
  eventKey: PropTypes.string
};

export default TimeSeriesDataView;

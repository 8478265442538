import React from 'react';

import { Icon } from 'components';

import Loadable from 'react-loadable';

const Loader = () => (
  <div className="loader-container">
    <Icon name={['far', 'spinner']} spin />
  </div>
);

const DataSourcesView = Loadable({
  loader: () => import('views/DataSources'),
  loading: Loader
});

const FlowsView = Loadable({
  loader: () => import('views/Flows'),
  loading: Loader
});

const AccountViews = Loadable({
  loader: () => import('views/Account'),
  loading: Loader
});

const IntegrationViews = Loadable({
  loader: () => import('views/Integrations'),
  loading: Loader
});

export default {
  DataSourcesView,
  FlowsView,
  AccountViews,
  IntegrationViews
};

export const FlowContainerTabs = {
  Configure: 0,
  Analyze: 1,
  Logs: 2
};

export const FlowStatus = {
  Stopped: 'stopped',
  Failed: 'failed',
  Terminating: 'terminating',
  Running: 'running',
  Pending: 'pending',
  Unknown: 'unknown'
};

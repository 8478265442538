import { RIGHT_PANEL_OPEN, RIGHT_PANEL_CLOSE } from 'store/actions/actionTypes';

export const openRightPanel = data => {
  return {
    type: RIGHT_PANEL_OPEN,
    panel: data.panel,
    properties: data.properties
  };
};

export const closeRightPanel = () => {
  return {
    type: RIGHT_PANEL_CLOSE
  };
};

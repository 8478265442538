import * as actionTypes from 'store/actions/actionTypes';

const initialState = {
  users: [],
  userId: null,
  customerDetails: {},
  error: null
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USERS_SUCCEEDED:
      return getUsersSucceeded(state, action);
    case actionTypes.DELETE_USER_SUCCEEDED:
      return deleteUserSucceeded(state, action);
    case actionTypes.UPDATE_USER_SUCCEEDED:
      return updateUserSucceeded(state, action);
    case actionTypes.GET_CUSTOMER_DETAILS_SUCCEEDED:
      return getCustomerDetailsSucceeded(state, action);
    case actionTypes.UPDATE_CUSTOMER_DETAILS_SUCCEEDED:
      return updateCustomerSucceeded(state, action);
    default:
      return state;
  }
};

export default reducer;

const getUsersSucceeded = (state, action) => {
  return {
    ...state,
    error: null,
    users: action.payload
  };
};

const updateUserSucceeded = state => {
  return {
    ...state,
    error: null
  };
};

const deleteUserSucceeded = state => {
  return {
    ...state,
    error: null
  };
};

const getCustomerDetailsSucceeded = (state, action) => {
  return {
    ...state,
    customerDetails: action.payload,
    error: null
  };
};

const updateCustomerSucceeded = state => {
  return {
    ...state,
    error: null
  };
};

import { FormDropdown } from 'components';
import { getFieldName } from 'utilities';

const DropdownParameter = ({ parameter, handleValidate }) => (
  <div className="field-container">
    <label>{parameter.name}</label>
    <FormDropdown
      key={parameter.ID}
      name={getFieldName(
        parameter.type,
        parameter.ID,
        parameter.flowNodeID,
        parameter.nodeTypeParameterID
      )}
      placeholder="Pick type of tracker to use"
      validate={handleValidate}
      values={parameter.validValues}
      rightIcon="caret-down"
      selected={parameter.value}
    />
  </div>
);

export default DropdownParameter;

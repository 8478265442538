import * as Yup from 'yup';
import { isEmpty } from 'lodash';

export const REGISTER_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .email('Email address must be valid')
    .required('Please enter an email address'),
  password: Yup.string()
    .min(2, 'Password is too short')
    .max(50, 'Password is too long')
    .required('Please enter a password')
});

export const RESET_PASSWORD_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .email('Email address must be valid')
    .required('Please enter an email address')
});

export const LOGIN_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .min(2, 'Email is too short')
    .max(50, 'Email is too long')
    .required('Please enter an email'),
  password: Yup.string()
    .min(2, 'Password is too short')
    .max(50, 'Password is too long')
    .required('Please enter a password')
});

export const TEMPLATE_FORM_SCHEMA = Yup.object().shape({
  name: Yup.string()
    .min(4, 'Name is too short')
    .max(100, 'Name is too long')
    .required('Please enter a template name'),
  description: Yup.string().required('Please enter a template description')
});

export const FLOW_FORM_SCHEMA = Yup.object().shape({
  name: Yup.string()
    .min(4, 'Name is too short')
    .max(100, 'Name is too long')
    .required('Please enter a flow name')
});

export const getUserValidationSchema = editing => {
  if (editing) {
    return Yup.object().shape({
      email: Yup.string()
        .email('Email address must be valid')
        .required('Please enter an email'),
      fullName: Yup.string().required('Please enter full name')
    });
  } else {
    return Yup.object().shape({
      email: Yup.string()
        .email('Email address must be valid')
        .required('Please enter an email'),
      password: Yup.string()
        .required('Please enter a password')
        .min(8, 'Password is too short - should be at least 8 characters long'),
      fullName: Yup.string().required('Please enter full name')
    });
  }
};

export const ACCOUNT_SETTINGS_SCHEMA = Yup.object().shape({
  companyName: Yup.string().required('Please enter a company name'),
  city: Yup.string().required('Please enter a city'),
  companyAddress: Yup.string().required('Please enter a company address'),
  postalCode: Yup.string().required('Please enter a postal code'),
  country: Yup.string().required('Please enter a country'),
  vatNumber: Yup.string().required('Please enter a vat number')
});

const isEmailSchema = Yup.string().email();

export const INVITE_USERS_SCHEMA = Yup.object().shape({
  emails: Yup.string()
    .required('Please enter an email address')
    .test({
      name: 'emails',
      test: function(value) {
        if (value) {
          const firstInvalidEmail = value
            .split(/[\n,]/)
            .map(email => email.trim())
            .filter(v => !isEmpty(v))
            .find(v => !isEmailSchema.isValidSync(v));

          return !firstInvalidEmail
            ? true
            : this.createError({
                message: `The email address '${firstInvalidEmail}' is invalid.`
              });
        }
      }
    })
});

export const ADD_USER_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .email('Email must be a valid email')
    .required('Please enter an email address'),
  password: Yup.string()
    .required('Please enter a password')
    .min(8, 'Password is too short - should be at least 8 characters long'),

  fullName: Yup.string().required('Please enter full name')
});

export const SET_PASSWORD = Yup.object().shape({
  password: Yup.string()
    .required('Please enter a password')
    .min(8, 'Password is too short - should be at least 8 characters long')
});

export const isRequired = value => {
  return !value ? `Please insert value` : '';
};

const TypeSchema = Yup.lazy(value => {
  switch (typeof value) {
    case 'undefined':
      return Yup.string().default(null);
    case 'object':
      return Yup.object()
        .optional()
        .nullable()
        .typeError('Required fields');
    case 'string':
      return Yup.string()
        .optional()
        .nullable();

    default:
      throw new Yup.ValidationError('Coordinates are not valid');
  }
});

export const EDIT_DATA_SOURCE_SCHEMA = Yup.object().shape({
  name: Yup.string()
    .required('Please enter a name')
    .max(200, 'Name is too long'),
  url: Yup.string()
    .url()
    .required('Please enter a valid URL'),
  coordinates: TypeSchema
});

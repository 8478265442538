import AddModal from './AddModal/AddModal';
import ConfirmModal from './ConfirmModal/ConfirmModal';
import DeleteModal from './DeleteModal/DeleteModal';
import NotificationModal from './NotificationModal/NotificationModal';
import CsvImportModal from './CsvImportModal/CsvImportModal';

const modalTypes = {
  add: AddModal,
  confirm: ConfirmModal,
  delete: DeleteModal,
  notification: NotificationModal,
  csv: CsvImportModal
};

export default modalTypes;

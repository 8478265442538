import React from 'react';
import PropTypes from 'prop-types';

const Base64Image = props => {
  return props.image ? (
    <img
      className={props.className}
      alt={props.alt || 'base64image'}
      src={'data:image/png;base64,' + props.image}
    />
  ) : null;
};

Base64Image.propTypes = {
  className: PropTypes.string,
  image: PropTypes.string,
  alt: PropTypes.string
};

export default Base64Image;

import React from 'react';
import { Formik, Form } from 'formik';
import { Button, Field, FieldPassword } from 'components';

import { LOGIN_SCHEMA } from 'schema/schema';

const initialValues = { email: '', password: '' };

const LoginForm = ({ onSubmit, handleForgotPassword, inProgress = false }) => (
  <Formik
    validationSchema={LOGIN_SCHEMA}
    initialValues={initialValues}
    onSubmit={onSubmit}
  >
    <Form>
      <Field name="email" placeholder="Email" />
      <FieldPassword name="password" placeholder="Password" />
      <div className="footer">
        <React.Fragment>
          <Button
            id="forgot-password-btn"
            type="button"
            onClick={handleForgotPassword}
            label="Forgot password"
            disabled={!!inProgress}
          />
          <Button
            id="signin-btn"
            type="submit"
            className="primary"
            label="Sign in"
            disabled={!!inProgress}
            isLoading={inProgress}
          />
        </React.Fragment>
      </div>
    </Form>
  </Formik>
);

export default LoginForm;

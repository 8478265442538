import * as actionTypes from 'store/actions/actionTypes';

export const PERSISTENT_NOTIFICATIONS = 'notifications';

export const PERSISTENT_INTEGRATIONS = 'integrations';

export const PERSISTENT_TYPES = [
  PERSISTENT_NOTIFICATIONS,
  PERSISTENT_INTEGRATIONS
];

export const loadPersistentState = () => {
  return {
    type: actionTypes.LOAD_PERSISTENT_STATE_REQUESTED,
    payload: PERSISTENT_TYPES
  };
};

export const addPersistentStateItem = (type, item, overwrite) => {
  return {
    type: actionTypes.ADD_PERSISTENT_STATE_ITEM_REQUESTED,
    payload: {
      type,
      item,
      overwrite
    }
  };
};

export const clearPersistentState = key => {
  return {
    type: actionTypes.CLEAR_PERSISTENT_STATE_ITEM_REQUESTED,
    payload: key
  };
};

export const removePersistentStateItems = ({ stateKey, property, value }) => {
  return {
    type: actionTypes.REMOVE_PERSISTENT_STATE_ITEMS_REQUESTED,
    payload: { stateKey, property, value }
  };
};

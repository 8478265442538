import { RIGHT_PANEL_OPEN, RIGHT_PANEL_CLOSE } from 'store/actions/actionTypes';

const initialState = {
  visible: false,
  rightPanel: '',
  properties: {}
};

export const showRightPanel = (state, action) => {
  return {
    ...state,
    visible: true,
    rightPanel: action.panel,
    properties: action.properties
  };
};

export const closeRightPanel = state => {
  return {
    ...state,
    ...initialState
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RIGHT_PANEL_OPEN:
      return showRightPanel(state, action);
    case RIGHT_PANEL_CLOSE:
      return closeRightPanel(state);
    default:
      return state;
  }
};

export default reducer;

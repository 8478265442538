import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import './Error.scss';

const Error = props => (
  <div className="error">
    <h2 id="error-title">Error {props.errorStatus}</h2>
    <div className="logo-container">
      <div className="error-logo" />
      <div className="stage" />
    </div>
    <p>
      Page you are trying to access was either moved or simply does not exist.
    </p>
    <p>
      You can navigate back to&nbsp;<Link to="/">Homepage</Link>.
    </p>
  </div>
);

Error.propTypes = {
  errorStatus: PropTypes.number.isRequired
};

export default Error;

import React from 'react';
import get from 'lodash/get';

import { Base64Image, NoData, WidgetWrapper } from 'components';

const VideoDisplayWidgetView = ({ data, title }) => {
  const image = get(data, 'img_output', null);

  return (
    <WidgetWrapper name="Video Display" title={title}>
      {image ? (
        <Base64Image image={image} alt="output stream" />
      ) : (
        <NoData text="Video stream is loading..." />
      )}
    </WidgetWrapper>
  );
};

export default VideoDisplayWidgetView;

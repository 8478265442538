import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';

import App from './App';
import Authorization from 'hoc/Authorization/Authorization';
import { generateStore, history } from 'store/store';
import * as serviceWorker from './serviceWorker';
import { ToastContainer } from 'react-toastify';

import 'vendor/rappid.css';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import './style/index.scss';

export const store = generateStore(history);

const app = (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Authorization />
      <App />
    </ConnectedRouter>
    <ToastContainer />
  </Provider>
);

ReactDOM.render(app, document.getElementById('main'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

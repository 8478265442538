import { createSelector } from 'reselect';

import { formatBytesNoUnit, getFormatedBytesUnit } from 'utilities/transform';

export const kpiNodeMetricsSelector = createSelector(
  store => store.kpis.nodeMetrics,
  nodeMetrics => nodeMetrics
);

export const kpiCombinedSelector = createSelector(
  store => store.kpis.combined,
  combined => combined
);

export const kpiRestartsSelector = createSelector(
  store => store.kpis.restarts,
  restarts => restarts
);

export const keyStatItemsSelector = createSelector(
  [kpiCombinedSelector, kpiRestartsSelector],
  (combined, restarts) => {
    if (combined) {
      let items = [];

      const memory = combined.filter(x => x.name === 'memory')[0];
      const cpu = combined.filter(x => x.name === 'cpu')[0];

      let units;
      let quantity;

      if (memory) {
        units = getFormatedBytesUnit(memory.quantity);
        quantity = formatBytesNoUnit(memory.quantity);
      }

      items.push({
        key: memory ? memory.name : 'byte',
        id: memory ? memory.name : 'byte',
        value: quantity ? quantity : 0,
        legend: memory ? memory.name + ' (' + units + ')' : 'Bytes'
      });

      items.push({
        key: cpu ? cpu.name : '',
        id: cpu ? cpu.name : '',
        value: cpu ? cpu.quantity : 0,
        legend: cpu ? cpu.name.toUpperCase() : 'CPU'
      });

      if (restarts) {
        items.push({
          key: restarts.name,
          id: restarts.name,
          value: restarts.quantity,
          legend: restarts.unit
        });

        return items;
      }
    }
  }
);

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button } from 'components';

const NotificationModal = ({ notification, closeModal }) => {
  const buttons = (
    <React.Fragment>
      <Button
        id="confirm-btn"
        type="submit"
        label="Continue"
        onClick={closeModal}
      />
    </React.Fragment>
  );

  return (
    <div className={classnames('modal-wrapper')}>
      <p>{notification}</p>
      <div className="footer">{buttons}</div>
    </div>
  );
};

NotificationModal.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  entity: PropTypes.shape({
    type: PropTypes.string,
    command: PropTypes.string,
    node: PropTypes.node
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
  notification: PropTypes.string.isRequired
};

export default NotificationModal;

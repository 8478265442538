export const BOUNDS = {
  MAX: 190,
  MIN: 0
};

export const DEFAULT_COORDINATES = {
  first: { x: 0, y: 95 },
  second: { x: 190, y: 95 }
};

export function calculateBounds(x, y) {
  let bounds = {};
  // left
  if (x <= BOUNDS.MIN) {
    bounds = {
      top: BOUNDS.MIN,
      bottom: BOUNDS.MAX,
      left: BOUNDS.MIN,
      right: BOUNDS.MIN
    };
  }
  // right
  if (x >= BOUNDS.MAX) {
    bounds = {
      top: BOUNDS.MIN,
      bottom: BOUNDS.MAX,
      left: BOUNDS.MAX,
      right: BOUNDS.MAX
    };
  }

  // top
  if (y <= BOUNDS.MIN) {
    bounds = {
      top: BOUNDS.MIN,
      bottom: BOUNDS.MIN,
      left: BOUNDS.MIN,
      right: BOUNDS.MAX
    };
  }
  // bottom
  if (y >= BOUNDS.MAX) {
    bounds = {
      top: BOUNDS.MAX,
      bottom: BOUNDS.MAX,
      left: BOUNDS.MIN,
      right: BOUNDS.MAX
    };
  }

  // left top
  const isLeftTop = x <= BOUNDS.MIN && y <= BOUNDS.MIN;
  const isLeftBottom = x <= BOUNDS.MIN && y >= BOUNDS.MAX;
  const isRightTop = x >= BOUNDS.MAX && y <= BOUNDS.MIN;
  const isRightBottom = x >= BOUNDS.MAX && y >= BOUNDS.MAX;

  if (isLeftTop || isLeftBottom || isRightTop || isRightBottom) {
    bounds = {
      top: BOUNDS.MIN,
      bottom: BOUNDS.MAX,
      left: BOUNDS.MIN,
      right: BOUNDS.MAX
    };
  }
  return bounds;
}

export function desearializeCoordinates(first, second) {
  return `${getPercentage(first.x)},${getPercentage(first.y)},${getPercentage(
    second.x
  )},${getPercentage(second.y)}`;
}

export function searializeCoordinates(value) {
  try {
    const splitValues = value.split(',');
    if (splitValues.length < 4) {
      throw Error('Missing arguments.');
    }

    const [firstX, firstY, secondX, secondY] = splitValues;

    const payload = {
      first: { x: getValue(firstX), y: getValue(firstY) },
      second: { x: getValue(secondX), y: getValue(secondY) }
    };
    return payload;
  } catch {
    return DEFAULT_COORDINATES;
  }
}

function getPercentage(value) {
  return ((1 / BOUNDS.MAX) * value).toFixed(2);
}

function getValue(value) {
  return +(+value * BOUNDS.MAX).toFixed();
}

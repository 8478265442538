import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from 'vector/logo.svg';

const logo = () => {
  return (
    <Link id="logo" className="logo" to="/">
      <Logo />
    </Link>
  );
};

export default logo;

import { toast, cssTransition } from 'react-toastify';
import { put } from 'redux-saga/effects';

import {
  PERSISTENT_NOTIFICATIONS,
  addPersistentStateItem
} from 'store/actions/persistentState';

const customSlide = cssTransition({
  enter: 'Toastify__slideInUp',
  exit: 'Toastify__slideOutUp'
});

function* showNotification({
  type,
  content,
  position = 'top-right',
  autoClose = 2000
}) {
  yield toast(content, {
    type,
    position,
    autoClose,
    transition: customSlide,
    hideProgressBar: false,
    closeOnClick: true,
    closeButton: false,
    draggable: false
  });
}

function* showSuccessNotification({ ...config }) {
  const notificationProps = { ...config, type: 'success' };

  yield* showNotification(notificationProps);

  yield put(
    addPersistentStateItem(PERSISTENT_NOTIFICATIONS, {
      ...notificationProps,
      created: Date.now()
    })
  );
}

function* showErrorNotification({ ...config }) {
  const notificationProps = { ...config, type: 'error' };

  yield* showNotification(notificationProps);

  yield put(
    addPersistentStateItem(PERSISTENT_NOTIFICATIONS, {
      ...notificationProps,
      created: Date.now()
    })
  );
}

export { showSuccessNotification, showErrorNotification };

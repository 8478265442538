import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Field as FormField, ErrorMessage } from 'formik';
import { InfoIcon } from 'components';

import styles from './Field.module.scss';

const Field = ({
  className,
  name,
  label,
  type,
  checked,
  component,
  placeholder,
  description,
  as,
  validate,
  disabled,
  id
}) => {
  const classes = classNames(className || 'field-container');
  const hasLabel = label && typeof label === 'string';

  return (
    <div className={classes}>
      <div className={styles['field-label']}>
        {hasLabel && <label htmlFor={name}>{label || name}</label>}
        {description && (
          <InfoIcon className={styles['info-icon']}>{description}</InfoIcon>
        )}
      </div>
      <FormField
        data-testid={label}
        id={id}
        name={name}
        as={as}
        type={type || 'text'}
        checked={checked}
        component={component}
        placeholder={placeholder}
        validate={validate}
        disabled={disabled}
      />
      <ErrorMessage component="span" className="error" name={name} />
    </div>
  );
};

Field.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  checked: PropTypes.bool,
  component: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  validate: PropTypes.func,
  disabled: PropTypes.bool
};

export default Field;

import React from 'react';
import { Formik, Form } from 'formik';

import { Button, Field, FieldPassword } from 'components';

import { REGISTER_SCHEMA } from 'schema/schema';

const initialValues = { email: '', password: '' };

const RegistrationForm = ({ onSubmit, inProgress }) => {
  return (
    <Formik
      validationSchema={REGISTER_SCHEMA}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      <Form>
        <Field name="email" placeholder="Professional email id" />
        <FieldPassword name="password" placeholder="Password" />
        <div className="footer">
          <React.Fragment>
            <Button
              id="register-btn"
              type="submit"
              className="primary"
              label="Register"
              disabled={!!inProgress}
              isLoading={inProgress}
            />
          </React.Fragment>
        </div>
      </Form>
    </Formik>
  );
};

export default RegistrationForm;

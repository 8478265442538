import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';

import { authSetPassword } from 'store/actions/auth';
import { setPasswordLoaderSelector } from 'store/selectors';

import { Button, FieldPassword } from 'components';
import { SET_PASSWORD } from 'schema/schema';

import './SetNewPassword.scss';

const SetNewPassword = () => {
  const { jwt } = useParams();
  const dispatch = useDispatch();

  const isSetPasswordLoading = useSelector(setPasswordLoaderSelector);

  const handleSubmit = useCallback(
    values => {
      dispatch(authSetPassword(values.password, jwt));
    },
    [dispatch, jwt]
  );

  return (
    <div>
      <h1>Reset password</h1>
      <Formik
        initialValues={{
          password: ''
        }}
        validationSchema={SET_PASSWORD}
        onSubmit={handleSubmit}
      >
        <Form>
          <FieldPassword
            name="password"
            label={'New password'}
            placeholder={'New password'}
          />
          <Button
            id="submit-password-btn"
            type="submit"
            label="Submit and save"
            className="primary"
            isLoading={isSetPasswordLoading}
          />
        </Form>
      </Formik>
    </div>
  );
};

export default SetNewPassword;

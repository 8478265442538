import React from 'react';
export const TEMPLATE_ID = '78cea302-77de-428b-896a-1d00c1780df2';
export const NODE_TYPE_ID = '117530d1-89cd-45bb-b40f-9394a9163ac6';

export const MOCK_FLOWS = [
  {
    ID: 'a1422dab-1a3c-4cd3-952d-c27ea374be81',
    name: 'flow 1',
    updated: '2019-09-23T12:45:00.321Z'
  },
  {
    ID: 'd7ca641b-5194-429d-9bf7-385a52c1c181',
    name: 'flow2',
    updated: '2019-08-16T21:30:15.123Z'
  }
];

export const MOCK_CONFIGURATIONS = [
  {
    ID: '16e3677f-0d92-4aa2-aa98-b037788a29f9',
    flowID: 'd7ca641b-5194-429d-9bf7-385a52c1c181',
    name: 'HSL vehicle monitoring',
    status: 'running',
    created: '2019-08-16T21:30:15.123Z',
    updated: '2019-08-16T21:30:15.123Z'
  }
];

export const MOCK_TEMPLATES = [
  {
    ID: 'a1422dab-1a3c-4cd3-952d-c27ea374be81',
    name: 'template 1',
    version: 3,
    created: '2019-09-23T12:45:00.321Z',
    nodes: [
      {
        ID: 'fdac6ec9-00da-46ec-89da-48e05edc36a8',
        flowID: 'a1422dab-1a3c-4cd3-952d-c27ea374be81',
        nodeTypeID: NODE_TYPE_ID,
        x: 12,
        y: 24
      }
    ]
  },
  {
    ID: 'd7ca641b-5194-429d-9bf7-385a52c1c181',
    name: 'template2',
    version: 5,
    created: '2019-08-16T21:30:15.123Z'
  }
];

export const MOCK_PARAMETERS = [
  {
    ID: '5158b57b-b689-44b0-9195-647c2bff470e',
    name: 'input',
    type: 'string',
    nodeTypeID: NODE_TYPE_ID,
    nodeTypeParameterID: '5158b57b-b689-44b0-9195-647c2bff470e',
    flowNodeID: MOCK_TEMPLATES[0].nodes[0].ID,
    value: 'http://www.test.com',
    configurationID: '572db644-8bd8-433f-981f-601ddf8f0d18'
  },
  {
    ID: '6a2f1ec1-e063-4556-a8e3-935325d252d9',
    name: 'input',
    type: 'string',
    nodeTypeID: NODE_TYPE_ID,
    nodeTypeParameterID: '6a2f1ec1-e063-4556-a8e3-935325d252d9',
    flowNodeID: MOCK_TEMPLATES[0].nodes[0].ID,
    value: 'http://www.test2.com',
    configurationID: '572db644-8bd8-433f-981f-601ddf8f0d18'
  },
  {
    ID: '6a2f1ec1-e063-4556-a8e3-935325d252d9',
    name: 'input',
    type: 'string',
    nodeTypeID: '3e049c9f-f55b-4c47-9f38-c5142d6c1807',
    nodeTypeParameterID: '6a2f1ec1-e063-4556-a8e3-935325d252d9',
    flowNodeID: MOCK_TEMPLATES[0].nodes[0].ID,
    value: 'http://www.test2.com',
    configurationID: 'fcf23446-ecc2-46f5-bdec-ed4d56cbfc74'
  }
];

export const MOCK_NODE_TYPES = [
  {
    ID: NODE_TYPE_ID,
    name: 'Youtube stream',
    description: 'Video streaming service from youtube to Kafka',
    group: 'video',
    icon: 'video',
    ports: [
      {
        ID: '404ad1b6-4f5b-47dc-8234-ad72eceed049',
        nodeTypeID: NODE_TYPE_ID,
        name: 'frames',
        direction: 'input',
        schema: ''
      },
      {
        ID: '1288d2d2-6ee9-45e4-a145-0a6d0035f56a',
        nodeTypeID: NODE_TYPE_ID,
        name: 'results',
        direction: 'output',
        schema: ''
      }
    ],
    connectors: [],
    parameters: [MOCK_PARAMETERS[0], MOCK_PARAMETERS[1]]
  },
  {
    ID: '3e049c9f-f55b-4c47-9f38-c5142d6c1807',
    name: 'Spark object detection',
    description: 'Video object detection service running on Spark',
    group: 'processing',
    icon: 'processor',
    connectors: [
      {
        ID: '35f3a8d6-0216-42df-b963-aff26b171116',
        nodeTypeID: NODE_TYPE_ID,
        destinationPortID: '404ad1b6-4f5b-47dc-8234-ad72eceed049',
        sourcePortID: '24f8e77f-ded6-457e-a8a2-16e900e15d88'
      }
    ],
    parameters: [MOCK_PARAMETERS[0], MOCK_PARAMETERS[1]]
  }
];

export const MOCK_SOURCE_MAGNET = (
  <circle
    id="v-15"
    port="117530d1-89cd-45bb-b40f-9394a9163ac6_404ad1b6-4f5b-47dc-8234-ad72eceed049"
    type="output"
  ></circle>
);
export const MOCK_DESTINATION_MAGNET = (
  <circle
    id="v-15"
    port="3e049c9f-f55b-4c47-9f38-c5142d6c1807_24f8e77f-ded6-457e-a8a2-16e900e15d88_404ad1b6-4f5b-47dc-8234-ad72eceed049"
    type="input"
  ></circle>
);

export const MOCK_VALIDATION_NODES = [
  {
    id: 'ae718b92-4329-4f35-a7b3-68b9256687c0',
    model: {
      attributes: {
        type: 'standard.HeaderedRecord',
        position: { x: 65, y: 98 },
        nodeTypeId: MOCK_NODE_TYPES[0].ID,
        z: 1
      }
    }
  },
  {
    id: 'b68ea29a-57a2-4d35-90be-b19bbc3099cb',
    model: {
      attributes: {
        type: 'standard.HeaderedRecord',
        position: { x: 368, y: 98 },
        nodeTypeId: MOCK_NODE_TYPES[1].ID,
        z: 2
      }
    }
  }
];

export const MOCK_TEMPLATE_NODES = [
  {
    id: 'ae718b92-4329-4f35-a7b3-68b9256687c0',
    externalId: '978c0713-47a7-445a-a02a-cbc4143f4e2a',
    type: 'standard.HeaderedRecord',
    position: { x: 65, y: 98 },
    nodeTypeId: MOCK_NODE_TYPES[0].ID,
    z: 1
  },
  {
    id: 'b68ea29a-57a2-4d35-90be-b19bbc3099cb',
    externalId: 'e5e6c70b-86f6-4a31-a57c-28bfff66e10f',
    type: 'standard.HeaderedRecord',
    position: { x: 368, y: 98 },
    nodeTypeId: MOCK_NODE_TYPES[1].ID,
    z: 2
  },
  {
    type: 'link',
    externalId: 'd857ea14-f3bd-4fea-bea3-32b941a641d6',
    source: {
      id: 'a0ece93b-6a62-42dd-8e1e-08fda820f352'
    },
    target: {
      id: 'e96d3aee-d331-46f9-97f5-4d6a477235ac',
      port: null
    },
    id: 'a2bb61c2-7a45-4eee-847e-04a42fdd3ae0'
  }
];

export const MOCK_NODES_WITH_PARAMETERS = [
  {
    ID: MOCK_TEMPLATES[0].nodes[0].ID,
    name: 'Youtube stream',
    description: 'Video streaming service from youtube to Kafka',
    group: 'VideoIn',
    nodeTypeID: NODE_TYPE_ID,
    parameters: MOCK_PARAMETERS
  }
];

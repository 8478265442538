import * as compareData from './CompareParameterInputs';
import { NODE_TYPE_COMPARE } from 'constants';

export default type => {
  switch (type) {
    case NODE_TYPE_COMPARE: {
      return compareData;
    }
    default:
      return null;
  }
};

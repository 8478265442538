import React from 'react';
import { showModal, hideModal } from 'store/actions/modal';
import { CREATE_MODAL } from 'store/actions/actionTypes';
import { isEmpty } from 'utilities';

export default ({ dispatch }) => next => action => {
  next(action);

  const closeModal = () => {
    dispatch(hideModal());
  };

  if (action.type === CREATE_MODAL) {
    let {
      className,
      type,
      entity,
      id,
      title,
      command,
      data,
      node,
      onConfirm,
      notification
    } = action.meta;

    switch (type) {
      case 'add':
        switch (entity) {
          case 'user':
            className = isEmpty(data) ? 'add-modal' : 'edit-modal';
            title = 'User';
            break;
          case 'source':
            className = 'add-modal';
            title = 'Assign data sources';
            break;
          default:
        }
        break;
      case 'delete':
        const { name, email } = data;
        className = 'delete-modal';
        switch (entity) {
          case 'user':
            node = email ? <p>{email}</p> : <p>No user data found</p>;
            break;
          case 'flow':
            title = `${command || type} ${entity}`;
            node = name ? <p>{name}</p> : <p>No {entity} found</p>;
            break;
          case 'data node':
            title = `${command || type} ${entity}`;
            node = name ? <p>{name}</p> : <p>No {entity} found</p>;
            break;
          default:
        }
        break;
      case 'confirm':
        className = 'confirm-modal';
        if (entity === 'configuration') {
          title = `${command || type} ${entity}`;
        }
        if (entity === 'integration') {
          title = `${command || type} ${entity}`;
        }
        break;
      case 'notification':
        className = 'notification-modal';
        title = `Notification`;
        break;
      default:
    }

    dispatch(
      showModal({
        modalType: type,
        modalProps: {
          className,
          title: title || entity,
          id,
          entity: {
            type: entity,
            command,
            node
          },
          confirmModal: onConfirm,
          closeModal,
          notification
        }
      })
    );
  }
};

import axios from 'axios';

const API_URL = window?.ENV?.API_URL;

const fetchDataSources = () =>
  axios({
    baseURL: API_URL,
    method: 'GET',
    url: 'sources/datasources',
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true
  });

const fetchUnassignedDataSources = () =>
  axios({
    baseURL: API_URL,
    method: 'GET',
    url: 'sources/datasources/unassigned',
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true
  });

const fetchDataTypes = () =>
  axios({
    baseURL: API_URL,
    method: 'GET',
    url: 'sources/datatypes',
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true
  });

const fetchDataSourcesByDataNodeId = ({ dataNodeId }) =>
  axios({
    baseURL: API_URL,
    method: 'GET',
    url: `sources/datasources/datanode/${dataNodeId}`,
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true
  });

const fetchDataNodes = () =>
  axios({
    baseURL: API_URL,
    method: 'GET',
    url: 'sources/datanodes',
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true
  });

const fetchDataNodesWithCounts = () =>
  axios({
    baseURL: API_URL,
    method: 'GET',
    url: 'sources/datanodes/count',
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true
  });

const fetchDatanodesPalette = () =>
  axios({
    baseURL: API_URL,
    method: 'GET',
    url: 'sources/datanodes/palette',
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true
  });

const createDataNode = data =>
  axios({
    baseURL: API_URL,
    method: 'POST',
    url: `sources/datanodes`,
    data: data,
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true
  });

const updateDataSource = (id, data) =>
  axios({
    baseURL: API_URL,
    method: 'PATCH',
    url: `sources/datasources/${id}`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: data,
    withCredentials: true
  });

const updateDataSources = dataSources =>
  axios({
    baseURL: API_URL,
    method: 'PATCH',
    url: `sources/datasources`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: dataSources,
    withCredentials: true
  });

const deleteDataSource = ({ dataSourceId }) =>
  axios({
    baseURL: API_URL,
    method: 'DELETE',
    url: `sources/datasources/${dataSourceId}`,
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true
  });

const deleteDataNode = ({ dataNodeId }) =>
  axios({
    baseURL: API_URL,
    method: 'DELETE',
    url: `sources/datanodes/${dataNodeId}`,
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true
  });

const uploadCsvFile = formData => {
  return axios({
    baseURL: API_URL,
    method: 'POST',
    data: formData,
    url: 'sources/datasources/csv',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    withCredentials: true
  });
};

export {
  fetchDataSources,
  fetchDataNodes,
  fetchDataSourcesByDataNodeId,
  fetchDataTypes,
  fetchDataNodesWithCounts,
  fetchUnassignedDataSources,
  fetchDatanodesPalette,
  createDataNode,
  updateDataSource,
  deleteDataSource,
  deleteDataNode,
  uploadCsvFile,
  updateDataSources
};

// Import the library
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faChartPie as falChartPie,
  faCodeBranch as falCodeBranch,
  faDatabase as falDatabase,
  faEmptySet as falEmptySet,
  faMicrochip as falMicrochip,
  faTimes as falTimes
} from '@fortawesome/pro-light-svg-icons';

import {
  faAngleLeft,
  faAngleRight,
  faChartPie,
  faCheck,
  faCog,
  faHome,
  faLocation,
  faPencil,
  faPlus,
  faArrowFromLeft,
  faArrowToRight,
  faBell,
  faBook,
  faCode,
  faRandom,
  faRobot,
  faScroll,
  faSignOut,
  faStethoscope,
  faSearch,
  faTimes,
  faUsers,
  faTrashAlt,
  faEyeSlash,
  faEye,
  faQuestionCircle,
  faSpinner,
  faClone,
  faMinus,
  faUnlink
} from '@fortawesome/pro-regular-svg-icons';

import {
  faCaretDown as fasCaretDown,
  faCircle as fasCircle,
  faMinus as fasMinus,
  faInfoCircle as fasInfoCircle,
  faTimesCircle as fasTimesCircle,
  faExclamationTriangle as fasExclamationTriangle,
  faExclamationCircle as fasExclamationCircle,
  faCheckCircle as fasCheckCircle,
  faThList as fasThList,
  faThLarge as fasThLarge,
  faMapMarkerAlt as fasMapMarkerAlt
} from '@fortawesome/pro-solid-svg-icons';

library.add(
  falChartPie,
  falCodeBranch,
  falDatabase,
  falEmptySet,
  falMicrochip,
  falTimes,
  faAngleLeft,
  faAngleRight,
  faBell,
  faChartPie,
  faCheck,
  faCog,
  faHome,
  faLocation,
  faPencil,
  faPlus,
  faArrowFromLeft,
  faArrowToRight,
  faBook,
  faCode,
  faRandom,
  faRobot,
  faScroll,
  faSignOut,
  faStethoscope,
  faSearch,
  faTimes,
  faUsers,
  faTrashAlt,
  fasCaretDown,
  fasCircle,
  fasInfoCircle,
  fasTimesCircle,
  fasExclamationTriangle,
  fasExclamationCircle,
  fasCheckCircle,
  fasThList,
  fasThLarge,
  faEye,
  faEyeSlash,
  faQuestionCircle,
  faSpinner,
  faClone,
  faMinus,
  fasMinus,
  fasMapMarkerAlt,
  faUnlink
);

import React, { useCallback } from 'react';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { resetPasswordLoaderSelector } from 'store/selectors';

import * as actionTypes from 'store/actions/actionTypes';

import { Button, Field } from 'components';

import { RESET_PASSWORD_SCHEMA } from 'schema/schema';

import './ResetPassword.scss';

const initialValues = { email: '' };

const ResetPassword = () => {
  const isResetPasswordLoading = useSelector(resetPasswordLoaderSelector);

  const dispatch = useDispatch();

  const handleResetPasswordRequest = useCallback(
    ({ email }) => {
      if (email) {
        dispatch({
          type: actionTypes.RESET_PASSWORD_REQUESTED,
          payload: email
        });
      }
    },
    [dispatch]
  );

  return (
    <div className="login request-password-reset">
      <h1>Reset password</h1>
      <Formik
        validationSchema={RESET_PASSWORD_SCHEMA}
        initialValues={initialValues}
        onSubmit={handleResetPasswordRequest}
      >
        <Form className="field-container">
          <label>Your email id</label>
          <div className="form-group">
            <Field
              // className="reset-password-field"
              type="email"
              name="email"
              placeholder="Your email id"
            />
            <Button
              id="reset-password-btn"
              type="submit"
              className="primary"
              label="Submit"
              isLoading={isResetPasswordLoading}
              disabled={isResetPasswordLoading}
            />
          </div>
        </Form>
      </Formik>
      <p className="form__description">
        If your email belongs to a RAIN account, we’ll send you a link to reset
        your password. The link expires in 15mins.
      </p>
    </div>
  );
};

export default ResetPassword;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { distanceInWordsToNow } from 'date-fns';
import { NoData, Icon } from 'components';
import { NOTIFICATION_ICONS } from 'utilities';
import './Notification.scss';

const Notification = ({ notification, className }) => {
  if (!notification) {
    return <NoData />;
  }

  if (typeof notification.content !== 'string') {
    return null;
  }

  const { type, created, content } = notification;
  const classes = classNames(className, 'notification');

  return (
    <div className={classes}>
      <div className="notification-icon">
        <Icon
          className={notification.type}
          name={['fas', NOTIFICATION_ICONS[type]]}
        />
      </div>
      <div className="notification-details">
        <span className={classNames('message', type)}>
          {content.toString()}
        </span>
        <span className="date">{distanceInWordsToNow(created)} ago</span>
      </div>
    </div>
  );
};

Notification.propTypes = {
  className: PropTypes.string,
  notification: PropTypes.shape({
    type: PropTypes.string,
    created: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    message: PropTypes.string
  }).isRequired
};

export default Notification;

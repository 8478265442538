import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FlowStatus } from 'constants';
import Icon from '../Icon/Icon';
import './Status.scss';

const Status = props => {
  const [status, setStatus] = useState(null);

  useEffect(() => {
    let status;
    switch (props.status) {
      case FlowStatus.Stopped:
        status = 'stopped';
        break;
      case FlowStatus.Running:
        status = 'running';
        break;
      case FlowStatus.Terminating:
        status = 'terminating';
        break;
      case FlowStatus.Failed:
        status = 'failed';
        break;
      case FlowStatus.Pending:
        status = 'pending';
        break;
      case FlowStatus.Unkown:
        status = 'unknown';
        break;
      default:
        status = '';
        break;
    }
    setStatus(status);
  }, [props]);

  const classes = classNames(props.className || 'status', status);

  return <Icon className={classes} name={['fas', 'circle']} />;
};

Status.propTypes = {
  className: PropTypes.string,
  status: PropTypes.string.isRequired
};

export default Status;

import { put, call } from 'redux-saga/effects';
import * as API from '../api';
import * as actionTypes from 'store/actions/actionTypes';
import { activateLoader, deactivateLoader } from './loaders';
import {
  showErrorNotification,
  showSuccessNotification
} from './notifications';

function* updateCustomer(action) {
  const { payload } = action;
  const { id, data } = payload;

  try {
    yield activateLoader({
      payload: { path: 'accountDetails.customer' }
    });

    yield call(API.updateCustomer, { id, data });

    yield put({
      type: actionTypes.UPDATE_CUSTOMER_DETAILS_SUCCEEDED
    });

    yield* showSuccessNotification({
      content: 'Customer details were successfully updated.'
    });
  } catch (e) {
    yield* showErrorNotification({
      content: 'Error updating customer.'
    });

    yield put({
      type: actionTypes.UPDATE_CUSTOMER_DETAILS_FAILED,
      payload: e.message
    });
  } finally {
    yield deactivateLoader({
      payload: { path: 'accountDetails.customer' }
    });
  }
}

function* getCustomerById(action) {
  const { payload } = action;
  const { id } = payload;

  try {
    yield activateLoader({
      payload: { path: 'accountDetails.customer' }
    });

    const { data } = yield call(API.getCustomer, { id });

    yield put({
      type: actionTypes.GET_CUSTOMER_DETAILS_SUCCEEDED,
      payload: data.response
    });
  } catch (e) {
    yield* showErrorNotification({
      content: 'User / Customer could not be found.'
    });

    yield put({
      type: actionTypes.GET_CUSTOMER_DETAILS_FAILED,
      payload: e.message
    });
  } finally {
    yield deactivateLoader({
      payload: { path: 'accountDetails.customer' }
    });
  }
}

export { updateCustomer, getCustomerById };

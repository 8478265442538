import { put, call, select } from 'redux-saga/effects';
import * as API from '../api';
import * as actionTypes from 'store/actions/actionTypes';
import { userSelector } from '../selectors';

import { FEATURE_FLAGS } from 'constants/featureFlags';

const { isArray } = Array;

function* batchEvaluateFlags(action) {
  const { flagKeys } = action?.payload ?? {};

  try {
    const user = yield select(userSelector);

    const { data } = yield call(API.batchEvaluateFlags, {
      user,
      flagKeys: isArray(flagKeys)
        ? [...flagKeys, FEATURE_FLAGS.REGISTRATION]
        : [FEATURE_FLAGS.REGISTRATION]
    });

    yield put({
      type: actionTypes.BATCH_EVALUATE_FEATURE_FLAGS_SUCCEEDED,
      payload: data?.evaluationResults
    });
  } catch (e) {
    yield put({
      type: actionTypes.BATCH_EVALUATE_FEATURE_FLAGS_FAILED,
      payload: e.message
    });
  }
}

function* evaluateFlag(action) {
  const { flagKey, evaluationContext } = action.payload;

  if (flagKey) {
    try {
      const user = yield select(userSelector);

      const { data } = yield call(API.evaluateFlag, {
        user,
        evaluationContext,
        flagKey
      });

      yield put({
        type: actionTypes.EVALUATE_FEATURE_FLAG_SUCCEEDED,
        payload: data
      });
    } catch (e) {
      yield put({
        type: actionTypes.EVALUATE_FEATURE_FLAG_FAILED,
        payload: e.message
      });
    }
  }
}

export { batchEvaluateFlags, evaluateFlag };

import React from 'react';

import { ToggleSwitch } from 'components';

import styles from './Toggle.module.scss';

const Toggle = ({ parameter, name }) => {
  return (
    <div className={styles.container}>
      <div className="label">{parameter.name}</div>
      <ToggleSwitch name={name} />
    </div>
  );
};

export default Toggle;

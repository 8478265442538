import React, { useMemo } from 'react';
import ReactTable from 'react-table';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Pagination from './Pagination';

import { DEFAULT_PAGE_SIZE } from 'constants';

import 'react-table/react-table.css';
import './Table.scss';

const Table = props => {
  const classes = classNames(props.className);

  const data = useMemo(() => {
    if (!!props.search) {
      return props.data.filter(d => rowFilter(d, props.search));
    }
    return props.data;
  }, [props.search, props.data]);

  if (props.pageSize && props.pageSize < data.length && props.pagination) {
    return (
      <ReactTable
        PaginationComponent={Pagination}
        key={props.pageSize || DEFAULT_PAGE_SIZE}
        id={props.id}
        className={classes}
        data={data}
        columns={props.columns}
        pageSize={props.pageSize || DEFAULT_PAGE_SIZE}
        showPagination
        minRows={props.minRows}
        filterable={props.filterable}
        defaultSorted={props.defaultSorted}
        loading={props.loading}
        getTrProps={props.getTrProps}
      />
    );
  } else {
    return (
      <ReactTable
        key={props.pageSize || DEFAULT_PAGE_SIZE}
        id={props.id}
        minRows={props.minRows}
        className={classes}
        data={data}
        columns={props.columns}
        defaultPageSize={props.pageSize || DEFAULT_PAGE_SIZE}
        showPagination={false}
        filterable={props.filterable}
        defaultSorted={props.defaultSorted}
        loading={props.loading}
        getTrProps={props.getTrProps}
      />
    );
  }
};

Table.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  columns: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  pageSize: PropTypes.number,
  pagination: PropTypes.bool,
  filterable: PropTypes.bool,
  search: PropTypes.string,
  defaultSorted: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      desc: PropTypes.bool
    })
  )
};

export default Table;

const rowFilter = (row, filter) => {
  if (!filter) return true;

  if (filter) {
    for (let key in row) {
      if (!row[key]) continue;

      if (
        row[key]
          .toString()
          .toLowerCase()
          .includes(filter.toLowerCase())
      ) {
        return true;
      }
    }

    return false;
  } else {
    return true;
  }
};

import * as actionTypes from 'store/actions/actionTypes';

const initialState = {
  selectedStencilTab: 0,
  isStencilVisible: false,
  error: null
};

const hideStencil = state => {
  return {
    ...state,
    isStencilVisible: false
  };
};

const showStencil = state => {
  return {
    ...state,
    isStencilVisible: true
  };
};

const setSelectedTab = (state, action) => {
  return {
    ...state,
    selectedStencilTab: action.selectedTab
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.HIDE_STENCIL:
      return hideStencil(state);
    case actionTypes.SHOW_STENCIL:
      return showStencil(state);
    case actionTypes.SET_SELECTED_TAB:
      return setSelectedTab(state, action);
    default:
      return state;
  }
};

export default reducer;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Field, ErrorMessage } from 'formik';
import './RadioGroup.scss';

const RadioGroup = ({ className, name, label, inputs, validate, onChange }) => {
  const classes = classNames(className || 'field-container');

  const radioItems = inputs.map(({ id, label, checked, value, component }) => {
    return (
      <div className={'radio-item'} key={`${name}_${value}`}>
        <Field
          id={id}
          className="radio"
          label={label}
          name={name}
          type={'radio'}
          value={value}
          checked={checked}
          validate={validate}
          onChange={onChange}
        />

        {component ? component : <label htmlFor={id}>{label}</label>}
      </div>
    );
  });

  return (
    <div className={classes}>
      <label htmlFor={name}>{label || name}</label>
      <div className="radio-items">{radioItems}</div>
      <ErrorMessage component="span" className="error" name={name} />
    </div>
  );
};

RadioGroup.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired,
  checked: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number
  ]),
  validate: PropTypes.func
};

export default RadioGroup;

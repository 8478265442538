import { createSelector } from 'reselect';

export const authSelector = createSelector(
  store => store.auth,
  auth => auth
);

export const authUserSelector = createSelector(authSelector, auth => auth.user);

export const authenticatedSelector = createSelector(
  authSelector,
  auth => auth.authenticated
);

export const userSelector = createSelector(authSelector, auth => auth?.user);

export const isAdminSelector = createSelector(
  authSelector,
  auth => auth?.user?.isAdmin
);

export const authUserId = createSelector(authSelector, auth => auth?.user?.id);

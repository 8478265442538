import { put, call, delay } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import * as API from '../api';
import * as actionTypes from '../actions/actionTypes';
import { authCheck } from '../actions/auth';
import { activateLoader, deactivateLoader } from './loaders';
import removeCookie from 'utilities/removeCookie';

import {
  showErrorNotification,
  showSuccessNotification
} from './notifications';

import { ROUTES } from 'constants/index';

function* resetPassword(action) {
  const { payload } = action;
  const email = { toAddress: payload };

  try {
    yield activateLoader({
      payload: { path: 'passwordReset.isSent' }
    });

    yield call(API.resetPassword, email);

    yield put({ type: actionTypes.RESET_PASSWORD_SUCCEEDED, payload: email });

    yield* showSuccessNotification({
      content:
        'Password reset link should be in your inbox soon. Make sure to check Spam as well, just in case.'
    });
  } catch (e) {
    yield* showErrorNotification({
      content: 'Password could not be reset or saved.'
    });

    yield put({
      type: actionTypes.RESET_PASSWORD_FAILED,
      message: e.message
    });
  } finally {
    yield deactivateLoader({
      payload: { path: 'passwordReset.isSent' }
    });
  }
}

function* authorizeNewUser(action) {
  const { jwt, redirect } = action.payload;

  try {
    yield activateLoader({
      payload: { path: 'accountDetails.page' }
    });

    const { data } = yield call(API.checkAuth, { jwt });

    if (!data.response) {
      yield put({
        type: actionTypes.AUTH_CHECK_FAILED,
        error: data.response
      });
    } else {
      yield put({
        type: actionTypes.AUTH_CHECK_SUCCEEDED,
        payload: data.response.user
      });
    }

    if (typeof redirect === 'function') redirect();
  } catch (e) {
    yield* showErrorNotification({
      content: 'Something went wrong trying to authorizing your account.'
    });
    yield put({ type: actionTypes.AUTHORIZE_USER_FAILED, message: e.message });
  } finally {
    yield deactivateLoader({
      payload: { path: 'accountDetails.page' }
    });
  }
}

function* authorizeUser() {
  try {
    const { data } = yield call(API.checkAuth);
    if (data) {
      yield put({
        type: actionTypes.AUTH_CHECK_SUCCEEDED,
        payload: data.response.user
      });
    }
  } catch (e) {
    yield put({
      type: actionTypes.AUTH_CHECK_FAILED,
      error: e.response
    });
  }
}

function* loginUser(action) {
  const { email, password } = action.payload;

  try {
    if (email && password) {
      yield activateLoader({
        payload: { path: 'auth.page' }
      });

      const { data } = yield call(API.signIn, { email, password });

      if (data.response) {
        yield put({
          type: actionTypes.LOG_IN_USER_SUCCEEDED,
          payload: data.response.user
        });
      } else {
        throw new Error(
          'You are either not logged in or not authorised for that action.'
        );
      }
    }
  } catch (error) {
    yield* showErrorNotification({
      content: 'User/password combination not found.'
    });
    yield put({
      type: actionTypes.LOG_IN_USER_FAILED,
      payload: error.message
    });
  } finally {
    yield deactivateLoader({
      payload: { path: 'auth.page' }
    });

    yield* authorizeUser();
  }
}

function* logoutUser() {
  try {
    yield call(API.signOut);

    removeCookie();
    yield put({
      type: actionTypes.LOG_OUT_USER_SUCCEEDED
    });
  } catch (error) {
    yield put({
      type: actionTypes.LOG_OUT_USER_FAILED,
      payload: error.message
    });
  }
}

function* registerUser(action) {
  const { email, password } = action.payload;

  try {
    if (email && password) {
      yield activateLoader({
        payload: { path: 'auth.page' }
      });

      yield call(API.register, { email, password });

      yield* showSuccessNotification({
        content:
          'Confirmation link should be in your inbox soon. Make sure to check Spam as well, just in case.'
      });
    }
  } catch (error) {
    yield* showErrorNotification({
      content: 'There was a problem while performing registration.'
    });
  } finally {
    yield deactivateLoader({
      payload: { path: 'auth.page' }
    });
  }
}

function* setPassword(action) {
  const { password, jwt } = action.payload;

  try {
    if (password) {
      yield activateLoader({
        payload: { path: 'auth.setPassword' }
      });

      yield call(API.setPassword, { password, jwt });

      yield* showSuccessNotification({
        content: 'Password has been set successfully.'
      });

      yield put({ type: actionTypes.AUTH_SET_PASSWORD_SUCCEEDED });

      yield delay(3500);

      yield put(authCheck());
      yield put(push(ROUTES.FLOWS.ROOT));
    }
  } catch (error) {
    yield* showErrorNotification({
      content: 'The token has already expired. Try requesting access again.'
    });
    yield put({
      type: actionTypes.AUTH_SET_PASSWORD_FAILED,
      payload: error.message
    });
  } finally {
    yield deactivateLoader({
      payload: { path: 'auth.setPassword' }
    });
  }
}

function* checkAuth() {
  try {
    const {
      data: { response }
    } = yield call(API.authCheck);

    if (!response) {
      throw Error('Authentication failed!');
    }

    yield put({
      type: actionTypes.AUTH_CHECK_SUCCEEDED,
      payload: response.user
    });
  } catch (error) {
    yield put({
      type: actionTypes.AUTH_CHECK_FAILED,
      payload: error.message
    });
  }
}

export {
  authorizeNewUser,
  loginUser,
  logoutUser,
  resetPassword,
  registerUser,
  setPassword,
  checkAuth
};

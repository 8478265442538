import produce, { enableMapSet } from 'immer';
import * as actionTypes from 'store/actions/actionTypes';

const initialState = {
  data: new Map()
};

enableMapSet();

const { isArray } = Array;

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case actionTypes.BATCH_EVALUATE_FEATURE_FLAGS_SUCCEEDED: {
      const evaluationResults = action.payload;

      if (isArray(evaluationResults)) {
        evaluationResults.forEach(evaluationResult => {
          const { flagKey } = evaluationResult;

          if (draft.data.has(flagKey)) draft.data.delete(flagKey);

          draft.data.set(flagKey, evaluationResult);
        });
      }

      break;
    }

    case actionTypes.EVALUATE_FEATURE_FLAG_SUCCEEDED: {
      const evaluationResult = action.payload;
      const { flagKey } = evaluationResult;

      if (draft.data.has(flagKey)) draft.data.delete(flagKey);

      draft.data.set(flagKey, evaluationResult);

      break;
    }

    default:
      break;
  }
}, initialState);

export default reducer;

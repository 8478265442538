import * as actionTypes from 'store/actions/actionTypes';

export const updateCustomerDetails = (id, data) => ({
  type: actionTypes.UPDATE_CUSTOMER_DETAILS_REQUESTED,
  payload: { id, data }
});

export const getCustomerDetailsById = id => ({
  type: actionTypes.GET_CUSTOMER_DETAILS_REQUESTED,
  payload: { id }
});

export const getUsers = () => ({
  type: actionTypes.GET_USERS_REQUESTED
});

export const deleteUser = id => ({
  type: actionTypes.DELETE_USER_REQUESTED,
  payload: { id }
});

export const updateUser = (id, data) => ({
  type: actionTypes.UPDATE_USER_REQUESTED,
  payload: { id, data }
});

export const inviteUsers = data => ({
  type: actionTypes.USERS_INVITE_REQUESTED,
  payload: data
});

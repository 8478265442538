import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '../Icon/Icon';
import Input from '../Input/Input';

const Search = ({
  className,
  value,
  placeholder = 'Select by name',
  onChange,
  onClearClick
}) => {
  const classes = classnames(className || 'search-input');

  return (
    <div id={'search'} className={classes}>
      <Icon className="search-icon" name={['far', 'search']} />
      <Input placeholder={placeholder} onChange={onChange} value={value} />
      <Icon
        className="search-clear"
        name={['fas', 'times-circle']}
        onClick={onClearClick}
      />
    </div>
  );
};

// Set default props
Search.defaultProps = {
  onChange: () => void 0
};

Search.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onClearClick: PropTypes.func
};

export default Search;

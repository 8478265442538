import React from 'react';
import PropTypes from 'prop-types';

import { ItemsTable, WidgetWrapper } from 'components';

const TableView = ({ data, title }) => {
  return (
    <WidgetWrapper name="Table" title={title}>
      <ItemsTable data={data.items || []} />
    </WidgetWrapper>
  );
};

TableView.propTypes = {
  data: PropTypes.arrayOf([PropTypes.any])
};

TableView.defaultProps = {
  data: []
};

export default TableView;

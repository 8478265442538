import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import {
  FlexibleWidthXYPlot,
  XAxis,
  YAxis,
  VerticalBarSeries,
  VerticalGridLines,
  HorizontalGridLines
} from 'react-vis';

import { WidgetWrapper } from 'components';
import { chartColors } from 'utilities/transform';

const StackedBarChart = ({
  className,
  data,
  domainMin = 0,
  title,
  name,
  isMocked
}) => {
  const processedData = (data?.items ?? []).map(({ value }, index) => ({
    data: [{ x: data?.items?.map(d => d.key).join(', '), y: value }],
    cluster: 'compare',
    color: chartColors[Math.floor(index % (data?.items ?? []).length)]
  }));

  const [maxYDomain, setMaxYDomain] = useState(15);

  const classes = classNames(className || 'stacked-bar-chart');
  const chartHeight = 400;

  useEffect(() => {
    const actualYDomainSum = processedData.reduce(
      (acc, item) => acc + get(item, 'data[0].y'),
      0
    );

    if (actualYDomainSum != 0 && maxYDomain - 10 <= actualYDomainSum) {
      setMaxYDomain(actualYDomainSum * 1.5);
    }
  }, [processedData, maxYDomain]);

  const charts = cloneDeep(processedData)
    .sort((a, b) => get(a, 'data[0].y') - get(b, 'data[0].y'))
    .map((item, index, original) => {
      if (index > 0) {
        const originalValue = get(item, `data[0].y`, null);

        if (originalValue) {
          const localItem = cloneDeep(item);
          localItem.data[0].y =
            originalValue +
            original
              .slice(0, index)
              .reduce((acc, item) => acc + get(item, 'data[0].y'), 0);
          return localItem;
        }
        return item;
      } else {
        return item;
      }
    })
    .sort((a, b) => get(b, 'data[0].y') - get(a, 'data[0].y'))
    .map(({ cluster, color, data }) => (
      <VerticalBarSeries
        key={color}
        cluster={cluster}
        color={color}
        stroke={'white'}
        data={data}
      />
    ));

  const fakeItem = useMemo(() => {
    if (charts.length === 0) {
      if (isMocked) {
        return [
          <VerticalBarSeries
            key="second"
            cluster="compare"
            color="var(--color-grey)"
            data={[{ x: '', y: 10 }]}
          />,
          <VerticalBarSeries
            key="first"
            cluster="compare"
            color="var(--color-grey-dark)"
            data={[{ x: '', y: 5 }]}
          />
        ];
      }
      return (
        <VerticalBarSeries
          cluster="compare"
          color="transparent"
          data={[{ x: '', y: 0 }]}
        />
      );
    }
  }, [charts, isMocked]);

  return (
    <WidgetWrapper title={title} name={name}>
      <div className={classes}>
        <FlexibleWidthXYPlot
          xType="ordinal"
          margin={{ left: maxYDomain > 1000 ? 70 : 40 }}
          height={chartHeight}
          yDomain={[domainMin, maxYDomain]}
        >
          <VerticalGridLines />
          <HorizontalGridLines />
          <XAxis />
          <YAxis />
          {fakeItem}
          {data?.items?.length > 0 ? charts : null}
        </FlexibleWidthXYPlot>
      </div>
    </WidgetWrapper>
  );
};

StackedBarChart.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      cluster: PropTypes.string,
      color: PropTypes.string,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          y: PropTypes.number,
          x: PropTypes.string
        })
      )
    })
  ),
  domainMin: PropTypes.number,
  domainMax: PropTypes.number
};

export default StackedBarChart;

import { put } from 'redux-saga/effects';

import * as actionTypes from '../actions/actionTypes';

function* activateLoader(action) {
  const { path } = action.payload;
  yield put({ type: actionTypes.SET_LOADER, payload: { path, value: true } });
}

function* deactivateLoader(action) {
  const { path } = action.payload;
  yield put({ type: actionTypes.SET_LOADER, payload: { path, value: false } });
}

function* setLoader(action) {
  const { path, value } = action.payload;

  if (path) {
    yield put({
      type: actionTypes.SET_LOADER,
      payload: { path, value: Boolean(value) }
    });
  }
}

export { activateLoader, deactivateLoader, setLoader };

import * as actionTypes from 'store/actions/actionTypes';
import {
  AddPersistentStateItemSucceededAction,
  LoadPersistentStateSucceededAction,
  LoadPersistentStateAction
} from 'store/actions/Action';

export interface PersistentState {
  data: {
    [key: string]: any;
  };
}

const initialState: PersistentState = {
  data: {}
};

const addPersistentStateItem = (
  state: PersistentState,
  action: AddPersistentStateItemSucceededAction
) => {
  return {
    ...state,
    data: {
      ...action.payload
    }
  };
};

const loadPersistentStore = (
  state: PersistentState,
  action: LoadPersistentStateSucceededAction
) => {
  return {
    ...state,
    data: {
      ...state.data,
      ...action.payload
    }
  };
};

const removePersistentStateItem = (
  state: PersistentState,
  action: LoadPersistentStateAction
) => {
  const key = action.payload;
  return {
    ...state,
    data: {
      ...state.data,
      [key]: []
    }
  };
};

const removePersistentStateItems = (
  state: PersistentState,
  action: LoadPersistentStateAction
) => {
  return {
    ...state,
    data: action.payload
  };
};

const reducer = (
  state = initialState,
  action: LoadPersistentStateAction | AddPersistentStateItemSucceededAction
) => {
  switch (action.type) {
    case actionTypes.ADD_PERSISTENT_STATE_ITEM_SUCCEEDED:
      return addPersistentStateItem(
        state,
        action as AddPersistentStateItemSucceededAction
      );
    case actionTypes.LOAD_PERSISTENT_STATE_SUCCEEDED:
      return loadPersistentStore(
        state,
        action as LoadPersistentStateSucceededAction
      );
    case actionTypes.CLEAR_PERSISTENT_STATE_ITEM_SUCCEEDED:
      return removePersistentStateItem(
        state,
        action as LoadPersistentStateAction
      );
    case actionTypes.REMOVE_PERSISTENT_STATE_ITEMS_SUCCEEDED:
      return removePersistentStateItems(
        state,
        action as LoadPersistentStateAction
      );
    default:
      return state;
  }
};

export default reducer;

import React, { useRef } from 'react';
import { useClickAway } from 'react-use';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Button from '../Button/Button';
import './Modal.scss';

const Modal = ({ className, isShowing, title, hide, children }) => {
  const ref = useRef(null);
  const targetElement = document.getElementById('modal-root');

  useClickAway(ref, () => {
    if (hide && typeof hide === 'function') hide();
  });

  return isShowing
    ? ReactDOM.createPortal(
        <div className="modal-overlay">
          <div className={classnames('modal', className)} ref={ref}>
            <div className="modal-header">
              <h1>{title || 'Modal'}</h1>
              <Button
                className="icon modal-close-button"
                type="button"
                data-dismiss="modal"
                onClick={hide}
                icon="times"
              />
            </div>
            <div className="modal-body">{children}</div>
          </div>
        </div>,
        targetElement
      )
    : null;
};

Modal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  isShowing: PropTypes.bool,
  title: PropTypes.string,
  hide: PropTypes.func.isRequired
};

export default Modal;

import { Slider } from 'components';
import { getFieldName } from 'utilities';

const SliderParameter = ({ parameter, isTimeInput }) => (
  <Slider
    key={parameter.ID}
    isTimeInput={isTimeInput}
    name={getFieldName(
      parameter.type,
      parameter.ID,
      parameter.flowNodeID,
      parameter.nodeTypeParameterID
    )}
    label={parameter.name}
    defaultValue={Number(parameter.default)}
    max={Number(parameter.max)}
    min={Number(parameter.min)}
  />
);

export default SliderParameter;

import React from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';

import { Icon, Wrapper } from './styled';

import './InfoIcon.scss';

const InfoIcon = ({ children, ...rest }) => {
  const placement = rest.place ? rest.place : 'top';

  return (
    <Wrapper data-for="info-icon-tooltip" data-tip={children} {...rest}>
      <Icon name={['fas', 'info-circle']} />
      <ReactTooltip
        id="info-icon-tooltip"
        place={placement}
        effect="solid"
        multiline
        html
        className="tooltip-body"
      />
    </Wrapper>
  );
};

InfoIcon.propTypes = {
  children: PropTypes.node,
  place: PropTypes.string
};

export default InfoIcon;

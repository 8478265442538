import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { convertGraphDataToObject } from 'utilities/charts';
import {
  StackedBarChart,
  NoData,
  WidgetWrapper,
  BarChart,
  PieChart,
  DonutChart,
  LineChart
} from 'components';
import { FlowStatus } from 'constants';

const CompareView = ({ data = {}, visualizationStyle, title, flowStatus }) => {
  const {
    graphs: {
      lineGraph,
      barGraph,
      areaGraph,
      treeMapDiagram,
      stackedBarGraph,
      stackedAreaGraph,
      pieChart,
      donutChart
    }
  } = convertGraphDataToObject(visualizationStyle);

  const isMocked = useMemo(() => flowStatus !== FlowStatus.Running, [
    flowStatus
  ]);

  return (
    <React.Fragment>
      {/* IMPLEMENTED WIDGETS */}

      {stackedBarGraph ? (
        <StackedBarChart
          data={data}
          domainMin={0}
          title={title}
          name="Stacked Bar Graph"
          isMocked={isMocked}
        />
      ) : null}

      {pieChart ? (
        <PieChart
          data={data}
          title={title}
          name="Pie Chart"
          isMocked={isMocked}
        />
      ) : null}

      {donutChart ? (
        <DonutChart
          data={data}
          title={title}
          name="Donut Chart"
          isMocked={isMocked}
        />
      ) : null}

      {barGraph ? (
        <BarChart
          data={data}
          title={title}
          name="Bar Graph"
          isMocked={isMocked}
        />
      ) : null}

      {lineGraph ? (
        <LineChart
          data={data}
          title={title}
          name="Line Chart"
          isMocked={isMocked}
        />
      ) : null}

      {/* NOT IMPLEMENTED */}
      {areaGraph ? (
        <WidgetWrapper title={title} name="Area Graph">
          <NoData />
        </WidgetWrapper>
      ) : null}
      {treeMapDiagram ? (
        <WidgetWrapper title={title} name="Tree Map Diagram">
          <NoData />
        </WidgetWrapper>
      ) : null}
      {stackedAreaGraph ? (
        <WidgetWrapper title={title} name="Stacked Area Graph">
          <NoData />
        </WidgetWrapper>
      ) : null}
    </React.Fragment>
  );
};

CompareView.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string
};

export default CompareView;

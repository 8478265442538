import produce from 'immer';
import * as actionTypes from 'store/actions/actionTypes';

const initialState = {
  flow: { fetched: false, isLoading: false },
  flows: [],
  nodeTypes: [],
  error: null
};

const fetchFlowSuccess = (draft, action) => {
  draft.flow = { ...action.payload, fetched: true, isLoading: false };
  draft.error = null;

  return draft;
};

const clearFlow = draft => {
  draft.flow = initialState.flow;
  return draft;
};

const fetchFlowsSuccess = (state, action) => {
  return {
    ...state,
    flows: action.payload,
    error: null
  };
};

const fetchNodeTypesSuccess = (state, action) => {
  action.payload.sort((a, b) => {
    if (a.group < b.group) {
      return -1;
    }
    if (a.group > b.group) {
      return 1;
    }
    return 0;
  });

  return {
    ...state,
    nodeTypes: action.payload,
    error: null
  };
};

const updateFlowSucceeded = (draft, action) => {
  const updatedFlow = action.payload;
  const position = draft.flows.findIndex(i => i.ID === updatedFlow.ID);

  draft.flow = {
    fetched: true,
    isLoading: false,
    ...updatedFlow
  };

  draft.flows[position] = {
    fetched: true,
    ...updatedFlow
  };

  return draft;
};
const setLoaderTrue = draft => {
  draft.flow.isLoading = true;
  return draft;
};

const reducer = produce((draft, action) => {
  switch (action.type) {
    // FLOW ACTIONS
    case actionTypes.CLEAR_FLOW_DATA:
      return clearFlow(draft, action);
    case actionTypes.FETCH_FLOW_SUCCESS:
      return fetchFlowSuccess(draft, action);
    case actionTypes.UPDATE_FLOW_NODES_SUCCEEDED:
    case actionTypes.UPDATE_FLOW_SUCCEEDED:
      return updateFlowSucceeded(draft, action);
    // FLOWS ACTIONS
    case actionTypes.FETCH_FLOWS_SUCCESS:
      return fetchFlowsSuccess(draft, action);
    // NODE TYPES ACTIONS
    case actionTypes.FETCH_NODE_DEFINITIONS_SUCCEEDED:
      return fetchNodeTypesSuccess(draft, action);
    case actionTypes.UPDATE_FLOW_NODES_REQUESTED:
      return setLoaderTrue(draft);

    default:
      break;
  }
}, initialState);

export default reducer;

/* eslint react/jsx-no-bind: [2, {
    "allowArrowFunctions": true
}] */
import React, { useCallback, useState, useEffect } from 'react';
import { useMount } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Formik, Form, FieldArray } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import get from 'lodash/get';

import { FlowStatus } from 'constants';
import { convertGraphDataToObject } from 'utilities/charts';
import { parseField } from 'utilities/flow';

import { isRequired } from 'schema/schema';
import { flowIsLoading } from 'store/selectors/flow';
import {
  AnalyzeNodeTypes,
  VisibilityParameters,
  NODE_TYPE_COMPARE
} from 'constants';

import {
  getNodeTypeName,
  getFieldName,
  findArrayValueById,
  filterArrayByParameter,
  findArrayObjectById
} from 'utilities';
import { closeRightPanel } from 'store/actions/panels';
import { Button, Accordion } from 'components';

import AdvancedSettings from './AdvancedSettings/AdvancedSettings';
import NodeParameter from './NodeParameter';
import Integrations from './Integrations';
import generateInputsData from './ParameterInputs/generateInputsData';

import './ConfigurationForm.scss';

const ConfigurationForm = ({
  editing,
  nodes,
  nodeTypes,
  parameters,
  handleCancel,
  handleSubmit,
  selectedNodeId,
  flowStatus,
  empty
}) => {
  const dispatch = useDispatch();

  const [nodesWithParameters, setNodesWithParameters] = useState([]);

  const isLoading = useSelector(flowIsLoading);

  useMount(() => {
    setNodesWithParameters(
      nodes
        ? nodes.map(node => {
            // parameter definitions for particular node
            const nodeTypeParameters =
              findArrayValueById(nodeTypes, node.nodeTypeID, 'parameters') ||
              [];

            const nodeTypeIntegrations =
              findArrayValueById(nodeTypes, node.nodeTypeID, 'integrations') ||
              null;

            // parameters for particular node
            let nodeParameters =
              (editing
                ? filterArrayByParameter(parameters, node.ID, 'flowNodeID')
                : nodeTypeParameters) || [];

            // parameters that does not exist in configuration yet but is required by the node
            let missingParameters = [];

            if (editing) {
              missingParameters = nodeTypeParameters
                .filter(
                  ({ ID }) =>
                    !nodeParameters.find(
                      nodeParam => nodeParam.nodeTypeParameterID === ID
                    )
                )
                .map(parameter => ({
                  ...parameter,
                  ID: uuidv4(),
                  nodeTypeParameterID: parameter.ID,
                  flowNodeID: node.ID
                }));

              // enhance current configuration params
              nodeParameters = nodeParameters.map(nodeParameter => {
                const nodeTypeParameter = findArrayObjectById(
                  nodeTypeParameters,
                  nodeParameter.nodeTypeParameterID
                );
                return {
                  ...nodeTypeParameter,
                  ...nodeParameter,
                  flowNodeID: node.ID
                };
              });
            } else {
              nodeParameters = nodeParameters.map(
                ({ ID, ...parameterData }) => ({
                  ...parameterData,
                  nodeTypeParameterID: ID,
                  ID: uuidv4(),
                  type: parameterData.type || 'string',
                  flowNodeID: node.ID
                })
              );
            }
            return {
              ...node,
              nodeTypeName: getNodeTypeName(nodeTypes, node.nodeTypeID),
              integrations: nodeTypeIntegrations,
              parameters: [...nodeParameters, ...missingParameters]
            };
          })
        : []
    );
  });

  const [activeAccordion, setActiveAccordion] = useState(
    nodesWithParameters ? selectedNodeId : null
  );

  useEffect(() => {
    setActiveAccordion(selectedNodeId);
  }, [selectedNodeId]);

  const validate = (required, formProps, name) => {
    if (required) {
      return isRequired(formProps.values[name]);
    } else {
      return '';
    }
  };

  const onSubmit = useCallback(
    values => {
      const changedValues = {};

      // this is needed to stringify objects if we have any
      // applies for compare node type for example.
      Object.entries(values).forEach(([key, value]) => {
        if (typeof value === 'object' || typeof value === 'boolean') {
          changedValues[key] = JSON.stringify(value);
        }
      });

      dispatch(closeRightPanel());
      handleSubmit({ ...values, ...changedValues }, nodesWithParameters);
    },
    [handleSubmit, nodesWithParameters, dispatch]
  );

  const toggleHandler = useCallback(
    id => {
      activeAccordion === id ? setActiveAccordion('') : setActiveAccordion(id);
    },
    [activeAccordion]
  );

  const getAdvancedParameters = node => {
    if (node.nodeTypeName === NODE_TYPE_COMPARE) {
      return [];
    }
    return node.parameters.filter(
      parameter => parameter.visibility === VisibilityParameters.ADVANCED
    );
  };

  return (
    <div id="configuration-form" className={classNames('form')}>
      <div className="right-panel-header">
        <h1>{editing ? 'Edit configuration' : 'Create a new configuration'}</h1>
        <Button
          className="icon"
          onClick={handleCancel}
          icon={['fal', 'times']}
        />
      </div>
      <Formik
        enableReinitialize
        initialValues={getInitialValues(nodesWithParameters)}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        render={props => (
          <Form>
            <div className="form-content">
              {nodesWithParameters
                .filter(n => (empty ? true : n.ID === selectedNodeId))
                .map(node =>
                  node.parameters.length > 0 ? (
                    <FieldArray
                      key={node.ID}
                      name="node"
                      render={() => (
                        <Accordion
                          togglerHidden={flowStatus !== FlowStatus.Running}
                          id={node.ID}
                          className="form-segment"
                          title={node.nodeTypeName}
                          onToggle={toggleHandler}
                          activeId={activeAccordion}
                          forceOpened={
                            Object.keys(props.errors)
                              .map(key => parseField(key).flowNodeID)
                              .indexOf(node.ID) !== -1
                          }
                          body={
                            <React.Fragment>
                              {get(node, 'integrations', null) ? (
                                <Integrations handleSelect={props.setValues} />
                              ) : null}
                              {getAdvancedParameters(node).length > 0 ? (
                                <AdvancedSettings
                                  {...props}
                                  advancedParameters={getAdvancedParameters(
                                    node
                                  )}
                                  integrationType={get(
                                    node,
                                    'integrations.auth'
                                  )}
                                  validate={validate}
                                />
                              ) : null}
                              <Integrations
                                handleSelect={props.setValues}
                                integrationType={get(node, 'integrations.auth')}
                              />
                              {[NODE_TYPE_COMPARE].indexOf(
                                node.nodeTypeName
                              ) !== -1
                                ? (() => {
                                    const { Inputs } = generateInputsData(
                                      node.nodeTypeName
                                    );
                                    return (
                                      <Inputs
                                        parameters={node.parameters}
                                        values={props.values}
                                        getFieldName={getFieldName}
                                        validate={validate}
                                        formProps={props}
                                      />
                                    );
                                  })()
                                : node.parameters.map(parameter =>
                                    parameter.visibility === 'basic' ? (
                                      <NodeParameter
                                        {...props}
                                        validate={validate}
                                        parameter={parameter}
                                        key={parameter.ID}
                                        integrationType={get(
                                          node,
                                          'integrations.auth'
                                        )}
                                      />
                                    ) : null
                                  )}
                            </React.Fragment>
                          }
                        />
                      )}
                    />
                  ) : null
                )}
            </div>

            <div className="options-bar">
              <Button
                id="cancel-btn"
                type="button"
                onClick={handleCancel}
                label="Cancel"
                disabled={isLoading}
              />
              <Button
                id="save-configuration-btn"
                type="submit"
                className="primary"
                label="Save"
                isLoading={isLoading}
              />
            </div>
          </Form>
        )}
      />
    </div>
  );
};

ConfigurationForm.propTypes = {
  inProgress: PropTypes.bool,
  editing: PropTypes.bool,
  configuration: PropTypes.shape({
    ID: PropTypes.string,
    name: PropTypes.string,
    flowID: PropTypes.string
  }),
  nodes: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.string,
      name: PropTypes.string,
      nodeTypeID: PropTypes.string
    })
  ),
  nodeTypes: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.string,
      name: PropTypes.string,
      parameters: PropTypes.arrayOf(
        PropTypes.shape({
          ID: PropTypes.string,
          name: PropTypes.string,
          type: PropTypes.string,
          value: PropTypes.string,
          default: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          max: PropTypes.string,
          min: PropTypes.string,
          flowNodeID: PropTypes.string,
          nodeTypeID: PropTypes.string
        })
      )
    })
  ),
  parameters: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.string,
      value: PropTypes.string,
      default: PropTypes.number,
      max: PropTypes.number,
      min: PropTypes.number,
      nodeTypeID: PropTypes.string
    })
  ),
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

export default ConfigurationForm;

export const getInitialValues = nodes => {
  const initialValues = {};
  if (nodes) {
    nodes.map(node => {
      if (!node.parameters || node.parameters.length === 0) return null;
      return node.parameters.map(parameter => {
        let customValue;
        // check if value needs some adjustments before go to initialValues
        if (
          parameter.name === 'visualization type' &&
          node.nodeTypeName === AnalyzeNodeTypes.COMPARE_WIDGET
        ) {
          customValue = convertGraphDataToObject(parameter.value);
        }

        let defaultValue = customValue
          ? customValue
          : parameter.value || parameter.default || '';

        const value =
          parameter.type === 'boolean' ? defaultValue === 'true' : defaultValue;
        return (initialValues[
          getFieldName(
            parameter.type,
            parameter.ID,
            parameter.flowNodeID,
            parameter.nodeTypeParameterID
          )
        ] = value);
      });
    });
  }

  return initialValues;
};

import React from 'react';

import { Route, Switch } from 'react-router-dom';

import SetNewPasswordContainer from 'containers/Authorization/SetNewPassword';
import ResetPasswordContainer from 'containers/Authorization/ResetPassword';

import SetPasswordContainer from 'containers/Authorization/SetPassword';

import { ROUTES } from 'constants';

const Authorization = () => (
  <Switch>
    <Route
      path={ROUTES.AUTH.PASSWORD.RESET}
      exact
      component={ResetPasswordContainer}
    />
    <Route
      path={ROUTES.AUTH.PASSWORD.SET_NEW}
      component={SetNewPasswordContainer}
    />
    <Route
      path={ROUTES.AUTH.PASSWORD.SET_INVITED}
      component={SetPasswordContainer}
    />
  </Switch>
);

export default Authorization;

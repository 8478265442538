import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Button, Input } from 'components';

const FlowTable = ({
  data,
  columns,
  getTdProps,
  addTemplate,
  defaultSorted,
  className,
  isDropdown,
  onClick
}) => {
  const [search, setSearch] = useState('');

  const onSearch = useCallback(event => {
    setSearch(event.target.value);
  }, []);

  return (
    <div className="card">
      <div className="table-toolbar">
        <div className="toolbar-item-wrapper">
          <Input
            type="search"
            placeholder="Search"
            onChange={onSearch}
            value={search}
          />
        </div>
        <span>
          {isDropdown ? (
            <div className="toolbar-item-wrapper">
              <Button
                type="button"
                className="stencil-close-button icon"
                onClick={onClick}
                icon="times"
              />
            </div>
          ) : (
            <Button
              className="primary"
              onClick={addTemplate}
              icon="plus"
              label="New template"
            />
          )}
        </span>
      </div>
      <Table
        id="templateTable"
        className={className}
        data={data}
        columns={columns}
        getTdProps={getTdProps}
        pageSize={data.length}
        pagination={false}
        search={search}
        defaultSorted={defaultSorted}
      />
    </div>
  );
};

FlowTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string
    })
  ).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
        .isRequired,
      accessor: PropTypes.string.isRequired,
      Cell: PropTypes.any, //eslint-disable-line
      className: PropTypes.string.isRequired,
      headerClassName: PropTypes.string
    })
  ).isRequired,
  getTdProps: PropTypes.func,
  addTemplate: PropTypes.func,
  defaultSorted: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      desc: PropTypes.bool
    })
  )
};

export default FlowTable;

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import capitalize from 'lodash/capitalize';

import { Button } from 'components';

const DeleteModal = ({ id, entity, closeModal, confirmModal }) => {
  const confirmModalHandler = useCallback(() => {
    try {
      confirmModal(id);
    } finally {
      closeModal();
    }
  }, [confirmModal, id, closeModal]);

  const deleteText = `Are you sure you want to ${entity.command ||
    'delete'} this ${entity.type}? This action is irreversible.`;

  return (
    <div className={classnames('modal-wrapper')}>
      <div>
        <p className="danger">{deleteText}</p>
        <p className="large-p">{entity.node}</p>
      </div>
      <div className="footer">
        <Button
          id="cancel-btn"
          type="button"
          onClick={closeModal}
          label="Cancel"
        />
        <Button
          id="delete-btn"
          type="submit"
          className="danger"
          label={capitalize(entity.command) || 'Delete'}
          onClick={confirmModalHandler}
        />
      </div>
    </div>
  );
};

DeleteModal.propTypes = {
  className: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  entity: PropTypes.shape({
    type: PropTypes.string,
    command: PropTypes.string,
    node: PropTypes.node
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
  confirmModal: PropTypes.func.isRequired
};

export default DeleteModal;

import React from 'react';

const WidgetWrapper = ({ children, title, name }) => {
  return (
    <div className="card widget-wrapper ">
      <h2 id="widget-wrapper-title">
        {name} {!!title ? `— ${title}` : null}
      </h2>
      <div>{children}</div>
    </div>
  );
};

export default WidgetWrapper;

import * as Yup from 'yup';

const TOO_SHORT_MESSAGE = 'Value is too short!';
const TOO_LONG_MESSAGE = 'Value is too long!';
const REQUIRED_MESSAGE = 'Please enter value!';

export const generateValidationSchema = definitions => {
  const validationObject = {};
  definitions.forEach(definition => {
    let yupDefinition = Yup.string();
    if (definition.min) {
      yupDefinition = yupDefinition.min(definition.min, TOO_SHORT_MESSAGE);
    }
    if (definition.max) {
      yupDefinition = yupDefinition.max(definition.max, TOO_LONG_MESSAGE);
    }
    if (definition.required) {
      yupDefinition = yupDefinition.required(REQUIRED_MESSAGE);
    }
    validationObject[definition.ID] = yupDefinition;
  });
  return Yup.object().shape(validationObject);
};

export const getInitialValues = (data = [], fields = []) => {
  const initialValues = {};

  data.forEach(({ value, ID }) => {
    initialValues[ID] = value;
  });

  // just in case not every field was saved
  const missingFields = fields.filter(({ ID }) => !data.some(d => d.ID === ID));

  if (missingFields.length > 0) {
    missingFields.forEach(({ ID, default: defaultValue }) => {
      initialValues[ID] = defaultValue || '';
    });
  }

  return initialValues;
};

import axios from 'axios';

const API_URL = window?.ENV?.API_URL;

export const fetchNodeDefinitions = data =>
  axios({
    baseURL: API_URL,
    method: 'GET',
    url: `/nodes`,
    data,
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true
  });

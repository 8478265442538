import { createSelector } from 'reselect';

export const integrationsDataSelector = createSelector(
  store => store.integrations,
  integrations => integrations.data
);

export const integrationByIdSelector = id =>
  createSelector(integrationsDataSelector, integrations => {
    const integration = integrations.find(integration => integration.ID === id);
    if (!integration) {
      return { values: [], title: '' };
    }

    return integration;
  });

export const integrationByTypeSelector = type =>
  createSelector(integrationsDataSelector, integrations => {
    return integrations.filter(integration => integration.type === type);
  });

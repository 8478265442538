import { createSelector } from 'reselect';
import get from 'lodash/get';

import { PERSISTENT_NOTIFICATIONS } from 'store/actions/persistentState';

export const persistentStateDataSelector = createSelector(
  store => store.persistentState.data,
  restarts => restarts
);

export const persistentNotificationsSelector = createSelector(
  persistentStateDataSelector,
  persistentState => get(persistentState, PERSISTENT_NOTIFICATIONS, [])
);

import * as actionTypes from './actionTypes';

export const batchEvaluateFlags = flagKeys => {
  return {
    type: actionTypes.BATCH_EVALUATE_FEATURE_FLAGS_REQUESTED,
    payload: { flagKeys }
  };
};

export const evaluateFlag = (flagKey, evaluationContext) => {
  return {
    type: actionTypes.EVALUATE_FEATURE_FLAG_REQUESTED,
    payload: { flagKey, evaluationContext }
  };
};

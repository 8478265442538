import React, { useCallback, useMemo } from 'react';

import { getFieldName } from 'utilities';
import { DEFINITIONS_BY_TYPE } from 'mocks/mockIntegrations';

import ToggleParameter from './ToggleParameter';
import FieldParameter from './FieldParameter';
import SliderParameter from './SliderParameter';
import DropdownParameter from './DropdownParameter';
import CoordinatesParameter from './CoordinatesParameter';

export const PARAMETER_TYPES = {
  boolean: 'boolean',
  string: 'string',
  stringSelection: 'stringSelection',
  valueRange: 'valueRange',
  seconds: 'seconds'
};

const NodeParameter = ({ parameter, validate, integrationType, ...props }) => {
  const isIntegrationField = useMemo(() => {
    const fieldDefinitions = integrationType
      ? DEFINITIONS_BY_TYPE[integrationType.toUpperCase()]
      : null;
    if (fieldDefinitions) {
      if (
        fieldDefinitions.find(
          definition => definition.ID === parameter.nodeTypeParameterID
        )
      ) {
        return true;
      }
    }
    return false;
  }, [integrationType, parameter.nodeTypeParameterID]);

  const handleValidate = useCallback(() => {
    return validate(
      parameter.required,
      props,
      getFieldName(
        parameter.type,
        parameter.ID,
        parameter.flowNodeID,
        parameter.nodeTypeParameterID
      )
    );
  }, [parameter, props, validate]);

  if (parameter.visibility == 'hidden') {
    return null;
  }

  if (parameter.variable === 'EE_TRACK_LINE') {
    return (
      <CoordinatesParameter
        parameter={parameter}
        setFieldValue={props.setFieldValue}
      />
    );
  }

  switch (parameter.type) {
    case PARAMETER_TYPES.valueRange:
      return <SliderParameter parameter={parameter} />;
    case PARAMETER_TYPES.stringSelection:
      return (
        <DropdownParameter
          parameter={parameter}
          handleValidate={handleValidate}
        />
      );
    case PARAMETER_TYPES.boolean:
      return <ToggleParameter parameter={parameter} />;
    case PARAMETER_TYPES.seconds:
      // Placeholder until component for time exists
      return <SliderParameter parameter={parameter} isTimeInput />;
    default:
      return (
        <FieldParameter
          parameter={parameter}
          handleValidate={handleValidate}
          isIntegrationField={isIntegrationField}
        />
      );
  }
};

export default NodeParameter;

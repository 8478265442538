import React from 'react';
import PropTypes from 'prop-types';

const AddModal = ({ entity, confirmModal }) => {
  return (
    <React.Fragment>
      {React.cloneElement(entity.node, { confirmModal })}
    </React.Fragment>
  );
};

AddModal.propTypes = {
  entity: PropTypes.shape({
    type: PropTypes.string,
    node: PropTypes.node
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
  confirmModal: PropTypes.func.isRequired
};

export default AddModal;

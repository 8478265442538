import React from 'react';
import PropTypes from 'prop-types';

import { Table } from 'components';

const ItemsTable = props => {
  let tableData = {};

  if (props.data) {
    tableData = props.data.map(dataItem => {
      return {
        key: dataItem.key,
        value: dataItem.value
      };
    });
  } else {
    // Empty table
    tableData = [{ key: 'None', value: 0 }];
  }

  const tableColumns = [
    {
      Header: <span className="text">Item</span>,
      accessor: 'key',
      className: 'text',
      headerClassName: 'text'
    },
    {
      Header: <span className="number">Number</span>,
      accessor: 'value',
      className: 'number',
      Cell: row => (
        <span className="tag">
          {Math.round((row.value + Number.EPSILON) * 100) / 100}
        </span>
      ),
      headerClassName: 'number'
    }
  ];

  return (
    <React.Fragment>
      {
        <Table
          id="detectedObjectsTable"
          data={tableData}
          columns={tableColumns}
          pageSize={tableData.length}
          pagination={false}
        />
      }
    </React.Fragment>
  );
};

ItemsTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  )
};

export default ItemsTable;

import * as actionTypes from 'store/actions/actionTypes';
import produce from 'immer';

import { PERSISTENT_INTEGRATIONS } from 'store/actions/persistentState';
import { PERSISTENT_STATE_KEY } from 'constants/storageKeys';

const initialState = {
  // TODO remove after REST API wire up
  data: getInitialIntegrations(),
  pending: false,
  error: null
};

const reducer = produce((draft, action) => {
  switch (action.type) {
    case actionTypes.CREATE_INTEGRATION_CREDENTIALS_REQUESTED:
      draft.pending = true;
      break;
    case actionTypes.CREATE_INTEGRATION_CREDENTIALS_SUCCEEDED:
      draft.data.push(action.payload);
      draft.pending = false;
      draft.error = null;
      break;
    case actionTypes.CREATE_INTEGRATION_CREDENTIALS_FAILED:
      draft.pending = false;
      draft.error = action.payload.message;
      break;

    case actionTypes.UPDATE_INTEGRATION_CREDENTIALS_REQUESTED:
      draft.pending = true;
      break;
    case actionTypes.UPDATE_INTEGRATION_CREDENTIALS_SUCCEEDED:
      const updatedCredentials = action.payload;
      const position = draft.data.findIndex(
        i => i.ID === updatedCredentials.ID
      );
      draft.data[position] = updatedCredentials;
      draft.error = null;
      draft.pending = false;
      break;
    case actionTypes.UPDATE_INTEGRATION_CREDENTIALS_FAILED:
      draft.error = action.payload.message;
      draft.pending = false;
      break;

    case actionTypes.DELETE_INTEGRATION_CREDENTIALS_REQUESTED:
      draft.pending = true;
      break;
    case actionTypes.DELETE_INTEGRATION_CREDENTIALS_SUCCEEDED:
      const id = action.payload;
      const updatedIntegrations = draft.data.filter(item => item.ID !== id);
      draft.data = updatedIntegrations;
      draft.pending = false;
      break;

    case actionTypes.DELETE_INTEGRATION_CREDENTIALS_FAILED:
      draft.error = action.payload.message;
      draft.pending = false;
      break;

    default:
      break;
  }
}, initialState);

// remove after REST API wire up
export function getInitialIntegrations() {
  try {
    const integrations = JSON.parse(localStorage.getItem(PERSISTENT_STATE_KEY))[
      PERSISTENT_INTEGRATIONS
    ];
    if (integrations.length > 0) {
      return integrations;
    } else {
      return [];
    }
  } catch (e) {
    return [];
  }
}

export default reducer;

import styled from 'styled-components';

import { ToggleSwitch as CommonToggleSwitch } from 'components';

const Container = styled.div`
  transition: var(--transition-all);
  box-shadow: 0 0 0 0.5px
    ${({ isToggled }) =>
      isToggled ? 'var(--color-orange-light)' : 'var(--color-grey)'};
  background-color: ${({ isToggled }) =>
    isToggled ? 'var(--color-orange-tint)' : 'var(--color-grey-lighter)'};
  border-radius: var(--s-xs);
  margin: 0 0 var(--s-b) 0;
  overflow: hidden;
  color: ${({ isToggled }) =>
    isToggled ? 'var(--color-orange)' : 'var(--color-grey-darker)'};
  transition: var(--transition-all);
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  & > *:last-child {
    margin: 0 var(--s-s) 0 0;
  }
`;

const ImageArea = styled.div`
  transition: var(--transition-all);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  background-color: ${({ isToggled }) =>
    isToggled ? 'var(--color-orange-light)' : 'var(--color-grey)'};
`;

const GraphName = styled.span`
  margin-left: var(--s-s);
`;

const ToggleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`;

const SwitchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ToggleSwitch = styled(CommonToggleSwitch)`
  margin: 0;
  display: flex;
`;

export {
  Container,
  Wrapper,
  ImageArea,
  GraphName,
  ToggleWrapper,
  ToggleSwitch,
  SwitchWrapper
};

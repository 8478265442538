import React, { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import { Field, Button } from 'components';

import {
  updateIntegrationCredentials,
  createIntegrationCredentials
} from 'store/actions/integrations';

import { generateValidationSchema, getInitialValues } from './utils';

import {
  DEFINITIONS_BY_TYPE,
  INTEGRATION_TITLE_DEFINITION
} from 'mocks/mockIntegrations';

import { integrationByIdSelector } from 'store/selectors';

import styles from './IntegrationPanel.module.scss'; // eslint-disable-line

const IntegrationPanel = ({ id, type, closePanel }) => {
  const integrationsSelector = useMemo(() => integrationByIdSelector(id), [id]);
  const { values: data, title } = useSelector(integrationsSelector);

  const dispatch = useDispatch();

  const newIntegration = useMemo(() => data.length === 0, [data]);

  const handleSubmit = useCallback(
    (title, data) => {
      try {
        const result = {
          ID: id,
          title,
          type,
          values: data
        };
        const action = newIntegration
          ? createIntegrationCredentials
          : updateIntegrationCredentials;

        dispatch(action(result));
      } finally {
        closePanel();
      }
    },
    [type, newIntegration, id, dispatch, closePanel]
  );

  const fields = useMemo(() => {
    const fieldDefinitions = DEFINITIONS_BY_TYPE[type];
    return fieldDefinitions
      ? [INTEGRATION_TITLE_DEFINITION, ...fieldDefinitions]
      : [];
  }, [type]);

  const validationSchema = useMemo(() => generateValidationSchema(fields), [
    fields
  ]);

  const initialValues = useMemo(
    () =>
      getInitialValues(
        [...data, { ID: INTEGRATION_TITLE_DEFINITION.ID, value: title }],
        fields
      ),
    [data, fields, title]
  );

  const onSubmit = useCallback(
    data => {
      const title = data[INTEGRATION_TITLE_DEFINITION.ID];
      delete data[INTEGRATION_TITLE_DEFINITION.ID];
      const mappedValues = Object.entries(data).map(([ID, value]) => ({
        ID,
        value
      }));
      handleSubmit(title, mappedValues);
    },
    [handleSubmit]
  );

  return (
    <div>
      <div className="right-panel-header">
        <h1>{newIntegration ? 'Create an integration' : 'Edit integration'}</h1>
        <Button className="icon" onClick={closePanel} icon={['fal', 'times']} />
      </div>
      <div className="form">
        <Formik
          enableReinitialize
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          <Form>
            {fields.map(({ name, ID, type, description }) => (
              <Field
                label={name}
                name={ID}
                type={type}
                key={ID}
                description={description}
              />
            ))}
            <div>
              <div className="options-bar">
                <Button
                  id="cancel-btn"
                  type="button"
                  onClick={closePanel}
                  label="Cancel"
                />
                <Button
                  id="save-integration-btn"
                  type="submit"
                  className="primary"
                  label="Save"
                />
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default IntegrationPanel;

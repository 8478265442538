import * as actionTypes from 'store/actions/actionTypes';
import produce from 'immer';

const initialState = {
  modalType: null,
  modalProps: {}
};

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case actionTypes.SHOW_MODAL: {
      draft.modalProps = action.modalProps;
      draft.modalType = action.modalType;
      break;
    }

    case actionTypes.HIDE_MODAL: {
      return initialState;
    }

    default:
      break;
  }
}, initialState);

export default reducer;

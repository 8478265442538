import isEqual from 'lodash/isEqual';
import valuesIn from 'lodash/valuesIn';

export const FILTERS_MAX_LENGTH = 2;

export const ALL_FILTERS = {
  NODES: { key: 'allNodes', value: 'All nodes' },
  TYPES: { key: 'allTypes', value: 'All types' }
};

export const FILTER_KEYS = {
  DATA_NODE: 'data_node',
  TYPE: 'type',
  SEARCH: 'search'
};

export function applyTagFilters({ dataSources, tagFilters }) {
  if (!dataSources || dataSources.length === 0) {
    return [];
  }
  if (!tagFilters || tagFilters.length === 0) {
    return dataSources;
  }
  const tagFiltersMapped = tagFilters.map(f => {
    const [name, value] = f.key.split('.');
    return { name, value };
  });

  if (!tagFiltersMapped.length) return dataSources;

  return dataSources.filter(dataSource => {
    const { tags } = dataSource;

    return tagFiltersMapped.every(({ name, value }) => tags[name] === value);
  });
}

export function applyFilters(dataSources, filters) {
  // check if any of the filters below shouldn't be applied
  const isNodeFilterEmpty =
    !filters[FILTER_KEYS.DATA_NODE] ||
    filters[FILTER_KEYS.DATA_NODE].key === ALL_FILTERS.NODES.key;

  const isTypeFilterEmpty =
    !filters[FILTER_KEYS.TYPE] ||
    filters[FILTER_KEYS.TYPE].key === ALL_FILTERS.TYPES.key;

  const isTypeSearchEmpty =
    !filters[FILTER_KEYS.SEARCH] || filters[FILTER_KEYS.SEARCH].length < 1;

  // if all empty, no sense to filter

  if (isNodeFilterEmpty && isTypeFilterEmpty && isTypeSearchEmpty)
    return dataSources;

  // check value in the input

  const DATA_NODE_ID = filters[FILTER_KEYS.DATA_NODE]?.key;
  const DATA_TYPE_ID = filters[FILTER_KEYS.TYPE]?.key;
  const NAME_PATTERN = filters[FILTER_KEYS.SEARCH];

  return dataSources.filter(dataSource => {
    // assume everything is valid before all checks
    let validNodeId = true;
    let validTypeId = true;
    let validName = true;

    // perform checks where input is not empty

    if (DATA_NODE_ID && !isNodeFilterEmpty) {
      validNodeId = dataSource.dataNodeID === DATA_NODE_ID;
    }
    if (DATA_TYPE_ID && !isTypeFilterEmpty) {
      validTypeId = dataSource.dataTypeID === DATA_TYPE_ID;
    }

    if (NAME_PATTERN && !isTypeSearchEmpty) {
      validName = dataSource.name
        .toLowerCase()
        .includes(NAME_PATTERN.toLowerCase());
    }

    return validNodeId && validTypeId && validName;
  });
}

export function composeTags(dataSources) {
  const outputTags = [];

  dataSources.forEach(({ tags }) => {
    Object.entries(tags).forEach(([tagName, tagValue]) => {
      const tag = {
        key: `${tagName}.${tagValue}`,
        name: tagName,
        value: tagValue
      };
      if (!outputTags.find(t => isEqual(t, tag))) {
        outputTags.push(tag);
      }
    });
  });

  return outputTags;
}

export function getDataTypeNames(dataTypes, handleTabClick) {
  const allDataSources = { title: 'all', onClick: handleTabClick };
  const dataTypesNames = Object.values(dataTypes)
    .map(type => {
      if (type.name.includes('unspecified')) {
        return { title: null };
      }
      return { title: type.name, onClick: handleTabClick };
    })
    .filter(function(item) {
      return item.title != null;
    });

  dataTypesNames.unshift(allDataSources);
  return dataTypesNames;
}

export function getDropdownDataTypes(dataTypes) {
  return valuesIn(dataTypes)
    .filter(type => !type.name.includes('unspecified'))
    .map(dataType => ({
      key: dataType.ID,
      value: dataType.name.toUpperCase()
    }))
    .concat([ALL_FILTERS.TYPES]);
}

export function getDropdownDataNodes(dataNodes) {
  return dataNodes
    .map(dataNode => ({
      key: dataNode.ID,
      value: dataNode.name
    }))
    .concat(ALL_FILTERS.NODES);
}

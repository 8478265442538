import axios from 'axios';

const API_URL = window?.ENV?.API_URL;

const KPI_URL = '/kpi';

export const getFlowKpi = id =>
  axios({
    baseURL: API_URL,
    method: 'GET',
    url: `${KPI_URL}/${id}`,
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true
  });

import axios from 'axios';

const API_URL = window?.ENV?.API_URL;

const DEPLOYMENT_URL = '/deployments';

export const deployFlow = id =>
  axios({
    baseURL: API_URL,
    method: 'POST',
    url: `${DEPLOYMENT_URL}/${id}/deploy`,
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true
  });

export const destroyFlow = id =>
  axios({
    baseURL: API_URL,
    method: 'DELETE',
    url: `${DEPLOYMENT_URL}/${id}/destroy`,
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true
  });

export const redeployNode = ({ flowId, nodeId }) =>
  axios({
    baseURL: API_URL,
    method: 'PUT',
    url: `${DEPLOYMENT_URL}/${flowId}/replace/${nodeId}`,
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true
  });

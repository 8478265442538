import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { CREATE_MODAL } from 'store/actions/actionTypes';
import { deleteIntegrationCredentials } from 'store/actions/integrations';

import {
  ICONS_BY_TYPE,
  DEFINITIONS_BY_TYPE,
  TYPE_LABELS_BY_INTEGRATION_TYPE
} from 'mocks/mockIntegrations';

import Credentials from './Credentials';

const IntegrationCredentials = ({
  credentials,
  handleEdit,
  title,
  type,
  values,
  updated,
  ID
}) => {
  const IconComponent = ICONS_BY_TYPE[type] ? (
    ICONS_BY_TYPE[type]
  ) : (
    <React.Fragment />
  );

  const dispatch = useDispatch();

  const handleDeleteIntegration = useCallback(
    id => {
      dispatch({
        type: CREATE_MODAL,
        meta: {
          type: 'delete',
          entity: 'integration',
          data: {},
          onConfirm: () => dispatch(deleteIntegrationCredentials(id))
        }
      });
    },
    [dispatch]
  );

  const fieldDefinitions = DEFINITIONS_BY_TYPE[type];

  const reference = { id: ID, type };

  const complexValues = values.map(value => {
    const fieldDefinition = fieldDefinitions.find(d => d.ID === value.ID);
    return Object.assign({}, value, {
      description: fieldDefinition?.description,
      name: fieldDefinition?.name
    });
  });

  return (
    <Credentials
      credentials={credentials}
      handleEdit={handleEdit}
      title={title}
      values={complexValues}
      updated={updated}
      icon={IconComponent}
      type={TYPE_LABELS_BY_INTEGRATION_TYPE[type]}
      id={ID}
      reference={reference}
      handleDelete={handleDeleteIntegration}
    />
  );
};

IntegrationCredentials.propTypes = {
  credentials: PropTypes.arrayOf([PropTypes.object]),
  handleEdit: PropTypes.func
};

export default IntegrationCredentials;

import { isEmpty } from 'lodash';

export const COORDINATES_MAX_LENGTH = 6;

export const validateCoordinates = value => {
  const isValueString = typeof value === 'string';
  const currentCoordinates = isValueString ? value.split(',') : value;
  const latitude = Number(currentCoordinates[0]);
  const longitude = Number(currentCoordinates[1]);
  const isLatitude = number => isFinite(number) && Math.abs(number) <= 90;
  const isLongitude = number => isFinite(number) && Math.abs(number) <= 180;

  let error;
  if (isEmpty(value)) return;

  if (!isLatitude(latitude || !latitude)) {
    error = 'Latitude is not valid. It must be a value between -90 and 90.';
  }
  if (!isLongitude(longitude || !longitude)) {
    error = 'Longitude is not valid. It must be a value between -180 and 180.';
  }
  if (isValueString && !value.includes(',')) {
    error = 'Please add a comma.';
  }
  return error;
};

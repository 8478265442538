import React, { useCallback, useState, useRef } from 'react';
import { Button } from 'components';
import styles from './CsvImport.module.scss';

const CSV_TEMPLATE_PATH = '/assets/datasources_import_template.csv';

const CsvImportModal = ({ closeModal, confirmModal }) => {
  const inputEl = useRef(null);
  const [file, setFile] = useState({});

  const handleChange = useCallback(
    event => {
      if (event.currentTarget.files[0]) {
        setFile(event.currentTarget.files[0]);
      }
    },
    [setFile]
  );

  const handleConfirm = useCallback(
    event => {
      event.preventDefault();
      if (file?.name) {
        confirmModal(file);
        closeModal();
      }
    },
    [confirmModal, closeModal, file]
  );

  const handleDownloadTemplate = useCallback(e => {
    e.preventDefault();
    window.location.assign(CSV_TEMPLATE_PATH);
  }, []);
  const handleSelectFile = useCallback(() => {
    if (inputEl) {
      inputEl.current.click();
    }
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.title}>Select CSV file and upload</div>
      <div className={styles.fakeFileInputContainer} onClick={handleSelectFile}>
        <input
          ref={inputEl}
          id="file-input"
          name="file"
          type="file"
          accept=".csv"
          onChange={handleChange}
          className={styles.fileInput}
        />
        <div className={styles.fakeFileIndicator}>
          {file?.name ?? 'Select file'}
        </div>
        <Button
          id="upload-btn"
          type="button"
          className="primary"
          label="Upload"
        />
      </div>
      <div className={styles.importingDestination}>
        Imported data sources would be added to: <b>All data sources</b>
        <p>
          <a onClick={handleDownloadTemplate} href={CSV_TEMPLATE_PATH}>
            You can download csv template here.
          </a>
        </p>
      </div>
      <div className={styles.footer}>
        <Button
          id="cancel-btn"
          type="button"
          onClick={closeModal}
          label="Cancel"
        />
        <Button
          id="save-import-btn"
          type="submit"
          onClick={handleConfirm}
          className="primary"
          label="Save"
          disabled={!file?.name}
        />
      </div>
    </div>
  );
};

export default CsvImportModal;

export function extractItemsData(arr) {
  const keys = {};
  arr.forEach(time => {
    const timeKey = Object.keys(time)[0];
    time[timeKey].forEach(item => {
      keys[item.key] = [];
    });
  });
  const uniqueKeys = Object.keys(keys);
  arr.forEach(timeObj => {
    const timeKey = Object.keys(timeObj)[0];
    uniqueKeys.forEach(key => {
      const matchingValue = timeObj[timeKey].find(obj => obj.key === key);
      keys[key] = keys[key].concat({
        y: matchingValue ? matchingValue.value : 0,
        x: +Date.parse(timeKey),
        label: key
      });
    });
  });
  return Object.values(keys);
}

const limitByDatapointAmount = (datapoints, amount) => {
  return datapoints.length > amount
    ? datapoints.slice(datapoints.length - amount, datapoints.length)
    : datapoints;
};

export const limitDatapointBufferSize = (
  datapoints,
  datapointsLowerLimit,
  timewindowLowerLimit
) => {
  // Limit buffer size by datapoint amount only if time window not configured
  if (timewindowLowerLimit === 0) {
    return limitByDatapointAmount(datapoints, datapointsLowerLimit);
  }

  // Limit by combined datapoint amount and time window length
  const thresholdTimestamp = Date.now() - timewindowLowerLimit * 1000;

  const firstDatapointIndexToKeep = datapoints.findIndex(
    datapoint => datapoint.x >= thresholdTimestamp
  );

  // Return all datapoints until received data spans across the whole time window
  if (firstDatapointIndexToKeep <= 0) {
    return limitByDatapointAmount(datapoints, datapointsLowerLimit);
  }

  // Select limiting factor when both apply
  return datapointsLowerLimit > datapoints.length - firstDatapointIndexToKeep
    ? limitByDatapointAmount(datapoints, datapointsLowerLimit)
    : datapoints.slice(firstDatapointIndexToKeep);
};

import React from 'react';
import { connect } from 'react-redux';

import { Panels } from 'constants';
import { closeRightPanel } from 'store/actions/panels';

import NotificationContainer from './Panels/NotificationContainer/NotificationContainer';
import AddConfiguration from './Panels/AddConfiguration/AddConfiguration';
import AddFlow from './Panels/AddFlow/AddFlow';
import IntegrationPanel from './Panels/IntegrationPanel';
import InviteUsersPanel from './Panels/InviteUsersPanel';
import DataSourcesPanel from './Panels/DataSourcesPanel';

import './RightPanel.scss';

class RightPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rightPanelClass: ''
    };
  }

  componentDidMount() {
    let panelClass = 'right-panel';
    if (this.props.panels.rightPanel === Panels.CONFIGURATION_CREATOR_PANEL) {
      panelClass = 'right-panel flow-configuration';
    }
    if (this.props.panels.rightPanel === Panels.NOTIFICATIONS_PANEL) {
      panelClass = 'notification-panel';
    }
    this.setState({
      rightPanelClass: panelClass
    });
  }

  closePanel(name) {
    return () => this.props.dispatch(closeRightPanel(name));
  }

  render() {
    let panel;
    switch (this.props.panels.rightPanel) {
      case Panels.FLOW_PANEL:
        panel = this.props.panels.properties ? (
          <AddFlow
            flowId={this.props.panels.properties.flowId}
            flowName={this.props.panels.properties.name}
            flowDescription={this.props.panels.properties.description}
          />
        ) : (
          <AddFlow />
        );
        break;
      case Panels.CONFIGURATION_CREATOR_PANEL:
        panel = (
          <AddConfiguration nodeId={this.props.panels.properties.nodeId} />
        );
        break;
      case Panels.NOTIFICATIONS_PANEL:
        panel = <NotificationContainer />;
        break;
      case Panels.INTEGRATION_PANEL:
        panel = (
          <IntegrationPanel
            id={this.props.panels.properties?.id}
            type={this.props.panels.properties?.type}
            closePanel={this.closePanel(Panels.INTEGRATION_PANEL)}
          />
        );
        break;
      case Panels.INVITE_USERS_PANEL:
        panel = (
          <InviteUsersPanel
            closePanel={this.closePanel(Panels.INVITE_USERS_PANEL)}
          />
        );
        break;
      case Panels.DATA_SOURCES_PANEL:
        panel = (
          <DataSourcesPanel
            dataSource={this.props.panels.properties}
            closePanel={this.closePanel(Panels.DATA_SOURCES_PANEL)}
          />
        );
        break;
      default:
        panel = <div>Invalid Panel</div>;
    }

    return <div className={this.state.rightPanelClass}>{panel}</div>;
  }
}

const mapStateToProps = state => ({
  panels: state.panels,
  persistentState: state.persistentState.data
});

export default connect(mapStateToProps)(RightPanel);

import * as actionTypes from 'store/actions/actionTypes';

const initialState = {
  combined: null,
  nodeMetrics: null,
  restarts: null,
  error: null
};

export const resetKpi = () => ({
  ...initialState
});

export const getFlowKpiSuccess = (state, action) => {
  return {
    ...state,
    combined: action.payload.combined,
    nodeMetrics: action.payload.nodeMetrics,
    restarts: action.payload.restarts,
    error: null
  };
};

export const getFlowKpiError = (state, action) => {
  return {
    ...state,
    error: action.payload
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_FLOW_KPI_SUCCEEDED:
      return getFlowKpiSuccess(state, action);
    case actionTypes.GET_FLOW_KPI_FAILED:
      return getFlowKpiError(state, action);
    case actionTypes.RESET_KPI:
      return resetKpi(state, action);
    default:
      return state;
  }
};

export default reducer;

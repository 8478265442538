import * as actionTypes from 'store/actions/actionTypes';

export const updateIntegrationCredentials = data => ({
  type: actionTypes.UPDATE_INTEGRATION_CREDENTIALS_REQUESTED,
  payload: { data }
});

export const createIntegrationCredentials = data => ({
  type: actionTypes.CREATE_INTEGRATION_CREDENTIALS_REQUESTED,
  payload: { data }
});

export const deleteIntegrationCredentials = data => ({
  type: actionTypes.DELETE_INTEGRATION_CREDENTIALS_REQUESTED,
  payload: data
});

import React from 'react';

import { Button, Input, InfoIcon } from 'components';

import styles from '../IntegrationCredentials.module.scss';

import { format } from 'date-fns';

const Credentials = ({
  icon: Icon,
  title,
  updated,
  reference,
  values,
  type,
  handleEdit,
  handleDelete
}) => (
  <div className={styles['integration-credentials-container']}>
    <div className={styles['integration-credentials-header']}>
      <div className={styles['integration-credentials-icon-wrapper']}>
        <Icon />
      </div>
      <div className={styles['text-info-wrapper']}>
        <span className="tag green">{type}</span>
        <h2 className={styles['integration-credentials-name']}>{title}</h2>
        <span>Updated — {format(updated, 'DD.MM.YYYY')}</span>
      </div>
      <div className={styles['integration-credentials-actions']}>
        <Button
          label="Configure"
          className="outlined"
          data-testid="update"
          onClick={() => handleEdit(reference)}
        />
        <Button
          label="Delete"
          className="danger"
          data-testid="delete"
          onClick={() => handleDelete(reference.id)}
        />
      </div>
    </div>
    <div className={styles['integration-credentials-wrapper']}>
      {values.map(field => (
        <div key={field.ID} className={styles['integration-credentials-input']}>
          <div className={styles['integration-credentials-input-label']}>
            <label>{field.name}</label>
            {field.description && (
              <InfoIcon className={styles['info-icon']}>
                {field.description}
              </InfoIcon>
            )}
          </div>
          <Input
            type="password"
            disabled
            value={Array(field.value.length)
              .fill('x')
              .join()}
          />
        </div>
      ))}
    </div>
  </div>
);

export default Credentials;

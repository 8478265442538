import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';

import { connectRouter, routerMiddleware } from 'connected-react-router';

import rootSaga from './sagas';

import authReducer from './reducers/auth';
import flowEditorReducer from './reducers/flowEditor';
import flowsReducer from './reducers/flows';
import usersReducer from './reducers/users';
import modalReducer from './reducers/modal';
import panelReducer from './reducers/panels';
import kpiReducer from './reducers/kpis';
import persistentStateReducer from './reducers/persistentState';
import notificationsReducer from './reducers/notifications';
import sourcesReducer from './reducers/sources';
import integrationsReducer from './reducers/integrations';
import featureFlagsReducer from './reducers/featureFlags';

import modalMiddleware from './middleware/modal';
import loadersReducer from './reducers/loaders';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();

export const history = createBrowserHistory();

const generateRootReducer = history =>
  combineReducers({
    auth: authReducer,
    flowEditor: flowEditorReducer,
    flows: flowsReducer,
    users: usersReducer,
    modal: modalReducer,
    panels: panelReducer,
    kpis: kpiReducer,
    persistentState: persistentStateReducer,
    notifications: notificationsReducer,
    sources: sourcesReducer,
    integrations: integrationsReducer,
    loaders: loadersReducer,
    featureFlags: featureFlagsReducer,
    router: connectRouter(history)
  });

const generateMiddleware = history => [routerMiddleware(history)];

const staticMiddleware = [modalMiddleware, sagaMiddleware];

if (process.env.NODE_ENV === `development`) {
  const { logger } = require(`redux-logger`);

  staticMiddleware.push(logger);
}

export const generateStore = history => {
  const store = createStore(
    generateRootReducer(history),
    composeEnhancers(
      applyMiddleware(...generateMiddleware(history), ...staticMiddleware)
    )
  );
  // then run the saga
  sagaMiddleware.run(rootSaga);
  return store;
};

import { select, put } from 'redux-saga/effects';
import pushGtagEvent from 'ga-gtag';

import {
  gtagFeatureFlagSelector,
  isPanelVisibleSelector
} from 'store/selectors';

import { closeRightPanel as closeRightPanelActionCreator } from 'store/actions/panels';

function* sendLocationAnalyticsEvent(action) {
  const { isFirstRendering, location } = action.payload;

  const gtagFeatureFlag = yield select(gtagFeatureFlagSelector);

  const gtag = gtagFeatureFlag?.variantKey;

  if (!isFirstRendering && gtag && gtag !== 'false') {
    try {
      yield pushGtagEvent('config', gtag, {
        page_path: location?.pathname
      });
    } catch {
      console.warn('GTAG configured incorrectly!');
    }
  }
}

function* closeRightPanel(action) {
  const { isFirstRendering } = action.payload;

  const isPanelVisible = yield select(isPanelVisibleSelector);

  if (!isFirstRendering && isPanelVisible) {
    yield put(closeRightPanelActionCreator());
  }
}

export { sendLocationAnalyticsEvent, closeRightPanel };

import React, { useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Notification, NoData, Button } from 'components';
import { useClickAway } from 'react-use';
import {
  clearPersistentState,
  PERSISTENT_NOTIFICATIONS
} from 'store/actions/persistentState';

import { persistentNotificationsSelector } from 'store/selectors';
import { closeRightPanel } from 'store/actions/panels';

import styles from './NotificationContainer.module.scss';

const NotificationContainer = () => {
  const dispatch = useDispatch();

  const handleClearNotifications = useCallback(() => {
    dispatch(clearPersistentState(PERSISTENT_NOTIFICATIONS));
    dispatch(closeRightPanel());
  }, [dispatch]);

  const notifications = useSelector(persistentNotificationsSelector);

  const sortedNotifications = useMemo(
    () =>
      notifications.sort((a, b) => new Date(b.created) - new Date(a.created)),
    [notifications]
  );

  const ref = useRef(null);

  useClickAway(
    ref,
    () => {
      dispatch(closeRightPanel());
    },
    ['click']
  );

  return (
    <div ref={ref}>
      <div className={styles['notifications-header-container']}>
        <span className={styles['header']}>Recent notifications</span>
        <Button
          className={styles['clear-btn']}
          type="button"
          onClick={handleClearNotifications}
          icon={['fas', 'times-circle']}
          label="Clear"
        />
      </div>
      <div className={styles['notifications-wrapper']}>
        {sortedNotifications.length > 0 ? (
          sortedNotifications.map(notification => (
            <Notification
              key={notification.created}
              notification={notification}
            />
          ))
        ) : (
          <NoData />
        )}
      </div>
    </div>
  );
};

export default NotificationContainer;

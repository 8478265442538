import axios from 'axios';

const API_URL = window?.ENV?.API_URL;

const FLOWS_URL = '/flows';

export const createFlow = data =>
  axios({
    baseURL: API_URL,
    method: 'POST',
    url: `${FLOWS_URL}`,
    data,
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true
  });

export const updateFlow = data =>
  axios({
    baseURL: API_URL,
    method: 'PATCH',
    url: `${FLOWS_URL}/${data.flowID} `,
    data,
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true
  });

export const deleteFlow = id =>
  axios({
    baseURL: API_URL,
    method: 'DELETE',
    url: `${FLOWS_URL}/${id} `,
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true
  });

export const cloneFlow = ({ flowId, name, description }) =>
  axios({
    baseURL: API_URL,
    method: 'POST',
    url: `/flows/${flowId}/copy`,
    data: {
      name,
      description
    },
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true
  });

export const updateFlowNodes = data =>
  axios({
    baseURL: API_URL,
    method: 'POST',
    url: `${FLOWS_URL}/${data.flowID}/flow `,
    data,
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true
  });

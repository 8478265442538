import * as actionTypes from 'store/actions/actionTypes';

const initialState = {
  notifications: []
};

export const addNotification = (state, action) => {
  const notifications = [...state.notifications, action.notification];

  return {
    ...state,
    notifications
  };
};

export const clearNotifications = state => {
  return {
    ...state,
    notifications: []
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_NOTIFICATION:
      return addNotification(state, action);
    case actionTypes.CLEAR_NOTIFICATIONS:
      return clearNotifications(state, action);
    default:
      return state;
  }
};

export default reducer;

import React, { useMemo } from 'react';
import { RadialChart } from 'react-vis';

import { WidgetWrapper } from 'components';
import { chartColors } from 'utilities/transform';

import styles from './PieChart.module.scss';

const PieChart = ({ title, name, data, innerRadius, padAngle, radius }) => {
  const items = useMemo(() => data?.items ?? [], [data]);

  const shouldShow = useMemo(() => items.length > 0, [items]);

  const total = items.reduce((acc, curr) => {
    return acc + curr?.value;
  }, 0);

  const pieChartData = items.map((item, index) => ({
    angle: item?.value,
    label: item?.key,
    color: chartColors[Math.floor(index % items.length)]
  }));

  const fakeData = useMemo(() => {
    if (pieChartData.length === 0) {
      return [
        {
          angle: 100,
          label: '',
          color: 'var(--color-grey)'
        }
      ];
    }
  }, [pieChartData.length]);

  return (
    <WidgetWrapper
      title={title}
      name={name}
      style={{ flexGrow: shouldShow ? 2 : 0 }}
    >
      <div className={styles.container}>
        <RadialChart
          colorType="literal"
          stroke={'white'}
          data={shouldShow ? pieChartData : fakeData}
          width={300}
          height={300}
          innerRadius={innerRadius}
          padAngle={padAngle}
          radius={radius}
        />
        {shouldShow ? (
          <div className={styles.keyStats}>
            <div className="label">Key stats & legend</div>
            <div className={styles.keyStatItems}>
              {pieChartData.map(item => (
                <div className={styles.keyStatItem} key={item.label}>
                  <div className={styles.keyStatPercentage}>
                    {`${((item.angle / total) * 100).toFixed(0)}%`}
                  </div>
                  <div className={styles.keyStatLabel}>{item.label}</div>
                  <div
                    className={styles.keyStatBar}
                    style={{ backgroundColor: item.color }}
                  />
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </WidgetWrapper>
  );
};

export default PieChart;

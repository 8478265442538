/* eslint react/jsx-no-bind: [2, {
    "allowArrowFunctions": true
}] */
import React, { useCallback } from 'react';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';

import { Field, Button, ToggleSwitch } from 'components';
import { getUserValidationSchema } from 'schema/schema';

const EditUserForm = ({
  id,
  isUpdating,
  isEditing = false,
  user = {},
  handleCancel,
  confirmModal,
  shouldShowAdminSwitch,
  isToggleDisabled
}) => {
  const initialValues = {
    email: isEditing ? user.email : '',
    fullName: isEditing ? user.fullName : '',
    isAdmin: shouldShowAdminSwitch
      ? undefined
      : isEditing
      ? user.isAdmin
      : false
  };

  const submitFormHandler = useCallback(
    values => {
      confirmModal(values, id);
    },
    [id, confirmModal]
  );

  return (
    <div className="user-wrapper">
      <div id="user-form" className="form">
        <Formik
          enableReinitialize
          validationSchema={getUserValidationSchema(isEditing)}
          initialValues={initialValues}
          onSubmit={submitFormHandler}
        >
          <Form>
            <Field
              name="email"
              type="email"
              label="Email"
              placeholder={'user@email.com'}
            />
            <Field
              name="fullName"
              type="string"
              label="Full name"
              placeholder={'Your full name'}
            />
            {!shouldShowAdminSwitch ? (
              <ToggleSwitch
                name="isAdmin"
                label="User is admin"
                disabled={!shouldShowAdminSwitch && isToggleDisabled}
              />
            ) : null}
            <div className="footer">
              <Button
                id="cancel-btn"
                type="button"
                onClick={handleCancel}
                label="Cancel"
                disabled={isUpdating}
              />
              <Button
                id="save-user-btn"
                type="submit"
                className="primary"
                label="Save"
                disabled={isUpdating}
              />
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

EditUserForm.propTypes = {
  id: PropTypes.string,
  isUpdating: PropTypes.bool,
  isEditing: PropTypes.bool,
  isToggleDisabled: PropTypes.bool,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

export default EditUserForm;

import axios from 'axios';
import { ENDPOINT_AUTH_CHECK } from 'constants';
import get from 'lodash/get';

export default authCheck => {
  axios.interceptors.response.use(
    response => response,
    error => {
      const isAuthCheckUrl = get(error, 'config.url') === ENDPOINT_AUTH_CHECK;
      if (error.response.status === 401 && !isAuthCheckUrl) {
        authCheck();
      }
      return Promise.reject(error);
    }
  );
};

import React from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Field } from 'formik';

import './ToggleSwitch.scss';

const ToggleSwitch = ({
  className,
  label,
  name,
  disabled,
  checked,
  onClick
}) => {
  const classes = classNames(className || 'field-container');

  return (
    <div className={classes}>
      <label>{label}</label>
      <Field
        type="checkbox"
        name={name}
        component={Checkbox}
        checked={checked}
        onClick={onClick}
      />
      <label
        style={disabled ? { opacity: '0.5', pointerEvents: 'none' } : {}}
        className="react-switch-label"
        htmlFor={`react-switch-new-${name}`}
      >
        <span className={`react-switch-button`} />
      </label>
    </div>
  );
};

function Checkbox({ field, type, checked, onClick }) {
  return (
    <input
      type={type}
      className="react-switch-checkbox"
      id={`react-switch-new-${field.name}`}
      checked={checked}
      onClick={onClick}
      {...field}
    />
  );
}

ToggleSwitch.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  isActive: PropTypes.bool,
  onToggle: PropTypes.func
};

export default ToggleSwitch;

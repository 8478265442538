import React from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../Icon/Icon';
import './Button.scss';

const Button = ({ isLoading = false, ...props }) => {
  const classes = classNames([props.className, 'btn']);
  const icon = props.icon ? <Icon name={props.icon} /> : null;
  const label = props?.label ? <span>{props.label}</span> : null;

  if (isLoading) {
    return (
      <button
        id={props.id}
        name={props.name}
        {...props}
        className={classNames(classes, 'loading')}
      >
        <span className="hidden-text">{label}</span>
        <Icon className="loading-icon" name={['far', 'spinner']} spin />
      </button>
    );
  }

  return (
    <button id={props.id} name={props.name} {...props} className={classes}>
      {icon}
      {label || props.children}
    </button>
  );
};

Button.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

export default Button;

import React from 'react';

import { WidgetWrapper } from 'components';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

const generateIcon = (angle, color) => {
  return L.divIcon({
    html: `
      <div class="marker-wrapper" style="transform: rotate(${angle}deg);">
        <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
          <path d="m14.2426 14.2426c-2.3431 2.3431-6.1421 2.3431-8.48524 0-2.34315-2.3432-2.34315-6.14218 0-8.48532l4.24264-4.24264 4.2426 4.24264c2.3432 2.34314 2.3432 6.14212 0 8.48532z" fill="var(--color-${color}-light)" stroke="var(--color-${color})" stroke-width="2"/>
        </svg>
      </div>`,
    className: 'directed-map-icon'
  });
};

const HslDisplayView = ({ data, title }) => {
  const MAP_URL = window.ENV.MAP_URL;
  const MAP_CENTER = [60.170187, 24.940227];
  const items = data?.items ?? [];

  return (
    <WidgetWrapper name="HSL Display" title={title}>
      <MapContainer
        className="map-container"
        center={MAP_CENTER}
        zoom={14}
        maxZoom={20}
        style={{ width: '100%', height: '600px' }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> contributors'
          url={MAP_URL}
        />
        {items.map(item => {
          /** From HSL's High-frequency positioning API
           * desi: vehicle route
           * veh: unique vehicle number
           * lat and long: vehicle coordinates
           * dl: vehicle lateness in seconds, negative value means late
           */
          const { desi, veh, lat, long, dl } = item.value;
          return (
            <Marker
              key={`${desi}+${veh}`}
              position={[lat, long]}
              icon={generateIcon(item.value.hdg, item.value.lateness)}
            >
              <Popup>
                <>
                  <div>Route {desi}</div>
                  <div>Vehicle number {veh}</div>
                  {dl < 0 ? <div>{`Late ${-dl} seconds`}</div> : null}
                </>
              </Popup>
            </Marker>
          );
        })}
      </MapContainer>
    </WidgetWrapper>
  );
};

export default HslDisplayView;

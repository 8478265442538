import React, { useMemo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import classnames from 'classnames';
import { replace } from 'lodash';
import { MapContainer, TileLayer, Marker, useMapEvent } from 'react-leaflet';

import { Button, Field, TagInput } from 'components';
import { EDIT_DATA_SOURCE_SCHEMA } from 'schema/schema';
import { FormDropdown } from 'components/UI/Dropdown/Dropdown';
import { closeRightPanel } from 'store/actions/panels';
import { updateDataSource } from 'store/actions/sources';
import { dataNodesSelector } from 'store/selectors';
import { validateCoordinates, COORDINATES_MAX_LENGTH } from './utils';

import styles from './DataSourcesPanel.module.scss';

const MAP_URL = window.ENV.MAP_URL;

const mapMarkerIcon = new L.divIcon({
  className: 'green-map-icon',
  iconSize: [54, 54],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40]
});

const DataSourcesPanel = ({ dataSource }) => {
  const dispatch = useDispatch();

  const dataNodes = useSelector(dataNodesSelector);

  const dropdownDataNodes = dataNodes
    .filter(node => node.dataTypeID === dataSource.dataTypeID)
    .map(node => node.name);

  const selectedDataNode = useMemo(
    () =>
      dataSource.dataNodeID
        ? dataNodes.find(node => node.ID === dataSource.dataNodeID).name
        : null,
    [dataSource, dataNodes]
  );

  const tags = useMemo(() => Object.values(dataSource.tags), [dataSource.tags]);

  const [map, setMap] = useState(null);
  const [dataSourceCoordinates, setDataSourceCoordinates] = useState(
    dataSource.properties.latitude && dataSource.properties.longitude
      ? [dataSource.properties.latitude, dataSource.properties.longitude]
      : []
  );

  const position = useMemo(
    () =>
      dataSourceCoordinates.length > 0
        ? dataSourceCoordinates
        : [60.170187, 24.940227],
    [dataSourceCoordinates]
  );

  const handleClick = useCallback(
    values => {
      const coordinates = values.coordinates;
      const newCoordinates = replace(coordinates, ' ', '').split(',');

      if (coordinates && coordinates !== dataSourceCoordinates) {
        setDataSourceCoordinates(newCoordinates);
        map.flyTo(newCoordinates);
      }
    },
    [map, dataSourceCoordinates]
  );

  const handleSubmit = useCallback(
    submitData => {
      try {
        dispatch(updateDataSource(dataSource.ID, submitData));
      } finally {
        closePanel();
      }
    },
    [closePanel, dataSource.ID, dispatch]
  );

  const closePanel = useCallback(() => {
    dispatch(closeRightPanel());
  }, [dispatch]);

  const onSubmit = useCallback(
    data => {
      const nodeId = data.node
        ? dataNodes.find(node => node.name === data.node).ID
        : null;
      const submitTags = data.tags.map(tag => [tag, tag]);
      const submitCoordinates =
        typeof data.coordinates === 'string'
          ? data.coordinates.split(',')
          : data.coordinates;

      const submitData = {
        name: data.name,
        dataNodeID: nodeId,
        dataTypeID: dataSource.dataTypeID,
        url: data.url,
        properties: {
          latitude: submitCoordinates[0],
          longitude: submitCoordinates[1]
        },
        tags: Object.fromEntries(submitTags)
      };
      handleSubmit(submitData);
    },
    [handleSubmit, dataNodes, dataSource.dataTypeID]
  );

  const initialValues = useMemo(
    () => ({
      name: dataSource.name,
      type: dataSource.dataTypeName.toUpperCase(),
      node: selectedDataNode,
      url: dataSource.url,
      coordinates: dataSourceCoordinates,
      tags: tags
    }),
    [dataSource, dataSourceCoordinates, selectedDataNode, tags]
  );

  return (
    <div>
      <div className="right-panel-header">
        <h1>Edit Data Source</h1>
        <Button className="icon" onClick={closePanel} icon={['fal', 'times']} />
      </div>
      <div className="form">
        <Formik
          enableReinitialize
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={EDIT_DATA_SOURCE_SCHEMA}
        >
          {({ isValid, values }) => (
            <Form>
              <Field label="Data source name" name="name" type="text" />
              <Field label="Data type" name="type" type="text" disabled />
              <div className="field-container">
                <label>Data node</label>
                <FormDropdown
                  name="node"
                  values={dropdownDataNodes}
                  selected={selectedDataNode}
                  rightIcon="caret-down"
                  placeholder="Select node"
                />
              </div>
              <Field label="URL" name="url" type="text" />
              <div className={styles['coordinates-container']}>
                <Field
                  className={styles['coordinate-field']}
                  name="coordinates"
                  type="text"
                  label="Device location"
                  placeholder="Latitude, Longitude"
                  description="Choose device location from the map or write coordinates for latitude and longitude, separated by a comma"
                  validate={validateCoordinates}
                />
                <Button
                  type="button"
                  className={classnames('outlined', styles['apply-btn'])}
                  label="Apply"
                  onClick={() => handleClick(values)}
                  disabled={!isValid || !values.coordinates}
                />
              </div>
              <div className="field-container">
                <MapContainer
                  className={styles['map-container']}
                  whenCreated={setMap}
                  center={position}
                  zoom={8}
                  maxZoom={20}
                  style={{ width: '100%', height: '160px' }}
                >
                  <TileLayer
                    attribution='&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> contributors'
                    url={MAP_URL}
                  />
                  <MarkerComponent
                    dataSourceCoordinates={dataSourceCoordinates}
                    setDataSourceCoordinates={setDataSourceCoordinates}
                    mapMarkerIcon={mapMarkerIcon}
                  />
                </MapContainer>
              </div>
              <TagInput name="tags" tags={tags} label="Tags" />
              <div>
                <div className="options-bar">
                  <Button
                    id="cancel-btn"
                    type="button"
                    onClick={closePanel}
                    label="Cancel"
                  />
                  <Button
                    id="save-btn"
                    type="submit"
                    className="primary"
                    label="Save"
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

const MarkerComponent = ({
  setDataSourceCoordinates,
  dataSourceCoordinates,
  mapMarkerIcon
}) => {
  // eslint-disable-next-line
  const map = useMapEvent('click', e => {
    setDataSourceCoordinates([
      e.latlng.lat.toFixed(COORDINATES_MAX_LENGTH),
      e.latlng.lng.toFixed(COORDINATES_MAX_LENGTH)
    ]);
  });
  if (dataSourceCoordinates.length > 0) {
    return <Marker position={dataSourceCoordinates} icon={mapMarkerIcon} />;
  }
  return null;
};

export default DataSourcesPanel;

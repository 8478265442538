import React from 'react';

import get from 'lodash/get';

import { NoData, WidgetWrapper } from 'components';
import { SENTIMENT_COLORS } from 'constants';

const TextAnalysis = ({ data, title }) => {
  const textLines = get(data, 'lines', null);

  const formattedItems = textLines?.map(item => {
    const sentiment = get(item.metadata, 'sentiment', null);

    if (sentiment === 'positive')
      return <p style={{ color: SENTIMENT_COLORS.positive }}>{item.text}</p>;
    if (sentiment === 'negative')
      return <p style={{ color: SENTIMENT_COLORS.negative }}>{item.text}</p>;
    return <p>{item.text}</p>;
  });

  return (
    <WidgetWrapper name="Text Analysis" title={title}>
      {textLines ? <div>{formattedItems}</div> : <NoData />}
    </WidgetWrapper>
  );
};

export default TextAnalysis;

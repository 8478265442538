import { Field } from 'components';
import { getFieldName } from 'utilities';

const FieldParameter = ({ parameter, handleValidate, isIntegrationField }) => (
  <Field
    key={parameter.ID}
    disabled={isIntegrationField}
    name={getFieldName(
      parameter.type,
      parameter.ID,
      parameter.flowNodeID,
      parameter.nodeTypeParameterID
    )}
    label={`${parameter.name} ${isIntegrationField ? '(Integration)' : ''}`}
    type={isIntegrationField ? 'password' : parameter.type}
    placeholder={isIntegrationField ? '' : `Enter ${parameter.name}`}
    validate={handleValidate}
  />
);

export default FieldParameter;

import { call, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { filterNodeParameters } from 'utilities';

import { isEqual, sortBy, uniqWith } from 'lodash';

import * as API from 'store/api';

import * as actionTypes from 'store/actions/actionTypes';
import { redeployFlowNode } from 'store/actions/flows';
import { clearFlow } from 'store/actions/flow';
import { flowSelector } from 'store/selectors';
import { FlowStatus, ROUTES } from 'constants';

import {
  showErrorNotification,
  showSuccessNotification
} from './notifications';

function* createFlow(action) {
  const { data } = action.payload;

  if (data) {
    try {
      const createFlowData = {
        name: data.name,
        description: data.description
      };

      const {
        data: { response }
      } = yield call(API.createFlow, createFlowData);

      yield* showSuccessNotification({
        content: 'Flow created.'
      });

      yield put({
        type: actionTypes.CREATE_FLOW_SUCCEEDED,
        payload: response
      });
      yield put(clearFlow());
      yield put(push(`${ROUTES.FLOWS.ROOT}/${response.ID}/0`));
    } catch (error) {
      yield* showErrorNotification({
        content: 'Error creating flow.'
      });
      yield put({
        type: actionTypes.CREATE_FLOW_FAILED,
        payload: error
      });
    } finally {
      yield put({
        type: actionTypes.RIGHT_PANEL_CLOSE
      });
    }
  }
}

function* updateFlow(action) {
  const { data } = action.payload;

  if (data) {
    try {
      const updatedFlowData = {
        name: data.name,
        description: data.description,
        flowID: data.flowId
      };

      const {
        data: { response }
      } = yield call(API.updateFlow, updatedFlowData);

      yield* showSuccessNotification({
        content: 'Flow saved.'
      });

      yield put({
        type: actionTypes.UPDATE_FLOW_SUCCEEDED,
        payload: response
      });
      yield put(clearFlow());
    } catch (error) {
      yield* showErrorNotification({
        content: 'Error updating flow.'
      });
      yield put({
        type: actionTypes.UPDATE_FLOW_FAILED,
        payload: error.message
      });
    } finally {
      yield put({
        type: actionTypes.RIGHT_PANEL_CLOSE
      });
    }
  }
}

function* updateFlowNodes(action) {
  const idSelector = obj => obj.ID;

  const { data, nodeId, flowId } = action.payload;
  if (data) {
    try {
      const flow = yield select(flowSelector);

      // ORIGINAL
      const originalNodesSorted = flow.nodes
        ? sortBy(flow.nodes, idSelector)
        : [];
      const originalConnectorsSorted = flow.connectors
        ? sortBy(flow.connectors, idSelector)
        : [];
      const originalParametersSorted = flow.parameters
        ? sortBy(flow.parameters, idSelector)
        : [];

      // DATA
      const dataNodesSorted = data.nodes ? sortBy(data.nodes, idSelector) : [];

      const dataConnectorsSorted = data.connectors
        ? sortBy(data.connectors, idSelector)
        : [];
      const dataParametersSorted = data.parameters
        ? sortBy(data.parameters, idSelector)
        : [];

      // this is needed since one node can be connected twice to other node on canvas
      const distinctConnectors = uniqWith(
        dataConnectorsSorted,
        (first, second) =>
          first.destinationNodeId === second.destinationNodeId &&
          first.sourceNodeId === second.sourceNodeId &&
          first.flowId === second.flowId
      );

      const updatedFlowNodes = {
        flowID: flowId,
        nodes: data.parameters ? originalNodesSorted : dataNodesSorted,
        connectors: data.parameters
          ? originalConnectorsSorted
          : distinctConnectors,
        parameters:
          dataParametersSorted.length > 0
            ? filterNodeParameters(dataParametersSorted, flowId)
            : originalParametersSorted
      };

      if (
        isEqual(originalNodesSorted, updatedFlowNodes.nodes) &&
        isEqual(originalConnectorsSorted, updatedFlowNodes.connectors) &&
        isEqual(originalParametersSorted, updatedFlowNodes.parameters)
      ) {
        return;
      }

      const {
        data: { response }
      } = yield call(API.updateFlowNodes, updatedFlowNodes);

      yield* showSuccessNotification({
        content: 'Flow saved.'
      });

      yield put({
        type: actionTypes.UPDATE_FLOW_NODES_SUCCEEDED,
        payload: response
      });

      if (nodeId && flow.status === FlowStatus.Running) {
        yield put(redeployFlowNode(flowId, nodeId));
      }
    } catch (error) {
      yield* showErrorNotification({
        content: 'Error updating flow.'
      });
      yield put({
        type: actionTypes.UPDATE_FLOW_NODES_FAILED,
        payload: error.message
      });
    } finally {
      yield put({
        type: actionTypes.RIGHT_PANEL_CLOSE
      });
    }
  }
}

function* fetchNodeDefinitions() {
  try {
    const {
      data: { response: dataNodes }
    } = yield call(API.fetchDatanodesPalette);

    yield put({
      type: actionTypes.FETCH_NODE_DEFINITIONS_SUCCEEDED,
      payload: dataNodes
    });
  } catch (error) {
    yield* showErrorNotification({
      content: 'Error fetching node definitions.'
    });
    yield put({
      type: actionTypes.FETCH_NODE_DEFINITIONS_FAILED,
      payload: error.message
    });
  }
}

function* deleteFlow(action) {
  try {
    const { id } = action.payload;
    yield call(API.deleteFlow, id);

    yield put({
      type: actionTypes.DELETE_FLOW_SUCCEEDED
    });
    yield* showSuccessNotification({
      content: 'Flow deleted.'
    });
  } catch (error) {
    yield* showErrorNotification({
      content: 'Error deleting flow.'
    });
    yield put({
      type: actionTypes.DELETE_FLOW_FAILED,
      payload: error.message
    });
  }
}

function* destroyFlow(action) {
  try {
    const { id } = action.payload;
    yield call(API.destroyFlow, id);

    yield put({
      type: actionTypes.DESTROY_FLOW_SUCCEEDED
    });
    yield* showSuccessNotification({
      content: 'Flow stopped.'
    });
  } catch (error) {
    yield* showErrorNotification({
      content: 'Error stopping flow.'
    });
    yield put({
      type: actionTypes.DESTROY_FLOW_FAILED,
      payload: error.message
    });
  }
}

function* deployFlow(action) {
  try {
    const { id } = action.payload;
    yield call(API.deployFlow, id);

    yield put({
      type: actionTypes.DEPLOY_FLOW_SUCCEEDED
    });
    yield* showSuccessNotification({
      content: 'Flow deployed successfully.'
    });
  } catch (error) {
    yield* showErrorNotification({
      content: 'Error deploying flow.'
    });
    yield put({
      type: actionTypes.DEPLOY_FLOW_FAILED,
      payload: error.message
    });
  }
}

function* redeployNode(action) {
  try {
    const { flowId, nodeId } = action.payload;
    yield call(API.redeployNode, { flowId, nodeId });

    yield put({
      type: actionTypes.REDEPLOY_FLOW_NODE_SUCCEEDED
    });
    yield* showSuccessNotification({
      content: 'Node redeployed succesfully.'
    });
  } catch (error) {
    yield* showErrorNotification({
      content: 'Error redeploying node.'
    });
    yield put({
      type: actionTypes.REDEPLOY_FLOW_NODE_FAILED,
      payload: error.message
    });
  }
}

function* cloneFlow(action) {
  try {
    const { flowId, name, description } = action.payload;
    const {
      data: { response }
    } = yield call(API.cloneFlow, {
      flowId,
      name,
      description
    });

    yield put({
      type: actionTypes.CLONE_FLOW_SUCCEEDED
    });
    yield* showSuccessNotification({
      content: 'Flow cloned.'
    });

    yield put(clearFlow());
    // this means flow was created using another flow as base
    // so redirect user instantly to newly created flow
    yield put(push(`${ROUTES.FLOWS.ROOT}/${response.ID}/0`));
  } catch (error) {
    yield* showErrorNotification({
      content: 'Error cloning flow.'
    });
    yield put({
      type: actionTypes.CLONE_FLOW_FAILED,
      payload: error.message
    });
  } finally {
    yield put({
      type: actionTypes.RIGHT_PANEL_CLOSE
    });
  }
}

export {
  createFlow,
  updateFlow,
  updateFlowNodes,
  fetchNodeDefinitions,
  deleteFlow,
  destroyFlow,
  deployFlow,
  redeployNode,
  cloneFlow
};

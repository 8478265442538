import produce from 'immer';
import * as actionTypes from 'store/actions/actionTypes';

const initialState = {
  dataNodes: [],
  dataNodeNames: [],
  dataSourcesByDataNodeIds: [],
  dataTypes: {},
  dataSources: [],
  unassignedDataSources: [],
  fetched: false,
  dataNodeId: ''
};

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case actionTypes.FETCH_DATANODES_SUCCEEDED: {
      const { data } = action.payload;
      draft.dataNodes = data?.response;
      draft.fetched = true;
      draft.dataNodeNames = Object.fromEntries(
        (data?.response ?? []).map(item => [item.ID, item.name])
      );
      break;
    }

    case actionTypes.FETCH_DATASOURCES_SUCCEEDED: {
      const { data } = action.payload;
      draft.dataSources = data?.response;
      break;
    }

    case actionTypes.FETCH_UNASSIGNED_DATASOURCES_SUCCEEDED: {
      const { data } = action.payload;
      draft.unassignedDataSources = data?.response;
      break;
    }

    case actionTypes.FETCH_DATATYPES_SUCCEEDED: {
      const { data } = action.payload;
      draft.dataTypes = Object.fromEntries(
        (data?.response ?? []).map(item => [item.ID, item])
      );
      break;
    }

    case actionTypes.FETCH_DATASOURCES_BY_DATANODE_ID_SUCCEEDED: {
      const { data } = action.payload;
      draft.dataSourcesByDataNodeIds = data?.response;
      break;
    }

    default:
      break;
  }
}, initialState);

export default reducer;

import Toggle from './Toggle';
import { getFieldName } from 'utilities';

const ToggleParameter = ({ parameter }) => (
  <Toggle
    parameter={parameter}
    name={getFieldName(
      parameter.type,
      parameter.ID,
      parameter.flowNodeID,
      parameter.nodeTypeParameterID
    )}
  />
);

export default ToggleParameter;

import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { chartColors } from 'utilities/transform';
import {
  FlexibleWidthXYPlot,
  XAxis,
  YAxis,
  VerticalBarSeries,
  VerticalGridLines,
  HorizontalGridLines
} from 'react-vis';

import { WidgetWrapper } from 'components';

const BarChart = ({ className, data, title, name, isMocked }) => {
  const classes = classNames(className || 'bar-chart');

  const items = useMemo(
    () => (data?.items && Array.isArray(data?.items) ? data?.items : []),
    [data.items]
  );
  const domainMin = 0;
  const chartHeight = 400;

  const [maxYDomain, setMaxYDomain] = useState(20);

  const chart = items.map(
    (item, index) =>
      (data = {
        x: item.key,
        y: item.value,
        color: chartColors[Math.floor(index % chartColors.length)]
      })
  );

  const chartData = useMemo(() => {
    if (chart && chart.length) {
      return chart;
    }
    return [];
  }, [chart]);

  useEffect(() => {
    const currentMaxYDomain = Math.max(...chart.map(({ y }) => (y ? y : 0)));

    if (maxYDomain - 15 <= currentMaxYDomain && currentMaxYDomain > 6) {
      setMaxYDomain(currentMaxYDomain * 1.5);
    }
  }, [chart, maxYDomain]);

  const fakeItems = useMemo(() => {
    if (chartData.length === 0) {
      if (isMocked) {
        return [
          <VerticalBarSeries
            key="first"
            data={[{ x: '', y: 10, color: 'var(--color-grey-dark)' }]}
          />,
          <VerticalBarSeries
            key="second"
            data={[{ x: '', y: 7, color: 'var(--color-grey)' }]}
          />
        ];
      }
      return (
        <VerticalBarSeries data={[{ x: '', y: 0, color: 'transparent' }]} />
      );
    }
    return null;
  }, [chartData, isMocked]);

  return (
    <WidgetWrapper title={title} name={name}>
      <div className={classes}>
        <FlexibleWidthXYPlot
          xType="ordinal"
          colorType="literal"
          height={chartHeight}
          margin={{ left: maxYDomain > 1000 ? 70 : 40 }}
          yDomain={[domainMin, maxYDomain]}
        >
          <VerticalGridLines tickValues={[0]} tickTotal={1} />
          <HorizontalGridLines />
          <XAxis orientation="bottom" />
          <YAxis tickTotal={10} />
          {chartData.length > 0 ? <VerticalBarSeries data={chartData} /> : null}
          {fakeItems}
        </FlexibleWidthXYPlot>
      </div>
    </WidgetWrapper>
  );
};

BarChart.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      y: PropTypes.number,
      x: PropTypes.string,
      color: PropTypes.string
    })
  )
};

export default BarChart;

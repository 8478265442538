import React, { useState, useCallback } from 'react';
import { Field as FormField, useField } from 'formik';

import { InfoIcon } from 'components';

import styles from './TagInput.module.scss';

const TagInput = ({ name, tags, label }) => {
  const [input, setInput] = useState('');
  const [field, _, helpers] = useField({ name, value: tags });

  const onChange = useCallback(
    e => {
      const { value } = e.target;
      setInput(value);
    },
    [setInput]
  );

  const handleKeyDown = e => {
    const { keyCode } = e;
    const trimmedInput = input.trim();
    if (keyCode === 13 && trimmedInput.length && !tags.includes(trimmedInput)) {
      e.preventDefault();
      field.value.push(trimmedInput);
      setInput('');
    }
  };

  const deleteTag = useCallback(
    index => {
      helpers.setValue(field.value.filter((tag, i) => i !== index));
    },
    [field.value, helpers]
  );

  return (
    <div className="field-container">
      <div className={styles['field-label']}>
        <label>{label}</label>
        <InfoIcon className={styles['info-icon']}>
          Add new tag by pressing Enter. Remove tags by clicking X.
        </InfoIcon>
      </div>
      <div className={styles['textarea-container']}>
        <div className={styles['tags-container']}>
          {field.value.map((tag, i) => (
            <div key={tag} className={styles['input-tag']}>
              {tag}
              <button type="button" onClick={() => deleteTag(i)}>
                x
              </button>
            </div>
          ))}
        </div>
        <FormField
          id={name}
          name={name}
          as="textarea"
          className={styles['tag-input']}
          type="text"
          value={input}
          placeholder="Enter a tag"
          onKeyDown={handleKeyDown}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default TagInput;

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Icon from '../Icon/Icon';

const Input = props => {
  const classes = classnames('field-container', props.className);

  const required = props.required ? (
    <span className="input-required">*</span>
  ) : null;

  const label = props.label ? (
    <label htmlFor={props.id}>
      {props.label}
      {required}
    </label>
  ) : null;

  const error = props.error ? (
    <span className="error">{props.error}</span>
  ) : null;

  const icon = props.icon ? (
    <Icon className={props.icon.className} name={props.icon.name} />
  ) : null;

  return (
    <div className={classes}>
      {label}
      <input {...props} value={props.value || ''} />
      {icon}
      {error}
    </div>
  );
};

Input.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.shape({
    className: PropTypes.string,
    name: PropTypes.string
  }),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func
};

export default Input;

import styled from 'styled-components';

import { Icon as CommonIcon } from 'components';

const Icon = styled(CommonIcon)`
  width: var(--s-l);
  height: var(--s-l);
  line-height: var(--s-l);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.span`
  display: flex;
  height: var(--s-l);
  width: var(--s-l);
  align-items: center;
`;

export { Icon, Wrapper };

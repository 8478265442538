import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef
} from 'react';
import classnames from 'classnames';
import { decode } from 'html-entities';
import toPairs from 'lodash/toPairs';

import { DEFAULT_PAGE_SIZE } from 'constants';
import { MultiSelectionDropdown } from 'components/UI/Dropdown/Dropdown';
import { Button, Dropdown, Input, Table, Checkbox } from 'components';
import { useMultiSelection } from 'components/UI/Dropdown/hooks/useMultiSelection';
import useDropdown from 'hooks/useDropdown';
import {
  composeTags,
  applyTagFilters,
  getDropdownDataTypes,
  applyFilters,
  FILTER_KEYS
} from 'utilities';

import styles from './CreateDataNodeModal.module.scss';

const CreateDataNodeModal = ({
  closeModal,
  confirmModal,
  dataTypes,
  dataSources,
  dataNodeNames
}) => {
  const [tableData, setTableData] = useState(dataSources);
  const [tagFilters, setTagFilters] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedDataSourceIds, setSelectedDataSourceIds] = useState([]);
  const [dataNodeName, setDataNodeName] = useState('');
  const [filters, setFilters] = useState({
    [FILTER_KEYS.DATA_NODE]: undefined,
    [FILTER_KEYS.TYPE]: undefined,
    [FILTER_KEYS.SEARCH]: undefined
  });

  const onSearch = useCallback(event => {
    const value = event?.target?.value;
    setFilters(filters => ({
      ...filters,
      [FILTER_KEYS.SEARCH]: value
    }));
  }, []);

  const currentPageVisibleDataSourceIds = useRef([]);

  const selectedType = useMemo(() => filters.type, [filters.type]);

  const selectedDataSources = useMemo(
    () => dataSources.filter(item => selectedDataSourceIds.includes(item.ID)),
    [selectedDataSourceIds, dataSources]
  );

  const dropdownDataTypes = useMemo(() => getDropdownDataTypes(dataTypes), [
    dataTypes
  ]);

  const submitDataExists = useMemo(
    () =>
      selectedType && dataNodeName && selectedDataSources.length > 0
        ? true
        : false,
    [dataNodeName, selectedDataSources, selectedType]
  );

  const { selectedItems, onSelect, isSelected } = useMultiSelection({
    onSelect: setTagFilters
  });

  const {
    shouldShow: shouldShowDropdown,
    setShouldShow: setShouldShowDropdown
  } = useDropdown();

  const handleNameChange = useCallback(event => {
    setDataNodeName(event.target.value);
  }, []);

  const handleTypeSelect = useCallback(
    type => value => {
      setSelectedDataSourceIds([]);

      setFilters(filters => ({
        ...filters,
        [type]: value
      }));
    },
    []
  );

  const handleSelectDataSource = useCallback(
    e => {
      const { id: currentDataSourceId, checked } = e.target;
      const selectedType = dataSources.find(
        item => item.ID === currentDataSourceId
      );
      if (checked) {
        setSelectedDataSourceIds(ids =>
          [...ids, currentDataSourceId].filter(Boolean)
        );
        setFilters(filters => ({
          ...filters,
          type: {
            key: selectedType.dataTypeID,
            value: selectedType.dataTypeName.toUpperCase()
          }
        }));
      } else {
        setSelectedDataSourceIds(ids =>
          ids.filter(id => id !== currentDataSourceId)
        );
      }
    },
    [setSelectedDataSourceIds, dataSources]
  );

  const handleSubmit = useCallback(() => {
    if (submitDataExists) {
      const dataNodeObject = {
        name: dataNodeName,
        dataTypeID: selectedType.key
      };

      confirmModal(selectedDataSources, dataNodeObject);
    }
    return;
  }, [
    confirmModal,
    dataNodeName,
    selectedDataSources,
    selectedType,
    submitDataExists
  ]);

  useEffect(() => {
    const tags = composeTags(dataSources);
    setTags(tags);

    const filteredData = applyFilters(
      applyTagFilters({ dataSources, tagFilters }),
      filters
    );
    setTableData(filteredData);
  }, [dataSources, tagFilters, filters]);

  const columns = useMemo(
    () =>
      generateColumns({
        selectedDataSourceIds,
        handleSelectDataSource,
        setSelectedDataSourceIds,
        dataNodeNames,
        dataSources,
        currentPageVisibleDataSourceIds
      }),
    [
      selectedDataSourceIds,
      handleSelectDataSource,
      setSelectedDataSourceIds,
      dataNodeNames,
      dataSources,
      currentPageVisibleDataSourceIds
    ]
  );

  return (
    <div className={styles.container}>
      <div className={styles['data-info-container']}>
        <div className={classnames('field-container', styles['wrapper'])}>
          <Input
            id="data-node-name"
            type="text"
            label="Data Node Name"
            placeholder="Data node name"
            onChange={handleNameChange}
            value={dataNodeName}
          />
        </div>
        <div className={classnames('field-container', styles['wrapper'])}>
          <label htmlFor="">Type</label>
          <Dropdown
            className="data-node"
            values={dropdownDataTypes}
            placeholder="Select type"
            onItemSelected={handleTypeSelect(FILTER_KEYS.TYPE)}
            open={shouldShowDropdown}
            setOpen={setShouldShowDropdown}
            rightIcon="caret-down"
            selected={filters[FILTER_KEYS.TYPE] ?? undefined}
          />
        </div>
      </div>
      <div className={styles['table-wrapper']}>
        <div className="table-toolbar dropdown">
          <Input
            className="search"
            type="search"
            placeholder="Search"
            onChange={onSearch}
            value={filters[FILTER_KEYS.SEARCH] ?? ''}
          />
          <div className={styles['filters']}>
            <MultiSelectionDropdown
              values={tags}
              shouldShow={shouldShowDropdown}
              setShouldShow={setShouldShowDropdown}
              entity={{ plural: 'Tags', singular: 'Tag' }}
              selectedItems={selectedItems}
              isSelected={isSelected}
              onSelect={onSelect}
            />
          </div>
        </div>
        <Table
          data={tableData}
          columns={columns}
          minRows={DEFAULT_PAGE_SIZE}
          pageSize={
            dataSources.length < DEFAULT_PAGE_SIZE
              ? dataSources.length
              : DEFAULT_PAGE_SIZE
          }
          pagination
        />
      </div>
      <div className="footer">
        <Button
          id="cancel-btn"
          type="button"
          onClick={closeModal}
          label="Cancel"
        />
        <Button
          type="submit"
          onClick={handleSubmit}
          className="primary"
          label="Create"
          disabled={submitDataExists ? false : true}
        />
      </div>
    </div>
  );
};

export default CreateDataNodeModal;

const generateColumns = ({
  setSelectedDataSourceIds,
  selectedDataSourceIds,
  handleSelectDataSource,
  dataNodeNames,
  currentPageVisibleDataSourceIds,
  dataSources
}) =>
  [
    {
      Header: () => {
        const isAllChecked =
          selectedDataSourceIds.length > 0 &&
          currentPageVisibleDataSourceIds.current.length !== 0
            ? currentPageVisibleDataSourceIds.current.every(el =>
                selectedDataSourceIds.includes(el)
              )
            : false;
        const handleSelectAllDataSources = () => {
          const selectedSourceIds = [];
          if (!isAllChecked) {
            selectedSourceIds.push(
              ...dataSources
                .filter(({ ID }) =>
                  currentPageVisibleDataSourceIds.current.includes(ID)
                )
                .map(({ ID }) => ID)
            );
          }
          setSelectedDataSourceIds(selectedSourceIds);
        };

        return (
          <Checkbox
            onChange={handleSelectAllDataSources}
            checked={isAllChecked}
          />
        );
      },
      getHeaderProps: state => {
        currentPageVisibleDataSourceIds.current = state.pageRows.map(
          i => i?._original.ID
        );
        return {};
      },
      width: 50,
      accessor: 'modify',
      className: 'checkbox-column',
      sortable: false,
      Cell: ({ original: { ID } }) => (
        <Checkbox
          key={ID}
          checked={selectedDataSourceIds.indexOf(ID) !== -1}
          onChange={handleSelectDataSource}
          id={ID}
        />
      )
    },
    {
      Header: <span className="text">Input</span>,
      accessor: 'name',
      className: 'text',
      minWidth: 300,
      Cell: row => (
        <div className="cell-actions-wrapper">
          <div className="cell-actions-content">{row.value}</div>
        </div>
      )
    },
    {
      Header: <span className="text">Type</span>,
      accessor: 'dataType',
      Cell: row => {
        return (
          <span className="tag table-tag admin">
            {row.original.dataTypeName}
          </span>
        );
      },
      id: 'data-type',
      className: 'data-type-column'
    },
    {
      Header: <span className="text">Data Node</span>,
      accessor: ({ dataNodeID }) =>
        dataNodeID ? (
          <span className="tag table-tag">
            {decode(dataNodeNames[dataNodeID], { level: 'all' })}
          </span>
        ) : null,
      id: 'data-node',
      className: 'data-node-column'
    },
    {
      Header: () => <span>Tags</span>,
      className: 'tags-column',
      accessor: ({ tags }) => tags,
      id: 'tags',
      minWidth: 200,
      sortable: false,
      Cell: row => {
        const { value } = row;
        const tags = toPairs(value);

        return (
          <>
            {tags.map(tag => {
              const [name, value] = tag;

              const key = `${name}.${value}`;

              return (
                <span className="tag table-tag" key={key}>
                  {value}
                </span>
              );
            })}
          </>
        );
      }
    }
  ].filter(Boolean);

import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import Draggable from 'react-draggable';
import { getFieldName } from 'utilities';
import { Field as FormField } from 'formik';

import {
  calculateBounds,
  desearializeCoordinates,
  searializeCoordinates,
  BOUNDS
} from './utils';

import styles from './CoordinatesParameter.module.scss';

const FAKE_BLOCK_OFFSET = 5;

const CoordinatesParamter = ({ parameter, setFieldValue }) => {
  const fieldName = useMemo(
    () =>
      getFieldName(
        parameter.type,
        parameter.ID,
        parameter.flowNodeID,
        parameter.nodeTypeParameterID
      ),
    [
      parameter.ID,
      parameter.type,
      parameter.flowNodeID,
      parameter.nodeTypeParameterID
    ]
  );

  const initialPosition = useMemo(
    () => searializeCoordinates(parameter.value),
    [] //eslint-disable-line
  );

  const [coordinates, setCoordinates] = useState(initialPosition);

  const handleDrag = useCallback(
    (e, ui) =>
      window.requestAnimationFrame(() => {
        e.preventDefault();
        const itemKey = ui.node.id;
        const coordinatesData = {
          [itemKey]: { x: ui.x, y: ui.y }
        };
        setCoordinates(coordinates => ({ ...coordinates, ...coordinatesData }));
      }),
    [] //eslint-disable-line
  );

  useEffect(() => {
    setFieldValue(
      fieldName,
      desearializeCoordinates(coordinates.first, coordinates.second)
    );
  }, [coordinates]); // eslint-disable-line

  const handleSwap = useCallback(() => {
    setCoordinates(coordinates => {
      const first = { ...coordinates.first };
      const second = { ...coordinates.second };
      return { second: first, first: second };
    });
  }, []);

  const bounds = useMemo(() => {
    const { first, second } = coordinates;

    const firstBounds = calculateBounds(first.x, first.y);
    const secondBounds = calculateBounds(second.x, second.y);

    return {
      first: firstBounds,
      second: secondBounds
    };
  }, [coordinates]);

  return (
    <div className="field-container">
      <label>{parameter.name}</label>
      <FormField name={fieldName} className={styles.hidden} disabled />
      <div className={styles.wrapper}>
        <div className={styles['fake-coordinates-box']} />
        <div className={styles['coordinates-box']}>
          <Draggable
            onDrag={handleDrag}
            position={coordinates.first}
            defaultPosition={{ x: BOUNDS.MIN, y: BOUNDS.MAX / 2 }}
            bounds={bounds.first}
          >
            <div className={styles['drag-box']} id="first"></div>
          </Draggable>
          <Draggable
            onDrag={handleDrag}
            position={coordinates.second}
            bounds={bounds.second}
            defaultPosition={{ x: BOUNDS.MAX, y: BOUNDS.MAX / 2 }}
          >
            <div className={styles['drag-box']} id="second"></div>
          </Draggable>

          <svg style={{ height: '200px', width: '200px' }}>
            <line
              strokeWidth="2px"
              stroke="#000000"
              x1={coordinates.first.x + FAKE_BLOCK_OFFSET}
              y1={coordinates.first.y + FAKE_BLOCK_OFFSET}
              x2={coordinates.second.x + FAKE_BLOCK_OFFSET}
              y2={coordinates.second.y + FAKE_BLOCK_OFFSET}
              id="mySVG"
            />
          </svg>
          <RightArrow
            onClick={handleSwap}
            first={coordinates.first}
            second={coordinates.second}
          />
        </div>
      </div>
    </div>
  );
};

const RightArrow = ({ first, second, onClick }) => {
  // angle in degrees
  var angleDeg = useMemo(
    () =>
      (Math.atan2(second.y - first.y, second.x - first.x) * 180) / Math.PI - 90,
    [first, second]
  );

  return (
    <IconWrapper
      onClick={onClick}
      first={first}
      second={second}
      angle={angleDeg}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
        <path d="m190.5 66.9 22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z" />
      </svg>
    </IconWrapper>
  );
};

const IconWrapper = styled.div.attrs(props => ({
  style: {
    top: `${(props.first.y + props.second.y) / 2}px`,
    left: `${(props.first.x + props.second.x) / 2}px`,
    transform: `translate(-50%, -50%) rotate(${props.angle}deg)`
  }
}))`
  position: absolute;
  width: 35px;
  max-height: 50px;
  cursor: pointer;
  svg {
    fill: black;
  }
`;

export default CoordinatesParamter;

import * as actionTypes from 'store/actions/actionTypes';

export const getDataNodes = () => ({
  type: actionTypes.FETCH_DATANODES_REQUESTED
});

export const getDataTypes = () => ({
  type: actionTypes.FETCH_DATATYPES_REQUESTED
});

export const getDataSources = () => ({
  type: actionTypes.FETCH_DATASOURCES_REQUESTED
});

export const getDataSourcesById = id => ({
  type: actionTypes.FETCH_DATASOURCES_BY_DATANODE_ID_REQUESTED,
  payload: { dataNodeId: id }
});

export const deleteDataSource = (nodeId, sourceId) => ({
  type: actionTypes.DELETE_DATASOURCE_REQUESTED,
  payload: { dataNodeId: nodeId, dataSourceId: sourceId }
});

export const updateDataSources = data => ({
  type: actionTypes.UPDATE_DATASOURCES_REQUESTED,
  payload: data
});
export const updateDataSource = (dataSourceId, data) => ({
  type: actionTypes.UPDATE_DATASOURCE_REQUESTED,
  payload: { dataSourceId, data }
});

export const createDataNode = (dataNode, dataSources) => ({
  type: actionTypes.CREATE_DATANODE_REQUESTED,
  payload: { dataNode, dataSources }
});

export const deleteDataNode = nodeId => ({
  type: actionTypes.DELETE_DATANODE_REQUESTED,
  payload: { dataNodeId: nodeId }
});

export const importDataSourceCsv = file => ({
  type: actionTypes.UPLOAD_DATASOURCES_CSV_REQUESTED,
  payload: file
});

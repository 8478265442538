import React, { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ErrorMessage, useField } from 'formik';
import ReactSlider from 'react-slider';
import { Input } from 'components';
import * as yup from 'yup';

import './Slider.scss';
import styles from './Slider.module.scss';

const Slider = ({ className, name, label, max, min, value, isTimeInput }) => {
  const classes = classNames(className || 'field-container');
  const [field, _, helpers] = useField({
    name,
    type: 'text',
    value,
    validate: number => {
      return (
        yup
          .number()
          .typeError('Field is required, plese enter value.')
          .max(max, 'Value should be less then ${max}')
          .min(min, 'Value should be bigger then ${min}')
          .required()
          .validate(number)
          // if not error then return undefined
          .then(() => undefined)
          // if there is error, we return string and render as message below
          .catch(e => e.message)
      );
    }
  });

  const handleValueChange = event => {
    helpers.setValue(event.target.value);
  };

  const takeValue = useCallback(
    value => {
      const maximum = +max;
      const minimum = +min;
      if (value > maximum) {
        return maximum;
      } else if (value < minimum) {
        return minimum;
      }
      return value;
    },
    [max, min]
  );

  const handleSelect = useCallback(
    (multiplyBy = 1) => e => {
      e.preventDefault();
      const value = field.value;
      const newValue = value * multiplyBy;

      helpers.setValue(takeValue(newValue));
    },
    [field.value, helpers, takeValue]
  );
  // resize ref when the parent component is resizing independently
  const containerRef = useRef();
  const sliderRef = useRef();
  useEffect(() => {
    if (typeof ResizeObserver !== 'undefined') {
      const currentRef = containerRef.current;
      const resizeObserver = new ResizeObserver(() => {
        sliderRef.current.resize();
      });
      resizeObserver.observe(currentRef);

      return () => {
        resizeObserver.unobserve(currentRef);
      };
    }
  }, []);
  return (
    <div className={classes} ref={containerRef}>
      <label htmlFor={name}>
        {label || name} ({min}—{max})
      </label>
      <div className="slider">
        <ReactSlider
          className="horizontal-slider"
          thumbClassName="thumb"
          trackClassName="track"
          max={max}
          min={min}
          step={!Number.isInteger(min) || Number(max) <= 1 ? 0.01 : 1}
          ref={sliderRef}
          type="number"
          {...field}
          value={+field.value}
          // eslint-disable-next-line
          onChange={value => helpers.setValue(`${value}`)}
        />
        <Input
          className="slider-value"
          type="number"
          placeholder="Value"
          value={field.value}
          onChange={handleValueChange} /* eslint-disable-line */
          step="0.01"
        />
        {isTimeInput ? (
          <div className={styles['time-picker-block']}>
            <button onClick={handleSelect(60 * 60 * 60, 'd')}>d</button>
            <button onClick={handleSelect(60 * 60, 'hr')}>hr</button>
            <button onClick={handleSelect(60, 'min')}>min</button>
          </div>
        ) : null}
      </div>
      <ErrorMessage component="span" className="error" name={name} />
    </div>
  );
};

Slider.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  thumbClassName: PropTypes.string,
  trackClassName: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  value: PropTypes.number,
  onChange: PropTypes.func,
  type: PropTypes.number
};

export default Slider;

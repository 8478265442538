import * as actionTypes from 'store/actions/actionTypes';

export const requestUserRegister = payload => ({
  type: actionTypes.REGISTER_USER_REQUESTED,
  payload
});

export const requestUserLogin = payload => ({
  type: actionTypes.LOG_IN_USER_REQUESTED,
  payload
});

export const authSetPassword = (password, jwt) => ({
  type: actionTypes.AUTH_SET_PASSWORD_REQUESTED,
  payload: {
    password,
    jwt
  }
});

export const requestUserLogout = () => ({
  type: actionTypes.LOG_OUT_USER_REQUESTED
});

export const authCheck = () => ({
  type: actionTypes.AUTH_CHECK_REQUESTED
});

import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Field, ErrorMessage, useField } from 'formik';
import classNames from 'classnames';

import { Icon } from 'components';

import './FieldPassword.scss';

const FieldPassword = ({ name, label, className, ...props }) => {
  const classes = classNames(className || 'field-container');
  const hasLabel = label && typeof label === 'string';
  const [field] = useField({
    ...props,
    name
  });
  const [shouldShowPassword, setShouldShowPassword] = useState(false);

  const togglePasswordVisibility = useCallback(() => {
    setShouldShowPassword(!shouldShowPassword);
  }, [shouldShowPassword, setShouldShowPassword]);

  return (
    <div className={classes}>
      {hasLabel && <label htmlFor={name}>{label || name}</label>}
      <div className="password-field__wrap">
        <Field
          {...field}
          {...props}
          type={shouldShowPassword ? 'text' : 'password'}
        />
        <button
          onClick={togglePasswordVisibility}
          type="button"
          className={classNames('btn-password-toggle', {
            active: shouldShowPassword
          })}
        >
          <Icon name={['far', shouldShowPassword ? 'eye-slash' : 'eye']} />
        </button>
      </div>
      <ErrorMessage component="span" className="error" name={name} />
    </div>
  );
};

FieldPassword.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default FieldPassword;

import axios from 'axios';

const API_URL = window?.ENV?.API_URL;

const USERS_URL = 'auth/users/';

export const inviteUsers = (emails = []) =>
  axios({
    baseURL: API_URL,
    method: 'POST',
    url: 'auth/invite',
    data: { emails },
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true
  });

export const fetchUsers = () =>
  axios({
    baseURL: API_URL,
    method: 'GET',
    url: USERS_URL,
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true
  });

export const updateUser = ({ id, data }) =>
  axios({
    baseURL: API_URL,
    method: 'PATCH',
    url: `${USERS_URL}${id}`,
    data,
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true
  });

export const deleteUser = ({ id }) =>
  axios({
    baseURL: API_URL,
    method: 'DELETE',
    url: `${USERS_URL}${id}`,
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true
  });

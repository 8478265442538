import React from 'react';

import styles from './IntegrationOptions.module.scss';

import InfoBlock from 'components/Integrations/InfoBlock';

const IntegrationOptions = ({ options, handleClick }) => {
  return (
    <InfoBlock>
      {options.map(({ name, icon: Icon, type }) => (
        <div
          data-testid={name}
          className={styles['integration-option-container']}
          key={name}
          onClick={() => handleClick({ type })}
        >
          <div className={styles['integration-option-icon-wrapper']}>
            <Icon />
          </div>
          <span>{name}</span>
        </div>
      ))}
    </InfoBlock>
  );
};

IntegrationOptions.propTypes = {};

export default IntegrationOptions;

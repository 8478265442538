export const NodeParameters = {
  EVENT_KEY: 'EVENT_KEY',
  VISUALIZATION_STYLE: 'VISUALIZATION_STYLE',
  TITLE: 'TITLE',
  DATAPOINTS_AMOUNT: 'DATAPOINTS_AMOUNT',
  TIMEWINDOW_DURATION: 'TIMEWINDOW_DURATION'
};

export const VisibilityParameters = {
  BASIC: 'basic',
  ADVANCED: 'advanced'
};

export default NodeParameters;

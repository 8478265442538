import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './KeyStat.scss';

const KeyStat = props => {
  const classes = classNames(props.className || 'key-stat');

  const value =
    props.id === 'cpu' ? parseFloat(props.value).toFixed(3) : props.value;

  return (
    <div className={classes} id={props.id}>
      <span className="key-stat-value">{value}</span>
      <span className="key-stat-legend">{props.legend}</span>
    </div>
  );
};

KeyStat.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  iconName: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  legend: PropTypes.string.isRequired
};

export default KeyStat;

import * as actionTypes from 'store/actions/actionTypes';

const initialState = {
  authenticated: false,
  inProgress: false,
  fetched: false,
  user: null,
  error: null
};

const authCheckSucceeded = (state, action) => {
  return {
    ...state,
    authenticated: true,
    inProgress: false,
    user: action.payload,
    fetched: true,
    error: null
  };
};

const authCheckFailed = (state, action) => {
  return {
    ...state,
    error: action.payload,
    authenticated: false,
    fetched: true,
    inProgress: false
  };
};

const authCheckProgress = state => {
  return {
    ...state,
    inProgress: true,
    error: null
  };
};

const authLogoutSuccess = state => {
  return {
    ...state,
    authenticated: false,
    inProgress: false,
    error: null
  };
};

const authLogoutRequested = state => {
  return {
    ...state,
    inProgress: true,
    error: null
  };
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_CHECK_SUCCEEDED:
    case actionTypes.LOG_IN_USER_SUCCEEDED:
      return authCheckSucceeded(state, action);
    case actionTypes.AUTH_CHECK_FAILED:
    case actionTypes.LOG_IN_USER_FAILED:
      return authCheckFailed(state, action);
    case actionTypes.AUTH_CHECK_REQUESTED:
      return authCheckProgress(state);
    case actionTypes.LOG_OUT_USER_SUCCEEDED:
      return authLogoutSuccess(state);
    case actionTypes.LOG_OUT_USER_REQUESTED:
      return authLogoutRequested(state);
    default:
      return state;
  }
};

export default reducer;

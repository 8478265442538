import React from 'react';

import classNames from 'classnames';
import styles from './Authorization.module.scss';
import { ReactComponent as LogoLarge } from 'vector/logo-large.svg';

const Authorization = ({ children }) => {
  return (
    <div id="content">
      <div className={classNames(styles.page, styles.grid)}>
        <div className={styles.left}>
          <div className={styles.textWrapper}>
            <LogoLarge />
            <h1>The platform for enterprise data applications</h1>
            <h2>Accelerate your business transformation with digitalization</h2>
            <p>
              Most enterprises struggle to utilize their data to the fullest
              extent. RAIN lets you fuse and monetize data from any source with
              no coding required.
            </p>
            <h2>Drag-and-drop, connect & act</h2>
            <p>
              Plan data flows, connect data sources, and act upon real-time data
              on a drag-and-drop canvas.
            </p>
          </div>
        </div>
        <div className={classNames(styles.right)}>
          <div id="authorization" className="card">
            {children}
          </div>
          <span className={classNames(styles.copyright)}>
            Copyright © {new Date().getFullYear()} Lempea Oy. All rights
            reserved.
          </span>
        </div>
      </div>
    </div>
  );
};

export default Authorization;

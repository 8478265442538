import { useState, useMemo, useCallback } from 'react';

export function useMultiSelection({
  initialSelection = [],
  onSelect: onSelectCallback
}) {
  const [selectedItems, setSelectedItems] = useState(initialSelection);

  const toggleSelection = useCallback(
    (item, onSelectionChange) => {
      const selectedItemsKeys = selectedItems.map(i => i.key);
      const isSelected = selectedItemsKeys.includes(item.key);
      let newSelection;

      if (!isSelected) {
        newSelection = [...selectedItems, item];
      } else {
        newSelection = selectedItems.filter(i => i.key !== item.key);
      }

      setSelectedItems(newSelection);

      if (onSelectionChange && typeof onSelectionChange === 'function') {
        onSelectionChange(newSelection);
      }
    },
    [selectedItems]
  );

  const selectedItemsKeys = selectedItems.map(i => i.key);

  const isSelected = useCallback(item => selectedItemsKeys.includes(item.key), [
    selectedItemsKeys
  ]);
  const onSelect = useCallback(
    item => toggleSelection(item, onSelectCallback),
    [onSelectCallback, toggleSelection]
  );

  return useMemo(
    () => ({
      selectedItems,
      setSelectedItems,
      toggleSelection,
      onSelect,
      isSelected
    }),
    [onSelect, isSelected, selectedItems, toggleSelection]
  );
}

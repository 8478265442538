import React, { useCallback, useMemo } from 'react';
import { useMount } from 'react-use';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';

import {
  authLoadersSelector,
  registrationFeatureFlagSelector,
  requestSignUpHubspotFeatureFlagSelector
} from 'store/selectors';
import { batchEvaluateFlags } from 'store/actions/featureFlags';
import { requestUserRegister, requestUserLogin } from 'store/actions/auth';
import { Tabs } from 'components';

import { FEATURE_FLAGS } from 'constants/featureFlags';

import { ROUTES } from 'constants';

import LoginForm from './LoginForm';
import HubspotForm from './HubspotForm';
import RegistrationForm from './RegistrationForm';

import './Login.scss';

const ResetPassword = ({ history }) => {
  const dispatch = useDispatch();

  useMount(() => {
    dispatch(
      batchEvaluateFlags([
        FEATURE_FLAGS.REGISTRATION,
        FEATURE_FLAGS.REQUEST_SIGN_UP_HUBSPOT
      ])
    );
  });

  const isLoading = useSelector(authLoadersSelector, isEqual);
  const isAuthenticated = useSelector(state => state.auth.authenticated);
  const registrationFeatureFlag = useSelector(registrationFeatureFlagSelector);
  const requestSignUpHubspotFeatureFlag = useSelector(
    requestSignUpHubspotFeatureFlagSelector
  );

  const shouldShowRegistration = useMemo(
    () => registrationFeatureFlag?.variantKey === 'enabled',
    [registrationFeatureFlag]
  );

  const shouldShowHubspotForm = useMemo(
    () => requestSignUpHubspotFeatureFlag?.variantKey === 'enabled',
    [requestSignUpHubspotFeatureFlag]
  );

  const handleSignIn = useCallback(data => dispatch(requestUserLogin(data)), [
    dispatch
  ]);

  const handleRegister = useCallback(
    data => {
      dispatch(requestUserRegister(data));
    },
    [dispatch]
  );

  const handleForgotPassword = useCallback(() => {
    history.push({ pathname: ROUTES.AUTH.PASSWORD.RESET });
  }, [history]);

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  const tabs = [{ title: 'Sign in' }, { title: 'Register' }];

  const panels = [
    <LoginForm
      key="login-form"
      onSubmit={handleSignIn}
      inProgress={isLoading}
      handleForgotPassword={handleForgotPassword}
    />,
    <RegistrationForm
      key="registration-form"
      onSubmit={handleRegister}
      inProgress={isLoading}
    />
  ];

  return (
    <div className="login">
      {shouldShowHubspotForm ? (
        <div className="login-limited">
          <h2>Sign up</h2>
          <p>Currently we are taking in new users in limited numbers.</p>
          <p>
            Submit your email address if you’re interested in trying out RAIN.
            You will receive a confirmation of your queue status.
          </p>
          <HubspotForm />
        </div>
      ) : null}
      {shouldShowRegistration ? (
        <Tabs tabs={tabs} panels={panels} />
      ) : (
        <div id="react-tabs-1">
          <h1 className="sign-in-title">Sign In</h1>
          <LoginForm
            onSubmit={handleSignIn}
            inProgress={isLoading}
            handleForgotPassword={handleForgotPassword}
          />
        </div>
      )}
    </div>
  );
};

export default ResetPassword;

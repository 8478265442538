import axios from 'axios';

const API_URL = window?.ENV?.API_URL;
const CUSTOMER_URL = 'auth/customers';

export const updateCustomer = ({ id, data }) =>
  axios({
    baseURL: API_URL,
    method: 'PATCH',
    url: `${CUSTOMER_URL}/${id}`,
    data,
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true
  });

export const getCustomer = ({ id }) =>
  axios({
    baseURL: API_URL,
    method: 'GET',
    url: `${CUSTOMER_URL}/${id}`,
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true
  });

import { createSelector } from 'reselect';

export const loadersSelector = createSelector(
  store => store.loaders,
  loaders => loaders
);

export const authLoadersSelector = createSelector(
  loadersSelector,
  loaders => loaders.auth.page
);

export const accountDetailsCustomerLoaderSelector = createSelector(
  loadersSelector,
  loaders => loaders.accountDetails.customer
);

export const inviteUsersLoaderSelector = createSelector(
  loadersSelector,
  loaders => loaders.accountDetails.inviteUsers
);

export const accountDetailsPageLoaderSelector = createSelector(
  loadersSelector,
  loaders => loaders.accountDetails.page
);

export const dataNodesTableLoaderSelector = createSelector(
  loadersSelector,
  loaders => loaders.dataNodes.table
);

export const dataSourcesTableLoaderSelector = createSelector(
  loadersSelector,
  loaders => loaders.dataSources.table
);

export const resetPasswordLoaderSelector = createSelector(
  loadersSelector,
  loaders => loaders.passwordReset.isSent
);

export const setPasswordLoaderSelector = createSelector(
  loadersSelector,
  loaders => loaders.auth.setPassword
);

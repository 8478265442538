import { createSelector } from 'reselect';

const moduleSelector = createSelector(
  store => store.sources,
  sources => sources
);

export const dataNodesSelector = createSelector(
  moduleSelector,
  moduleState => moduleState?.dataNodes
);

export const dataNodesFetchedSelector = createSelector(
  moduleSelector,
  moduleState => moduleState?.fetched
);

export const dataSourcesSelector = createSelector(
  moduleSelector,
  moduleState => moduleState?.dataSources
);

export const dataSourcesByDataNodeIdsSelector = createSelector(
  moduleSelector,
  moduleState => moduleState.dataSourcesByDataNodeIds
);

export const dataTypesSelector = createSelector(
  moduleSelector,
  moduleState => moduleState.dataTypes
);

export const unassignedDataSourcesSelector = createSelector(
  moduleSelector,
  moduleState => moduleState?.unassignedDataSources
);

export const dataNodeNamesSelector = createSelector(
  moduleSelector,
  moduleState => moduleState?.dataNodeNames
);
